import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../environment/environment";
import { Rating } from "react-simple-star-rating";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "./Pagination/Pagination";
import swal from "sweetalert";


function ClientRatings() {
  const navigate = useNavigate();
  const defaultImg = "/img/Small-no-img.png";

  const { pages } = useParams();
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  // const [profile, setProfile] = useState(defaultImg)
  const [ratingView, setRatingView] = useState({
    profile: "",
    name: "",
    rating: null,
    review: "",
    ratingOpt: "",
    date: ""
  });
  const [dataLoader, setDataLoader] = useState(false);

  // const [isLoader, setIsLoader] = useState(false);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const handleClose = () => setShow(false);
  const [pageNum, setPageNum] = useState(+pages);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const limitValue = 10;
  const [columnArr, setColumnArr] = useState([
    { name: "Profile", value: "profile", dir: 1 },
    { name: "Name", value: "firstname", dir: 1 },
    // { name: "Last Name", value: "lastname", dir: 1 },
    { name: "Review Date", value: "date", dir: 1 },
  ]);

  useEffect(() => {
    GetRatingList(pageNum || 1);
  }, []);

  async function GetRatingList(val) {
    setSelectedPage(val);
    // setIsLoader(true)
    setDataLoader(true)
    // document.querySelector(".loader").classList.remove("d-none");
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };

    await axios
      .post(`${apiUrl}/admin/ratinglist`, obj)
      .then(function (response) {
        // setIsLoader(false)
        // document.querySelector(".loader").classList.add("d-none");
        if (response.data.status === 1) {
          setTimeout(() => {

            setNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
            setList(response.data?.result[0]?.paginatedResults);
            setDataLoader(false)
          }, 1000);

        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // setIsLoader(false)
        setDataLoader(false)
        // document.querySelector(".loader").classList.add("d-none");
        window.alert(error);
      });
  }

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    setDataLoader(false)
    // setIsLoader(false)
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetRatingList(selectedPage);
    setDataLoader(false)
    // setIsLoader(false)
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetRatingList(pageNum);
  };

  const isViewRating = (obj) => {

    setRatingView({
      ...ratingView,
      profile: obj.client_data.profile,
      name: obj.client_data.firstname,
      rating: obj.sessionrating.rate,
      review: obj.sessionrating.review,
      ratingOpt: obj.sessionrating.ratingOpt,
      date: obj.date
    });
    setShow(true);
  };


  const curPage = (pageNum) => {
    setPageNum(pageNum);
    navigate(`/clientratings/${pageNum}`);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async() => {
        await searchClick(searchname, pageNum);
        setDataLoader(false);
      },500)
    }else{
      GetRatingList(pageNum);
    }
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      setPageNum(pageNum); // Reset page number when clearing the search
      GetRatingList(pageNum);
    }
  };

  const searchClick = async (searchData, pageNum) => {

    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };

      const response = await axios.post(`${apiUrl}/admin/searchratingsession`,
        { searchname: searchData, obj }, {})
      if (response.data.status === 1) {
        setList(response?.data?.result?.paginatedResults)
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }

    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
  };

  const [popuploader] = useState(false);


  return (
    <>
      
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Client Ratings</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Client Ratings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <button className="search_btn" onClick={clearClick}>
                      <i className="fa fa-times"></i>
                    </button>
                  ) : (
                    <button className="search_btn" onClick={() => searchClick()}>
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-12 record_table review mt-2">
                <div className="position-relative">
                  <div className="table-responsive">

                    {dataLoader &&
                      <div id="loadermodal" class="loader height-full"><div class="popuploader loginloader"></div></div>
                    }

                    {list?.length > 0 ? (<>
                      <table className="table table-bordered table-hover mb-0 ratingstable">
                        <thead>
                          <tr>
                            <th style={{ "width": "40px" }}>Sr. No</th>
                            {columnArr.map((col, i) => {
                              return (
                                <th
                                  style={{ cursor: "pointer" }}
                                  onClick={() => isSortedColumn(col.value, col.dir)}
                                >
                                  <div className="d-flex justify-content-between">
                                    {col.name}
                                    <span className="align-self-center">
                                      <i className="ml-2 fa fa-sort sort-gray"></i>
                                      <i
                                        className={`${col.dir === 1
                                          ? "sort-i fa fa-sort-asc"
                                          : "sort-i fa fa-sort-desc"
                                          }`}
                                      ></i>
                                    </span>
                                  </div>
                                </th>
                              );
                            })}
                            <th>Rating</th>
                            <th>Rating Option</th>
                            <th className="text-center w130">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((ele, index) => {
                            return (
                              <tr key={"index" + index}>
                                <td style={{ "width": "50px" }}>{index + 1 + (pageNum - 1) * limitValue}</td>
                                <td className="text-nowrap">
                                  <img
                                    className="rounded"
                                    height={50}
                                    width={50}
                                    title={ele.client_data.firstname}
                                    src={apiUrl + ele.client_data.profile || defaultImg}
                                    alt="profile"
                                    onError={(e) => {
                                      e.target.src = "/img/Small-no-img.png";
                                    }} />
                                </td>
                                <td className="text-nowrap">
                                  {ele.client_data.firstname} {ele.client_data.lastname}
                                </td>
                                {/* <td>
                                    {ele.client_data.lastname}
                                  </td> */}
                                <td>{new Date(ele.date).toDateString()}</td>
                                <td>
                                  <Rating
                                    initialValue={ele.sessionrating.rate}
                                    size="17"
                                    readonly="true"
                                    allowHover="false"
                                    allowHalfIcon="true"
                                  />
                                </td>
                                <td>
                                  {ele.sessionrating.ratingOpt.map((rs, i) => {
                                    return <p key={"index" + i}>{rs}</p>;
                                  })}
                                </td>
                                <td className="text-center">
                                  <button
                                    title="View"
                                    className="btn btn-eye mr-2"
                                    href={() => false}
                                    onClick={(e) => {
                                      isViewRating(ele);
                                    }}
                                  >
                                    <i className="ace-icon fa fa-eye"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                          <div className="text-center" colSpan="9">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Ratings Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                  </div>
                </div>
                {/* {searchname === "" ? (<> */}
                  {list?.length > 0 && (
                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={pageNum}
                      totalCount={noOfRecords}
                      pageSize={limitValue}
                      onPageChange={(page) => curPage(page)}
                    />
                  </div>
                  )}
                {/* </>) : (<></>)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="loadermodal" className="loader d-none">
          <div className="popuploader loginloader"></div>
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Client Ratings</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="rating-modal">
          <div className="col-md-12 pb-3 booking-history">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="row">
                  <div className="d-flex w-100">
                    <div className="profile-img">
                      {ratingView?.profile ? (
                        <img
                          src={apiUrl + ratingView?.profile}
                          alt=""
                          onError={(e) => {
                            e.target.src = "/img/Small-no-img.png";
                          }}
                        />
                      ) : (
                        <img src={"/img/Small-no-img.png"} alt="" />
                      )}
                    </div>
                    <div className="client-review">
                      <div className="d-flex justify-content-between">
                        <div className="rating">
                          <div className="rating-name">{ratingView.name}</div>
                          <Rating
                            initialValue={ratingView.rating}
                            size="17"
                            readonly="true"
                            allowHover="false"
                            allowHalfIcon="true"
                          />
                        </div>
                        <div className="ratingdate">
                          {new Date(ratingView.date).toDateString()}
                        </div>
                      </div>
                      <div>{ratingView.review ? ratingView.review : "N/A"}</div>
                      <div>
                        {ratingView.ratingOpt}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ClientRatings;
