import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../environment/environment";
import Pagination from "./Pagination/Pagination";

const initialSearchData = {
  tid: "",
  cid: "",
  fromdate: "",
  todate: "",
};

function ClientBookingHistory() {
  const navigate = useNavigate();

  const { pages } = useParams();

  const [list, setList] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);

  // console.log("search data",list);

  // const [loader, setLoader] = useState(false)
  // const [searchname, setSearchName] = useState("");
  // const [isSearching, setSearching] = useState(false);
  const [pageNum, setPageNum] = useState(+pages);
  const [noOfRecords, setNoOfRecords] = useState(0);


  const limitValue = 10;
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const [columnArr, setColumnArr] = useState([
    {
      name: "Trainer Name (E-mail)",
      value: "ele?.trainer_data?.firstname (ele?.trainer_data?.email)",
      dir: 1,
    },
    {
      name: "Member Name (E-mail)",
      value: "ele?.client_data.firstname (ele?.client_data?.email)",
      dir: 1,
    },
    // { name: "Last Name", value: "client_data.lastname", dir: 1 },
    // { name: "Email", value: "client_data.email", dir: 1 },
    { name: "Client Phone No", value: "client_data.phoneno", dir: 1 },
    { name: "Date", value: "date", dir: 1 },
  ]);
  const [trainer, setTrainer] = useState([]);
  const [client, setClient] = useState([]);
  const [searchData, setSearchData] = useState(initialSearchData);
  const [isDisable, setIsDisable] = useState(true)

  useEffect(() => {
    GetList(pageNum || 1);
    getTrainer();
    getClient();
  }, []);

  async function getTrainer() {
    // setLoader(true)
    setDataLoader(true);
    axios
      .get(`${apiUrl}/trainer/trainer/alltrainer`)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            // setLoader(false);
            setTrainer(response.data.result);
            setDataLoader(false);
          }, 1000);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        // console.log("trainer list", error);
        // setLoader(false);
        setDataLoader(false);
        window.alert(error);
      });
  }

  async function getClient() {
    // setLoader(true)
    setDataLoader(true);
    axios
      .get(`${apiUrl}/client/allTrainers`)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            setClient(response.data.result);
            // setLoader(false);
            setDataLoader(false);
          }, 1000);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        // console.log("trainer list", error);
        // setLoader(false);
        setDataLoader(false);
        window.alert(error);
      });
  }

  async function GetList(val) {
    setSelectedPage(val);
    // setLoader(true)
    setDataLoader(true);
    // document.querySelector(".loader").classList.remove("d-none");
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };
    await axios
      .post(`${apiUrl}/admin/clientBookinglist`, obj)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            setNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
            setList(response.data?.result[0]?.paginatedResults);
            setDataLoader(false);
            // document.querySelector(".loader").classList.add("d-none");
            // setLoader(false)
          }, 1000);
        } else {
          // setLoader(false)
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // setLoader(false)
        // document.querySelector(".loader").classList.add("d-none");
        setDataLoader(false);
        window.alert(error);
      });
  }

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    // setLoader(false)
    setDataLoader(false);
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetList(selectedPage);
    setDataLoader(false);
  };


  const curPage = async (pageNum) => {
    setPageNum(pageNum);
    const updatedSearchData = searchData;
    const obj = {
      limitValue: limitValue,
      pageNumber: pageNum,
    };

    if (searchData) {
      setDataLoader(true)
      setTimeout(async () => {
        await search(updatedSearchData, obj);
        setDataLoader(false)
      }, 500);
    } else {
      await GetList(pageNum);
    }

    navigate(`/memberbookinghistory/${pageNum}`);
  };

  const searchInputhandle = (e) => {
    const updatedSearchData = { ...searchData };

    if (e.target.value !== "") {
      if (e.target.name === 'tid') {
        updatedSearchData.tid = e.target.value;
      } else if (e.target.name === 'cid') {
        updatedSearchData.cid = e.target.value;
      } else if (e.target.name === 'fromdate') {
        updatedSearchData.fromdate = e.target.value;
      } else if (e.target.name === 'todate') {
        updatedSearchData.todate = e.target.value;
      }

      setSearchData(updatedSearchData);
      search(updatedSearchData, {
        limitValue: limitValue,
        pageNumber: pageNum,
      });
    } else {
      // Handle the case when the input value is empty (optional)
      delete updatedSearchData[e.target.name];
      setSearchData(updatedSearchData);
      search(updatedSearchData, {
        limitValue: limitValue,
        pageNumber: pageNum,
      });
    }
  };

  // Assuming that the search function filters data based on the 'cid' field
  const search = async (searchData, obj) => {
    setIsDisable(false);

    if (!searchData.tid && !searchData.cid && !searchData.fromdate && !searchData.todate) {
      clearClick();
      return false;
    }

    setPopuploader(true);

    try {
      const response = await axios.post(`${apiUrl}/admin/searchbooking`, { searchname: searchData, obj });
      if (response.data.status === 1) {
        if (response?.data?.result[0]?.paginatedResults) {
          setList(response?.data?.result[0]?.paginatedResults);
          setNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
        }
        setPopuploader(false);
      } else {
        window.alert(response.data.message);
      }
    } catch (error) {
      setPopuploader(false);
      window.alert(error);
    }
  };

  const clearClick = async () => {
    setIsDisable(true)
    setSearchData({ tid: "", cid: "", fromdate: "", todate: "" });
    GetList(pageNum);
    getClient(pageNum);
    getTrainer(pageNum);
    // setIsSearching(true)
  };

  const [popuploader, setPopuploader] = useState(false);

  return (
    <>
      {/* {loader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}

      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Member Booking History</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Member Booking History
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card client-book">
            <div className="row">
              <div className="col-xl-9 col-12">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-sm-6 col-12 mb-3">
                    <label>Select Trainer</label>
                    <select
                      onChange={(e) => searchInputhandle(e)}
                      name="tid"
                      value={searchData.tid}
                      className="input-box"
                    >
                      <option value="">Select trainer</option>
                      {trainer?.map((ele) => {
                        return (
                          <>
                            <option value={ele._id}>{ele.firstname}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-6 col-12 mb-3">
                    <label>Select Member</label>
                    <select
                      onChange={(e) => searchInputhandle(e)}
                      name="cid"
                      value={searchData.cid}
                      className="input-box"
                    >
                      <option value="">Select Member</option>
                      {client?.map((ele) => {
                        return (
                          <>
                            <option value={ele._id}>{ele.firstname}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-6 col-12 mb-3">
                    <label>From Date</label>
                    <input
                      type="date"
                      onChange={(e) => searchInputhandle(e)}
                      value={searchData.fromdate}
                      name="fromdate"
                      className="input-box"
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-6 col-12 mb-3">
                    <label>To Date</label>
                    <input
                      type="date"
                      onChange={(e) => searchInputhandle(e)}
                      value={searchData.todate}
                      name="todate"
                      className="input-box"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-12 col-12">
                <div className="row">
                  {/* <div className="col-xl-6 col-lg-3 col-sm-6 col-12 mb-3">
                <button onClick={() => search("")} className="searchbtn">
                  Search
                </button>
              </div> */}
                  <div className="col-xl-6 col-lg-3 col-sm-6 col-12 mb-3">
                    <button disabled={isDisable} onClick={() => clearClick("")} className="searchbtn">
                      Clear
                    </button>
                  </div>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-12 col-12 record_table history mt-2">
                <div className="position-relative">
                  <div className="table-responsive">
                    {dataLoader ? (
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {list?.length > 0 ? (
                      <>
                        <table className="table table-bordered table-hover mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>Sr. No</th>
                              {columnArr.map((col, i) => {
                                return (
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      isSortedColumn(col.value, col.dir)
                                    }
                                  >
                                    <div className="d-flex justify-content-between">
                                      {col.name}
                                      <span className="align-self-center">
                                        <i className="ml-2 fa fa-sort sort-gray"></i>
                                        <i
                                          className={`${col.dir === 1
                                              ? "sort-i fa fa-sort-asc"
                                              : "sort-i fa fa-sort-desc"
                                            }`}
                                        ></i>
                                      </span>
                                    </div>
                                  </th>
                                );
                              })}

                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list?.map((ele, index) => {
                              return (
                                <tr key={"index" + index}>
                                  <td style={{ width: "50px" }}>
                                    {index + 1 + (pageNum - 1) * limitValue}
                                  </td>
                                  <td className="text-nowrap">
                                    {ele?.trainer_data?.firstname} (
                                    {ele?.trainer_data?.email})
                                  </td>
                                  <td className="text-nowrap">
                                    {ele?.client_data?.firstname} (
                                    {ele?.client_data?.email})
                                  </td>
                                  <td>
                                    {ele?.client_data?.countryDialCode}{" "}
                                    {ele?.client_data?.phoneno}
                                  </td>
                                  <td className="text-nowrap">
                                    {new Date(ele?.date).toDateString()}
                                  </td>
                                  <td className="text-nowrap">
                                    {ele?.starthour} to {ele?.endhour}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                          <div className="text-center" colSpan="10">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Client Booking Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* {isSearching && ( */}
                <>
                  {list?.length > 0 && (
                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                      <Pagination
                        className="pagination-bar"
                        currentPage={pageNum}
                        totalCount={noOfRecords}
                        pageSize={limitValue}
                        onPageChange={(page) => curPage(page)}
                      />
                    </div>
                  )}
                </>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientBookingHistory;
