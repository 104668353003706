import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../environment/environment";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "./Pagination/Pagination";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function Subscribers() {
  let navigate = useNavigate();

  const { pages } = useParams();
  const [pageNum, setPageNum] = useState(+pages);

  const [dataLoader, setDataLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  // const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [plan, setPlan] = useState({
    id: "",
    plantype: "",
    noofsession: "",
    amount: "",
    tax: "",
    paymentMethod: "",
    status: true,
  });
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);

  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  const [errors, setErrors] = useState({});
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const limitValue = 10;
  const [columnArr, setColumnArr] = useState([
    { name: "Plan Type", value: "plantype", dir: 1 },
    { name: "No Of Session", value: "noofsession", dir: 1 },
    { name: "Amount", value: "amount", dir: 1 },
    { name: "Tax", value: "tax", dir: 1 },
  ]);

  useEffect(() => {
    GetList(pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function GetList(val) {
    setSelectedPage(val);
    // setIsLoader(true);
    setDataLoader(true)
    // document.querySelector(".loader").classList.remove("d-none");
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };
    await axios
      .post(`${apiUrl}/admin/getplan`, obj)
      .then(function (response) {
        // document.querySelector(".loader").classList.add("d-none");
        if (response.data.status === 1) {
          setTimeout(() => {
            setNoOfRecords(response.data?.result?.noOfRecords || 0);
            setList(response.data.result?.planlist);
            setDataLoader(false)
          }, 1000);
        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // setIsLoader(false);
        setDataLoader(false)
        // document.querySelector(".loader").classList.add("d-none");
        window.alert(error);
      });
  }

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    setDataLoader(false)
    // setIsLoader(false)
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetList(selectedPage);
    setDataLoader(false)
    // setIsLoader(false)
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      setPageNum(pageNum); // Reset page number when clearing the search
      GetList(pageNum);

      // setIsLoader(false)
    }
  };

  const curPage = (pageNum) => {
    navigate(`/subscribers/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async() => {
        await searchClick(searchname, pageNum);
        setDataLoader(false);
      }, 500);
  } else {
    GetList(pageNum);
  }
  };

  const searchClick = async (searchData,pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };
      const response = await axios.post(`${apiUrl}/admin/searchplans`, { searchname: searchData, obj }, {})

      if (response.data.status === 1) {
        // console.log("search data", );
        setList(response?.data?.result?.paginatedResults)
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
        setSearching(true);

      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }

    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // if (!searchData) {
    //   setDataLoader(false)
    //   // setIsLoader(false);
    //   GetList(pageNum);

    //   setDataLoader(false)
    //   // setIsLoader(false);
    // } else {
    //   // document.querySelector('.loader').classList.remove('d-none');
    //   await axios
    //     .post(`${apiUrl}/admin/searchplans`, { searchname: searchData }, {})
    //     .then(function (response) {
    //       // document.querySelector(".loader").classList.add("d-none");
    //       if (response.data.status === 1) {
    //         setList(response.data.result);
    //         setSearching(true);
    //       } else {
    //         window.alert(response.data.message);
    //       }
    //     })
    //     .catch(function (error) {
    //       window.alert(error);
    //     });
    // }
  };

  const isSave = (e) => {
    e.stopPropagation();
    let isValid = true;
    var errormsg = {};
    const reg = /^[0-9\b]+$/;

    if (plan.plantype === "") {
      errormsg.plantype = "Please select plantype!";
      isValid = false;
    }

    if (plan.amount === "") {
      errormsg.amount = "Please enter amount!";
      isValid = false;
    } else if (reg.test(plan.amount) === false) {
      errormsg.amount = "Please enter valid amount!";
      isValid = false;
    } else if (!/^\d+$/.test(plan.amount)) {
      // Check if value contains only digits
      errormsg.amount = "Please enter only digits!";
      isValid = false;
    } else if (plan.amount === "0") {
      errormsg.amount = "Amount cannot be 0";
      isValid = false;
    }

    if (plan.tax === "") {
      errormsg.tax = "Please enter tax!";
      isValid = false;
    } else if (reg.test(plan.tax) === false) {
      errormsg.tax = "Please enter valid tax!";
      isValid = false;
    } else if (!/^\d+$/.test(plan.tax)) {
      // Check if value contains only digits
      errormsg.tax = "Please enter only digits!";
      isValid = false;
    }

    if (plan.noofsession === "") {
      errormsg.noofsession = "Please enter no of session!";
      isValid = false;
    } else if (!/^\d+$/.test(plan.noofsession)) {
      errormsg.noofsession = "Please enter only digits!";
      isValid = false;
    } else if (plan.noofsession === "0") {
      errormsg.noofsession = "No of session cannot be 0";
      isValid = false;
    }

    setErrors(errormsg);
    if (isValid) {
      // setDataLoader(true)
      setIsButtonDisabled(true);
      setButtonLoader(true);
      setPopuploader(true)
      // setIsLoader(true);
      // document.querySelector(".loader").classList.remove("d-none");
      axios
        .post(`${apiUrl}/admin/saveplan`, plan)
        .then(function (response) {
          // setIsLoader(false);
          // document.querySelector(".loader").classList.add("d-none");
          if (response.data.status === 1) {
            setTimeout(() => {
              // GetList(1);
              setDataLoader(false)
              GetList(pageNum);
              setDataLoader(false)
              setIsButtonDisabled(false);
              setButtonLoader(false);
              setDataLoader(false)
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setPopuploader(false)
              setShow(false);
            }, 1000);
          } else {
            setShow(true);
            setDataLoader(false)
            setPopuploader(false)
            setIsButtonDisabled(false);
            setButtonLoader(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          // setIsLoader(false);
          setDataLoader(false)
          // document.querySelector(".loader").classList.add("d-none");
          window.alert(error);
        });
    }
  };

  const isEdit = (obj) => {
    setIsButtonDisabled(false);
    setPlan({
      ...plan,
      id: obj._id,
      plantype: obj.plantype,
      noofsession: obj.noofsession,
      amount: obj.amount,
      tax: obj.tax,
      paymentMethod: obj.paymentMethod,
      status: obj.statusid,
    });
    setShow(true);
  };

  const isDelete = (val) => {
    let obj = {
      id: val._id,
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // setIsLoader(true);
          setDataLoader(true)
          axios
            .post(`${apiUrl}/admin/deleteplan`, obj)
            .then(function (response) {
              // setIsLoader(false);
              if (response.data.status === 1) {
                setTimeout(async () => {
                  const newList = list.filter((x) => x._id !== val._id);
                  if (newList?.length === 0) {
                    if (pageNum === 1) {
                      setPageNum(pageNum);
                      await GetList(pageNum);
                      setList(newList);
                      navigate(`/subscribers/${pageNum}`);
                    } else {
                      setPageNum(pageNum - 1);
                      await GetList(pageNum - 1);
                      setList(newList);
                      navigate(`/subscribers/${pageNum - 1}`);
                    }
                  } else {
                    setList(newList);
                  }
                  swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true,
                  });
                  setDataLoader(false);
                }, 1000);
              } else {
                setDataLoader(false)
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                  button: true,
                });
                //window.alert(response.data.message);
              }
            })
            .catch(function (error) {
              setDataLoader(false)
              // setIsLoader(false);
              // document.querySelector(".loader").classList.add("d-none");
              window.alert(error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
   
  };



  const isShowModel = () => {
    setPlan({
      ...plan,
      id: "",
      plantype: "",
      noofsession: "",
      amount: "",
      tax: "",
      paymentMethod: "",
      status: true,
    });
    setErrors({});
    setShow(true);
  };

  const onChangeHandle = (e, ele) => {
    setList((prev) => prev.id === ele._id ? { ...prev, status: !prev.status } : prev);
    const obje = {
      id: ele._id,
      status: e.currentTarget.checked ? 1 : 0,
    }
    axios
      .post(`${apiUrl}/admin/planStatus`, obje)
      .then(function (response) {
        setShow(false);
        if (response.data.status === 1) {
          // GetList(pageNum);
          // const newState = list.map((x) => {
          //   if (x._id === ele._id) {
          //     return { ...x, statusid: response.data.result.statusid };
          //   }
          //   return x;
          // });
          // setList(newState);
          setDataLoader(false)
          GetList(pageNum)
          setDataLoader(false)
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

        } else {
          // setIsLoader(false);
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        setDataLoader(false)
        // setIsLoader(false);
        // document.querySelector(".loader").classList.add("d-none");
        window.alert(error);
      });
  };

  const handleChange = (e) => {
    setPlan({ ...plan, [e.target.name]: e.target.value });
  };

  const [popuploader, setPopuploader] = useState(false);


  return (
    <>
      {/* {isLoader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Subscribers / Plan</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Subscribers / Plan</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <button className="search_btn" onClick={clearClick}>
                      <i className="fa fa-times"></i>
                    </button>
                  ) : (
                    <button className="search_btn" onClick={() => searchClick()}>
                      {" "}
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </div>
              </div>
              <div className="col-xl-2 col-md-3 col-sm-6 col-12 offset-md-3 offset-xl-6 text-right mb-2">
                <button
                  className="add-btn"
                  onClick={(e) => {
                    isShowModel();
                  }}
                >
                  <i className="fa fa-plus pr-2"></i>Add
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive">
                    {dataLoader ? (
                      // <span>Loadnig..</span>
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}


                    {list?.length > 0 ? (<>
                      <table className="table table-bordered table-hover mb-0">
                        <thead>
                          <tr>
                            <th style={{ "width": "50px" }}>Sr. No</th>
                            {columnArr.map((col, i) => {
                              return (
                                <th
                                  style={{ cursor: "pointer" }}
                                  onClick={() => isSortedColumn(col.value, col.dir)}
                                >
                                  <div className="d-flex justify-content-between">
                                    {col.name}
                                    <span className="align-self-center">
                                      <i className="ml-2 fa fa-sort sort-gray"></i>
                                      <i
                                        className={`${col.dir === 1
                                          ? "sort-i fa fa-sort-asc"
                                          : "sort-i fa fa-sort-desc"
                                          }`}
                                      ></i>
                                    </span>
                                  </div>
                                </th>
                              );
                            })}
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((ele, index) => {
                            return (
                              <tr>
                                <td style={{ "width": "50px" }}>{index + 1 + (pageNum - 1) * limitValue}</td>
                                <td>{ele.plantype}</td>
                                <td>{ele.noofsession}</td>
                                <td>{ele.amount} $</td>
                                <td>{ele.tax} $</td>

                                <td>
                                  <div className="onoffswitch">
                                    <input
                                      type="checkbox"
                                      name="onoffswitch"
                                      className="onoffswitch-checkbox"
                                      id={`myonoffswitch ${index}`}
                                      checked={ele.statusid === 1 ? true : false}
                                      onChange={(e) => {
                                        onChangeHandle(e, ele);
                                      }}
                                    />
                                    <label
                                      className="onoffswitch-label"
                                      htmlFor={`myonoffswitch ${index}`}
                                    >
                                      <span className="onoffswitch-inner active-deactive"></span>
                                      <span className="onoffswitch-switch"></span>
                                    </label>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="btn btn-edit mr-2"
                                      onClick={(e) => {
                                        isEdit(ele);
                                      }}
                                    >
                                      <i className="ace-icon fas fa-pen-square"></i>
                                    </button>
                                    <button
                                      className="btn btn-delete mr-2"
                                      onClick={(e) => {
                                        isDelete(ele);
                                      }}
                                    >
                                      <i className="ace-icon fa fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                          <div className="text-center" colSpan="9">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Session Details Not Found
                                </h4>
                                {/* <p>You can add your session from add button</p> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                  </div>
                </div>
                {/* {searchname === "" ? (<> */}
                  {list?.length > 0 && (
                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                      <Pagination
                        className="pagination-bar"
                        currentPage={pageNum}
                        totalCount={noOfRecords}
                        pageSize={limitValue}
                        onPageChange={(page) => curPage(page)}
                      />
                    </div>
                  )}
                {/* </>) : (<></>)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {popuploader &&
          <div id="loadermodal" className="loader">
            <div className="popuploader loginloader"></div>
          </div>
        }
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center justify-content-between w-100 mb-0">
            <div className="d-flex align-items-center">
              <img src="/img/common-health.png" alt="logo" />
              <h4 className="mb-0 ml-3">Subscribers / Plan</h4>
            </div>
            <div className="onoffswitch m-0">
              <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchedit" checked={plan.status === 1 ? true : false} value={plan.status} onChange={(e) => { setPlan({ ...plan, status: e.currentTarget.checked ? 1 : 0, }); }} />
              <label className="onoffswitch-label" htmlFor="myonoffswitchedit">
                <span className="onoffswitch-inner active-deactive"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <label>Plan Type</label> <span className="text-red font-bold">*</span>

                <div className="input-group">
                  <i className="fa fa-angle-down darrow-i"></i>
                  <select
                    className="input-box m-0"
                    value={plan.plantype}
                    onChange={(event) => handleChange(event)}
                    name="plantype">
                    <option value="">Choose Plan</option>
                    <option value="Standard">Standard</option>
                    <option value="Elite">Elite</option>
                  </select>
                  <div className="text-danger">{errors.plantype}</div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <label>No Of Session</label> <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <input
                    className="input-box m-0"
                    type="number"
                    value={plan.noofsession}
                    placeholder="Number of session"
                    onChange={(event) => handleChange(event)}
                    name="noofsession"

                  />
                  <div className="text-danger">{errors.noofsession}</div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <label>Amount $</label> <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <input
                    className="input-box m-0"
                    type="number"
                    value={plan.amount}
                    placeholder="Amount"
                    min={1}
                    onChange={(event) => handleChange(event)}
                    name="amount"

                  />
                  <div className="text-danger">{errors.amount}</div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <label>Tax</label> <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <input
                    className="input-box m-0"
                    type="number"
                    value={plan.tax}
                    name="tax"
                    placeholder="Tax ($)"
                    onChange={(event) => handleChange(event)}

                  />
                  <div className="text-danger">{errors.tax}</div>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn cancel_btn" disabled={isButtonDisabled} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isButtonDisabled}
            className="btn save_btn"
            variant="secondary"
            onClick={(e) => {
              isSave(e);
            }}
          >
            {/* {buttonLoader ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            ) : (
              ""
            )} */}
            {plan.id ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Subscribers;
