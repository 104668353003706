/* eslint-disable no-mixed-operators */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const intiSocialmedia = {
  platform: "",
  icon: "",
  url: "",
}

function ProfileManagment() {
  const navigate = useNavigate();
  const [adminProfile, setAdminProfile] = useState({
    firstname: "",
    lastname: "",
    email: "",
    fitnessGoals: "",
    phoneno: "",
    countryDialCode: "",
    age: "",
    gender: "",
    height: "",
    weight: "",
    socialmedia: [{ platform: "", icon: "", url: "", _id: "" }],
  });
  const [errors, setErrors] = useState({});
  const defaultImg = "/img/Small-no-img.png";
  const [imagepreview, setImagePreview] = useState(defaultImg);
  const [getImage, setGetImage] = useState();
  // const [isLoader, setIsLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [socialmediaModal, setSocialmediaModal] = useState(false);
  const [socialmedia, setSocialmedia] = useState(intiSocialmedia);
  const [mobileNumber, setMobileNumber] = useState("")
  const [dataLoader, setDataLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [popuploader, setPopuploader] = useState(false);


  const openModal = () => {
    setErrors({})
    setIsButtonDisabled(false)
    setButtonLoader(false)
    setSocialmediaModal(true);
  };

  const getProfile = () => {
    // setIsLoader(true);
    setDataLoader(true)
    axios
      .get(`${apiUrl}/admin/getProfile`)
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            setAdminProfile(response.data.result);
            // setImagePreview(apiUrl + PORT + response.data.result.profile);
            setImagePreview(
              response.data.result.profile !== null &&
                response.data.result.profile !== undefined &&
                response.data.result.profile !== ""
                ? apiUrl + response.data.result.profile
                : defaultImg
            );
            let contectNUmber = response.data.result.country + response.data.result.countryDialCode + response.data.result.phoneno;
            console.log(contectNUmber);
            setMobileNumber(contectNUmber)
            sessionStorage.setItem("user", JSON.stringify(response.data.result));
            navigate("/profilemanagement");
            // setIsLoader(false);
            setDataLoader(false)
          }, 1000);

        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleInputs = (e) => {
    setAdminProfile({ ...adminProfile, [e.target.name]: e.target.value });
  };

  const onFileChange = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 2096000) {
      setImagePreview(defaultImg);
      alert("File size more than 2 MB. File size must under 2MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      setGetImage(event.target.files[0]);

      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        setIsEdit(true);
        setImagePreview(result);
      };

      fileReader.readAsDataURL(file);
    }
  };

  const OnFileDiacard = () => {
    setIsEdit(false);
    setImagePreview(imagepreview);
    getProfile();
  };

  const validatePhoneNumber = (phoneNumber, countryCode) => {
    console.log("phoneNumber, countryCode", phoneNumber, countryCode);
    const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (countryCode === 'no') {
      return formattedPhoneNumber.length === 8;
    } else {
      return formattedPhoneNumber.length === 10;
    }
  };


  const submitProfile = () => {
    setIsEdit(false);
    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    console.log("adminProfile.phoneno", adminProfile.phoneno);

    if (adminProfile.firstname === "") {
      errormsg.firstname = "Please enter First Name.";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(adminProfile.firstname)) {
      errormsg.firstname = "Please enter only letters!";
      isValid = false;
    }

    if (adminProfile.lastname === "") {
      errormsg.lastname = "Please enter Last Name.";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(adminProfile.lastname)) {
      errormsg.lastname = "Please enter only letters!";
      isValid = false;
    }

    if (adminProfile.gender === "") {
      errormsg.gender = "Please Choose Gender.";
      isValid = false;
    }
    if (adminProfile.fitnessGoals === "") {
      errormsg.fitnessGoals = "Please Choose FitnessGoals.";
      isValid = false;
    }
    if (adminProfile.phoneno === "") {
      errormsg.phoneno = "Please enter Phone number.";
      isValid = false;
    } else if (!validatePhoneNumber(adminProfile.phoneno, adminProfile.country)) {
      errormsg.phoneno = "Invalid phone number for the selected country.";
      isValid = false;
    }

    if (adminProfile.email === "") {
      errormsg.email = "Please enter Email.";
      isValid = false;
    } else if (reg_email.test(adminProfile.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    } else if (adminProfile.email.length <= 50 === false) {
      errormsg.email = "Please enter maximum 256";
      isValid = false;
    }

    setErrors(errormsg);
    if (isValid === true) {
      let obj = {
        id: adminProfile._id,
        firstname: adminProfile.firstname,
        lastname: adminProfile.lastname,
        gender: adminProfile.gender,
        fitnessGoals: adminProfile.fitnessGoals,
        phoneno: adminProfile.phoneno,
        countryDialCode: adminProfile.countryDialCode,
        height: adminProfile.height,
        weight: adminProfile.weight,
        email: adminProfile.email,
      };

      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      // PROFILE IMAGE
      let profile;
      if (typeof getImage == "string") {
        profile = getImage.split(apiUrl);
        profile = profile[1] || "";
        setGetImage(...getImage, profile);
      } else {
        profile = getImage;
      }

      if (getImage?.type === undefined) {
        formData.append("edprofile", profile);
      } else {
        formData.append("profile", profile);
      }
      // setIsLoader(true);
      setDataLoader(true)
      setButtonLoader(true)
      setIsButtonDisabled(true)

      axios
        .post(`${apiUrl}/admin/updateadminusers`, formData)
        .then(function (response) {
          // setIsLoader(false);
          if (response.data.status === 1) {
            setTimeout(() => {
              sessionStorage.setItem(
                "user",
                JSON.stringify(response.data.result)
              );
              setAdminProfile(response.data.result)
              // setAdminProfile(prev)
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setButtonLoader(false)
              setIsButtonDisabled(false)
              setDataLoader(false)
              navigate("/profilemanagement");
            }, 1000);

          } else {
            setDataLoader(false)
            setButtonLoader(false)
            setIsButtonDisabled(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          // setIsLoader(false);
          setDataLoader(false)
          window.alert(error);
        });
    }
  };

  const socialmediaHandle = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSocialmedia({ ...socialmedia, [name]: value });
  };

  const iconRef = useRef()
  const urlRef = useRef()


  const socialmediaAdd = async () => {
    let isValid = true;
    let errormsg = {};
    let url = socialmedia.url;

    if (!url.match(/^https?:\/\//i)) {
      url = `https://${url}`;
    }

    if (!url.match(/^www\./i)) {
      url = `www.${url}`;
    }

    url = url.replace(/^(https?:\/\/)?(www\.)?/i, '');

    if (!url.endsWith("/")) {
      url += "/";
    }

    const regexString = `${socialmedia.platform.toLowerCase()}\\.com`;
    const regex = new RegExp(regexString, 'gi');
    const match = regex.exec(url);

    if (socialmedia.platform === "") {
      errormsg.platform = "Please enter Socialmedia Name.";
      isValid = false;
    }

    if (socialmedia.url === "") {
      errormsg.url = "Please enter socialmedia link.";
      isValid = false;
    } else if (!match) {
      errormsg.url = "Url not matched with name.";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.platform) {
        iconRef.current.focus();
      } else if (errormsg.url) {
        urlRef.current.focus();
      }
    }


    setErrors(errormsg);
    if (isValid === true) {
      let icon = "";
      if (socialmedia.platform === "Facebook") {
        icon = "fab fa-facebook-f";
      } else if (socialmedia.platform === "Instagram") {
        icon = "fab fa-instagram";
      } else if (socialmedia.platform === "Twitter") {
        icon = "fab fa-twitter";
      } else if (socialmedia.platform === "Linkedin") {
        icon = "fab fa-linkedin";
      } else if (socialmedia.platform === "Youtube") {
        icon = "fab fa-youtube";
      } else if (socialmedia.platform === "Tiktok") {
        icon = "fab fa-tiktok";
      }

      let obj = {
        platform: socialmedia.platform,
        icon: icon,
        url: url,
      };
      // setIsLoader(true);
      setButtonLoader(true)
      setIsButtonDisabled(true)
      setPopuploader(true)
      await axios
        .post(`${apiUrl}/admin/account/socialmedia/${adminProfile._id}`, { obj })
        .then((response) => {
          // setIsLoader(false);
          if (response.data.status === 1) {
            setTimeout(() => {
              sessionStorage.setItem("admin", JSON.stringify(response.data.result));
              setAdminProfile(response.data.result);
              setSocialmedia(intiSocialmedia)
              setErrors({})
              setButtonLoader(false)
              setPopuploader(false)
              setIsButtonDisabled(false)
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setSocialmediaModal(false);
            }, 1000);
          } else {
            setButtonLoader(false)
            setIsButtonDisabled(false)
            setPopuploader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        });
    }
  };

  const removeMedi = async (mid) => {
    const adminid = adminProfile._id;
    Swal.fire({
      text: "Are you sure you want to delete your social media?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // setIsLoader(true);
        setDataLoader(true)
        axios
          .post(`${apiUrl}/admin/account/deleteSocialmedia/${adminid}`, { mid })
          .then(function (response) {
            if (response.data.status === 1) {
              // setIsLoader(false);
              setTimeout(() => {
                setAdminProfile(response.data.result);
                swal({
                  title: "Success!",
                  text: response.data.message,
                  icon: "success",
                  button: true,
                });
                setDataLoader(false)
              }, 500);

            } else {
              setDataLoader(false)

              swal({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                button: true,
              });
            }
          })

      }
    });

  };



  return (
    <>
      {/* {isLoader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Profile</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card profile-box position-relative">
            {dataLoader &&
              <div id="loadermodal" className="loader"><div className="popuploader loginloader"></div></div>
            }

            <h4 className="pro-title">{adminProfile.usertype}</h4>
            <hr />

            <div className="row">
              <div className="col-xl-3 col-12">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={onFileChange}
                      onError={(e) => {
                        e.target.src = "/img/Small-no-img.png";
                      }}
                    />
                    {isEdit ? (
                      <>
                        <button className="img-cancel" onClick={OnFileDiacard}>
                          <i className="fa fa-times"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <label htmlFor="imageUpload"></label>
                      </>
                    )}
                  </div>
                  <div className="avatar-preview">
                    <div
                      id="imagePreview"
                      style={{ backgroundImage: `url(${imagepreview})` }}
                    ></div>
                  </div>
                  <div className="prof_name">
                    <h4>{adminProfile.firstname}</h4>
                  </div>
                  <div className="prof_name"></div>
                </div>
              </div>
              <div className="col-xl-9 col-12">
                <div className="row">
                  <div className="col-xl-4 col-md-6 col-12 mb-3">
                    <label>First Name</label>
                    <div className="input-group">
                      <input
                        onChange={(e) => handleInputs(e)}
                        name="firstname"
                        className="input-box m-0"
                        value={adminProfile.firstname}
                           type="text"
                      />
                    </div>
                    <div className="text-danger">{errors.firstname}</div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Last Name</label>
                    <div className="input-group">
                      <input
                        onChange={(e) => handleInputs(e)}
                        name="lastname"
                        className="input-box m-0"
                        value={adminProfile.lastname}
                         type="text"
                      />
                      <div className="text-danger">{errors.lastname}</div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Gender</label>
                    <div className="input-group">
                      <i className="fa fa-angle-down darrow-i"></i>
                      <select
                        onChange={(e) => handleInputs(e)}
                        name="gender"
                        className="input-box"
                        value={adminProfile.gender}
                      
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Non-Binary">Non-Binary</option>
                      </select>
                    </div>
                    <div className="text-danger">{errors.gender}</div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Fitness Goals</label>
                    <div className="input-group">
                      <i className="fa fa-angle-down darrow-i"></i>
                      <select
                        onChange={(e) => handleInputs(e)}
                        name="fitnessGoals"
                        className="input-box m-0"
                        value={adminProfile.fitnessGoals}
                      
                      >
                        <option>Select</option>
                        <option value="01">Lower Body Fat</option>
                        <option value="02">Lean And Toned</option>
                        <option value="03">Build Muscle</option>
                      </select>
                    </div>
                    <div className="text-danger">{errors.fitnessGoals}</div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Email</label>
                    <div className="input-group">
                      <input
                        onChange={(e) => handleInputs(e)}
                        name="email"
                        className="input-box m-0"
                        value={adminProfile.email}
                        type="email"
                      
                      />
                    </div>
                    <div className="text-danger">{errors.email}</div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Contact No</label>
                  
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      name="phoneno"
                      placeholder="Phone number"
                      value={mobileNumber}
                      onChange={(value, country) => {
                        // setUser((prev)=>({...prev,[e.target.name]:e.target.value}))
                        const phone = value.replace(country.dialCode, "");
                        setAdminProfile((prev) => ({ ...prev, phoneno: phone }));
                        setAdminProfile((prev) => ({ ...prev, country: country.countryCode }));
                        setAdminProfile((prev) => ({ ...prev, countryDialCode: `+${country.dialCode}` }));
                      }}
                    />
                    {/* </div> */}
                    <div className="text-danger">{errors.phoneno}</div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 mb-3">
                    <div className="d-flex justify-content-end align-items-center">
                      <button className="profile-btn mt-3 s-media" onClick={openModal}>
                        Add Social media
                      </button>
                    </div>
                    {adminProfile.socialmedia.length > 0 ? (<>
                      <label className="media-title">Social Media</label>

                      {adminProfile?.socialmedia && (
                        <ul className="list-inlne social-media-icon">
                          {adminProfile?.socialmedia?.map((media, i) => (
                            <li key={i} className="list-inline-item position-relative" >
                              <Link
                                className="text-decoration-none"
                                to={media.url}
                                target="_blank"
                              >
                                <span className="px-1">
                                  <i
                                    className={media.icon}
                                    aria-hidden="true"
                                    title={media.platform}
                                  ></i>
                                </span>
                              </Link>
                              <div
                                onClick={() => removeMedi(media._id)}
                                className="c-mediaicon"
                              >
                                <i className="fa fa-times"></i>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>) : (<></>)}
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div className="col-md-12 col-12 text-center">
            <button
              className="profile-btn mt-3"
              type="button"
              disabled={isButtonDisabled}
              onClick={() => submitProfile()}
            >
              {/* {buttonLoader ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              ) : (
                ""
              )} */}
              Save
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={socialmediaModal}
        onHide={(e) => {
          setSocialmediaModal(false);
        }}
        className="mbody socialmedia"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {popuploader &&
          <div id="loadermodal" className="loader"><div className="popuploader loginloader"></div></div>
        }

        <Modal.Header className="session-m" closeButton>
          <Modal.Title className="book-title d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="" />
            <h4 className="mb-0 ml-3 font-weight-bold">Add Social Media</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="col-md-12 col-12 mb-3">
            <label className="lbl-name">Name </label>
            <div className="input-group">
              <i className="fa fa-angle-down darrow-i"></i>
              <select
                ref={iconRef}
                className="input-box"
                name="platform"
                onChange={socialmediaHandle}
              >
                <option value="" disabled selected="selected">
                  Select Name
                </option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
                <option value="Linkedin">Linkedin</option>
                <option value="Youtube">Youtube</option>
                <option value="Tiktok">Tiktok </option>
              </select>
            </div>
            <div className="text-danger">{errors.platform}</div>
          </div>

          <div className="col-md-12 col-12 mb-3">
            <label className="lbl-name">Url </label>
            <input
              className="input-box"
              type="url"
              placeholder="Url"
              autoComplete="off"
              onChange={socialmediaHandle}
              name="url"
              ref={urlRef}
            />
            <div className="text-danger">{errors.url}</div>
          </div>
          <div className="col-12">
            <button disabled={isButtonDisabled} className="training_btn mt-4" onClick={socialmediaAdd}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ProfileManagment;
