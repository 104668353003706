import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";

function ProtectedRoute() {
  const token = sessionStorage.getItem("token");

  axios.defaults.headers.common["authorization"] =
    sessionStorage.getItem("token");

  if (!token) {
    return <Navigate to={"/"} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
