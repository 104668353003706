import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../environment/environment";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import CryptoJS from 'crypto-js'
const secret_key="devloper@godofwebsite"

function Login() {
  const navigate = useNavigate();
  const usertype = sessionStorage.getItem("usertype");

  const [errors, setErrors] = useState({});
  // const [loader, setLoader] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  // const [dataLoader, setDataLoader] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  sessionStorage.setItem("usertype", "admin");

  const [user, setUser] = useState({
    email: "",
    password: "",
    rememberMe:false
  });

  
  const encriptData=CryptoJS.AES.encrypt(JSON.stringify(user),secret_key).toString()
  const storedEncriptData=localStorage.getItem("encripteded")
 
  useEffect(() => {
    if (storedEncriptData) {
      var bytes = CryptoJS.AES.decrypt(storedEncriptData, secret_key);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log("decryptedData",decryptedData);
      setUser({email:decryptedData?.email,password:decryptedData?.password,rememberMe:decryptedData?.rememberMe})
    }
  }, [storedEncriptData])
  

  
  const handleInputs = (e) => {
    const { name, value, type, checked } = e.target;
    setUser({ ...user,  [name]: type === 'checkbox' ? checked : value });
    // setUser({ ...user, [e.target.name]: e.target.value });
  };

  const PostLogin = async (e) => {
    e.preventDefault();

    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (user.email === "") {
      errormsg.email = "Please enter email id!";
      isValid = false;
    } 
    else if (reg_email.test(user.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    }
    if (user.password === "") {
      errormsg.password = "Please enter password!";
      isValid = false;
    }
    // 

    setErrors(errormsg);
    if (isValid) {
      // setLoader(true);
      // setDataLoader(true)
      setIsButtonDisabled(true)
      setButtonLoader(true)
      await axios
        .post(
          `${apiUrl}/admin/account/login`,
          { email: user.email, password: user.password },
          {}
        )
        .then(function (response) {

          if (response.data.status === 1) {
            setTimeout(() => {

              axios.defaults.headers.common["Authorization"] =response.data.result.token;
              sessionStorage.setItem("user",JSON.stringify(response.data.result.User));
              sessionStorage.setItem("usertype", usertype);
              sessionStorage.setItem("token", response.data.result.token);

              if (user.rememberMe) {
                localStorage.setItem("encripteded",encriptData)
              }else{
                localStorage.removeItem("encripteded",encriptData)
              }

              setButtonLoader(false)
              setIsButtonDisabled(false)
              navigate("/dashboard");
            }, 500);
           
          } else {
            // setDataLoader(false)
            setButtonLoader(false)
            setIsButtonDisabled(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          // setLoader(false);
          // setDataLoader(false)
          // document.querySelector(".loader").classList.add("d-none");
          console.log(error);
        });
    }
  };

 
  return (
    <>
      {/* {loader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}
      <div className="bg-img">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-12 mx-auto">
              <div className="login-box">
                <div className="loader d-none">
                  <div className="popuploader loginloader"></div>
                </div>
                <div className="log_logo">
                  <img src="/img/common-health.png" alt="Logo" />
                </div>
                <div className="panel-body">
                  <div className="col-md-12 col-sm-12 col-12 mb-3">
                    <div className="position-relative">
                      <input
                        onChange={(e) => handleInputs(e)}
                        name="email"
                        value={user.email}
                        className="input-box"
                        type="text"
                        placeholder="Email"
                      />
                      <i className="fa fa-envelope log_i"></i>
                    </div>
                    <div className="text-danger">{errors.email}</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-12 mb-3">
                    <div className="position-relative">
                      <input
                        onChange={(e) => handleInputs(e)}
                        name="password"
                        className="input-box"
                        value={user.password}
                        type={isHidden === true ? "password" : "text"}
                        placeholder="Password"
                      />
                      <i
                        className={`fa fa-eye${
                          isHidden === false ? "" : "-slash"
                        } log_i eyeicon`}
                        onClick={() => setIsHidden(!isHidden)}
                      ></i>
                    </div>
                    <div className="text-danger">{errors.password}</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-12 mb-2 dispinline pl-0 mt-2">
                    <div className="checkbox checkbox-primary float-sm-left">
                      <input id="RememberMe" type="checkbox"  onChange={(e) => handleInputs(e)} checked={user.rememberMe} name="rememberMe" />
                      <label htmlFor="RememberMe">Remember Me</label>
                    </div>
                    <div className="frgot_pass float-sm-right">
                      <Link to="/forgotpassword">Forgot password?</Link>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-12 text-right ">
                    <button
                     disabled={isButtonDisabled}
                      onClick={(e) => PostLogin(e)}
                      type="submit"
                      className="log_btn"
                    >
                      Login
                      {buttonLoader ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm ml-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          ""
                        )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
