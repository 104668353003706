import axios from "axios";
import React, { useState } from "react";
import { apiUrl } from "../environment/environment";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  sessionStorage.clear();
  const navigate = useNavigate()
  var uri = window.location.pathname;
  let splituri = uri.split("/");
  let usertype = splituri[1];

  const [user, setUser] = useState({
    password: "",
    confirmpassword: "",
  });
  const [error, setError] = useState({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isHidden, setIsHidden] = useState(true);


  const handleInputs = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const Reset = async (e) => {
    e.preventDefault();

    let isValid = true;
    var errormsg = {};

    if (user.password === "") {
      errormsg.password = "Please enter password!";
      isValid = false;
    }
    // else if ((user.password.length===6)===false) {
    //   errormsg.password = "Password length must be 6!";
    //   isValid = false;
    // }
    if (user.confirmpassword === "") {
      errormsg.confirmpassword = "Please enter confirm password!";
      isValid = false;
    }
    // else if ((user.confirmpassword.length === 6 === false)) {
    //   errormsg.confirmpassword = "Confirm password length must be 6!";
    //   isValid = false;
    // }

    if (user.password !== user.confirmpassword) {
      errormsg.confirmpassword = "Password and confirm password do not match!";
      isValid = false;
    }

    setError(errormsg)

    if (isValid) {
      setIsButtonDisabled(true)
      setButtonLoader(true)
      await axios
        .post(
          `${apiUrl}/${usertype}/account/resetpassword/${splituri[4]}`,
          { password: user.password, confirmpassword: user.confirmpassword },
          {}
        )
        .then(function (response) {
          if (response.data.status === 1) {
            //window.alert(response.data.message);
            setIsButtonDisabled(false)
            setButtonLoader(false)
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: true,
            });
            // window.location.href = "/login";
            navigate("/login");
          } else {
            setIsButtonDisabled(false)
            setButtonLoader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  };

  return (
    <>
      <div className="bg-img">
        <div className="container">
          <div className="resetbox">
            <div className="col-5 mx-auto">
              <div className="updatepassword">
                <div className="row">
                  <div className="col-12">
                    <h4 className="change-pass">Reset Password</h4>
                  </div>
                  <form className="w-100">
                    <div className="col-md-12 col-sm-12 col-12">
                      <input
                        onChange={(e) => handleInputs(e)}
                        name="password"
                        type={isHidden === true ? "password" : "text"}
                        placeholder="Password"
                        className="input_box"
                      />
                      {/* <i
                        className={`fa fa-eye${isHidden === false ? "" : ""
                          } regi-i eyeicon`}
                        onClick={() => setIsHidden(!isHidden)}
                      ></i> */}
                      <i
                        className={`fa fa-eye${
                          isHidden === false ? "" : "-slash"
                        } log_i eyeicon`}
                        onClick={() => setIsHidden(!isHidden)}
                      ></i>
                    </div>
                    <div className="text-red">{error.password}</div>
                    <div className="col-md-12 col-sm-12 col-12">

                      <input
                        onChange={(e) => handleInputs(e)}
                        name="confirmpassword"
                        type={isHidden === true ? "password" : "text"}
                        placeholder="Confirm Password"
                        className="input_box"
                      />
                      {/* <i
                        className={`fa fa-eye${isHidden === false ? "" : ""
                          } regi-i eyeicon`}
                        onClick={() => setIsHidden(!isHidden)}
                      ></i> */}
                      <i
                        className={`fa fa-eye${
                          isHidden === false ? "" : "-slash"
                        } log_i eyeicon`}
                        onClick={() => setIsHidden(!isHidden)}
                      ></i>
                    </div>
                    <div className="text-red">{error.confirmpassword}</div>
                    <div className="col-md-12 col-sm-12 col-12">
                      <button
                        disabled={isButtonDisabled}
                        onClick={(e) => Reset(e)}
                        type="submit"
                        className="login_btn"
                      >
                        {buttonLoader ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          ""
                        )}
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
