// import { logDOM } from "@testing-library/react";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
function ClientDetails() {
  const [clientData, setClientData] = useState("");
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let clientId = query.get("Id");

  useEffect(() => {
    getClientDetails(clientId);
  }, [clientId]);

  const getClientDetails = async (clientId) => {
    await axios
      .post(`${apiUrl}/admin/account/getclient`, { id: clientId })
      .then((response) => {
        if (response.data.status === 1) {
          setClientData(response.data.result);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4 className="mb-0">Client Details</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/client">Clients</Link>
                  </li>
                  <li className="breadcrumb-item active">Client Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="col-md-12 col-12 record_table mt-2 client_detail">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      First Name <span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.firstname}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Last Name <span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.lastname}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Email <span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.email}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Password <span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>********</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Mobile No<span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.phoneno}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Age<span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.age}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Gender<span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.gender}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Height<span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.height} Feet</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Weight<span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.weight} Kg</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Equipment<span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.equipmentavailable}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Fitness Goal<span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.fitnessgoals}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Injuries / Health Issues <span>:</span>
                    </label>
                    <div className="col-md-7 col-12">
                      <span>{clientData.injuriesorhelthissues}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <label className="col-md-5 col-12">
                      Profile Image <span>:</span>
                    </label>
                    <div className="col-md-7 col-12 proimg">
                      {clientData?.profile ? (
                        <img
                          src={apiUrl + clientData?.profile}
                          alt=""
                          onError={(e) => {
                            e.target.src = "/img/Small-no-img.png";
                          }}
                        />
                      ) : (
                        <img src={"/img/Small-no-img.png"} alt="" />
                      )}

                      {/* <img src={`${apiUrl}${clientData.profile}`} onError={(e) => { e.target.src = "/img/Small-no-img.png" }} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientDetails;
