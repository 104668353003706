import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../environment/environment";
import Swal from "sweetalert2";
import swal from "sweetalert";
import Pagination from "../Pagination/Pagination";

function Contectus() {
  const navigate = useNavigate();

  const { pages } = useParams();
  const [dataLoader, setDataLoader] = useState(false);

  const [pageNum, setPageNum] = useState(+pages);
  // const [isLoader, setIsLoader] = useState(false);
  const [getData, setGetData] = useState([]);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const limitValue = 10;


  function getContactusData(val) {
    // setIsLoader(true);
    setDataLoader(true);
    const obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
    };
    axios
      .post(`${apiUrl}/admin/getcontactus`, obj)
      .then((response) => {
        setTimeout(() => {
          setGetData(response.data.result.findContactUs);
          setNoOfRecords(response.data.result.noOfRecords);
          // setIsLoader(false);
          setDataLoader(false);
        }, 1000);
      })
      .catch((err) => {
        window.alert(err);
        setDataLoader(false);
      });
  }

  useEffect(() => {
    getContactusData(pageNum);
  }, []);

  const deleteClick = (e) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // setIsLoader(true);
          setDataLoader(true);
          axios
            .post(`${apiUrl}/admin/removecontact/${e._id}`)
            .then(function (response) {
              if (response.data.status === 1) {
                setTimeout(async () => {
                  const newList = getData.filter((x) => x._id !== e._id);
                  if (newList?.length === 0) {
                    if (pageNum === 1) {
                      setPageNum(pageNum);
                      await getContactusData(pageNum);
                      setGetData(newList);
                      navigate(`/contactus/${pageNum}`);
                    } else {
                      setPageNum(pageNum - 1);
                      await getContactusData(pageNum - 1);
                      setGetData(newList);
                      navigate(`/contactus/${pageNum - 1}`);
                    }
                  } else {
                    setGetData(newList);
                  }
                  swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true,
                  });
                  // setIsLoader(false);
                  setDataLoader(false);
                }, 1000);
              } else {
                // setIsLoader(false);
                setDataLoader(false);
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                  button: true,
                });
              }
            })
            .catch((err) => {
              setDataLoader(false);
              // console.log(err);
              window.alert(err);
              // setIsLoader(false);
            });
        }
      });
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    getContactusData(pageNum);
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      setPageNum(pageNum); // Reset page number when clearing the search
      getContactusData(pageNum);
    }
  };

  const searchClick = async (searchData, pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };
      const response = await axios.post(`${apiUrl}/admin/searchcontactus`, { searchname: searchData, obj }, {})
      if (response.data.status === 1) {
        // console.log("search data",response?.data?.result);
        setGetData(response?.data?.result?.paginatedResults);
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0);
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }

    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // if (!searchData) {
    //   setDataLoader(false);
    //   // setIsLoader(false);
    //   getContactusData(pageNum);
    //   setDataLoader(false);
    //   // setIsLoader(false);
    // } else {
    //   await axios
    //     .post(`${apiUrl}/admin/searchcontactus`, { searchname: searchData }, {})
    //     .then(function (response) {
    //       // setDataLoader(false)
    //       // setIsLoader(false);
    //       if (response.data.status === 1) {
    //         setGetData(response.data.result);
    //         setSearching(true);
    //       } else {
    //         setDataLoader(false);
    //         // setIsLoader(false);
    //         swal({
    //           title: "Error!",
    //           text: response.data.message,
    //           icon: "error",
    //           button: true,
    //         });
    //       }
    //     })
    //     .catch(function (error) {
    //       setDataLoader(false);
    //       // setIsLoader(false);
    //       window.alert(error);
    //     });
    // }
  };

  const curPage = (pageNum) => {
    navigate(`/contactus/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async () => {
        await searchClick(searchname, pageNum);
        setDataLoader(false);
      }, 500);
    } else {
      getContactusData(pageNum);
    }
  };

  return (
    <>
      {/* <div className="loading d-none">
          <div className="mainloader"></div>
        </div> */}
      {/* {isLoader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4 className="mb-0">Contact Us Detail</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card otherpage-box">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <>
                      <button className="search_btn mt-0" onClick={clearClick}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="search_btn mt-0"
                        onClick={() => searchClick("")}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive">
                    {dataLoader ? (
                      // <span>Loadnig..</span>
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {getData?.length > 0 ? (
                      <>
                        <table className="table table-bordered table-hover mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>Sr. No</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email</th>
                              <th>message</th>
                              <th className="text-center w130">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {getData.map((ele, i) => {
                              return (
                                <>
                                  <tr key={"index" + i}>
                                    <td style={{ width: "50px" }}>
                                      {i + 1 + (pageNum - 1) * limitValue}
                                    </td>
                                    <td>{ele.firstname}</td>
                                    <td>{ele.lastname}</td>
                                    <td>{ele.email}</td>
                                    <td>{ele.message}</td>
                                    <td className="text-center">
                                      <button
                                        className="btn btn-delete mr-2"
                                        onClick={() => {
                                          deleteClick(ele);
                                        }}
                                      >
                                        <i className="ace-icon fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {dataLoader === false && (
                          <div className="text-center" colSpan="10">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Contact us Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              
                    {getData?.length > 0 && (
                      <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                        <Pagination
                          className="pagination-bar"
                          currentPage={pageNum}
                          totalCount={noOfRecords}
                          pageSize={limitValue}
                          onPageChange={(page) => curPage(page)}
                        />
                      </div>
                    )}
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contectus;
