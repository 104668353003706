/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { apiUrl } from "../environment/environment";
import Pagination from "./Pagination/Pagination";
import swal from "sweetalert";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";


function Client() {
  const navigate = useNavigate();
  const defaultImg = "/img/Small-no-img.png";
  const { pages } = useParams();
  const [pageNum, setPageNum] = useState(+pages);
  const [dataLoader, setDataLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [List, setList] = useState([]);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [gender, setGender] = useState("");
  //const [EditList, SetEditList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  //const [show, setShow] = useState(false);
  const [userdetails, setUserDetails] = useState({
    _id: "",
    isactive: true,
    firstname: "",
    lastname: "",
    email: "",
    phoneno: "",
    countryDialCode: "",
    country: "",
    age: "",
    gender: "",
    profile: "",
    heightisfeet: true,
    height: "",
    weightiskg: true,
    weight: "",
    progressphotos: "",
    equipmentavailable: "",
    fitnessgoals: "",
    otherfitnessgoals: "",
    injuriesorhelthissues: "",
    emailnotifications: false,
    maillinglist: false,
    textnotifications: false,
    webnotifications: false,
    mobilenotifications: false,
  });

  const [show, setShow] = useState(false);
  // const [isLoader, setIsLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobilenotifications, setMobilenotifications] = useState(false);
  const [emailnotifications, setEmailnotifications] = useState(false);
  const [textnotifications, setTextnotifications] = useState(false);
  const [webnotifications, setWebnotifications] = useState(false);
  const [maillisting, setMaillisting] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const limitValue = 10;
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const [columnArr, setColumnArr] = useState([
    { name: "Profile", value: "profile", dir: 1 },
    { name: "Name", value: "firstname", dir: 1 },
    // { name: "Last Name", value: "lastname", dir: 1 },
    { name: "Email", value: "email", dir: 1 },
    { name: "Phone No", value: "phoneno", dir: 1 },
    // { name: "Age", value: "age", dir: 1 },
    // { name: "Gender", value: "gender", dir: 1 },
  ]);

  const [imagepreview, setImagePreview] = useState(defaultImg);
  const [getImage, setGetImage] = useState();
  const [selectAllGoal, setSelectAllGoal] = useState([]);
  const [fitnessgoal, setFitnessGoal] = useState([]);

  //Onload event set here.
  useEffect(() => {
    GetList(pages);
    Getgoals();
  }, []);

  const handleChange = (e) => {
    setGender(e);
    setUserDetails({ ...userdetails, gender: e });
  };

  const handleInputs = (e) => {
    setUserDetails({ ...userdetails, [e.target.name]: e.target.value });
  };

  const handleMoblieNotifcation = () => {
    setMobilenotifications(!mobilenotifications);
    setUserDetails({
      ...userdetails,
      mobilenotifications: !mobilenotifications,
    });
  };

  const handleEmailNotification = () => {
    setEmailnotifications(!emailnotifications);
    setUserDetails({ ...userdetails, emailnotifications: !emailnotifications });
  };

  const handleTextNotification = () => {
    setTextnotifications(!textnotifications);
    setUserDetails({ ...userdetails, textnotifications: !textnotifications });
  };

  const handleWebnotification = () => {
    setWebnotifications(!webnotifications);
    setUserDetails({ ...userdetails, webnotifications: !webnotifications });
  };

  const handleMaillisting = () => {
    setMaillisting(!maillisting);
    setUserDetails({ ...userdetails, maillinglist: !maillisting });
  };

  const handleOnChange = () => {
    setIsActive(!isActive);
    setUserDetails({ ...userdetails, isactive: !isActive });
  };

  async function GetList(val) {
    setSelectedPage(val);
    // setIsLoader(true)
    setDataLoader(true);
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
      // searchvalue: searchname || ""
    };

    await axios
      .post(`${apiUrl}/admin/clientlist`, obj)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            setNoOfRecords(response.data?.result?.noOfRecords || 0);
            setList(response.data.result?.memberlist);
            // setIsLoader(false)
            setDataLoader(false);
          }, 1000);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        // setIsLoader(false)
        setDataLoader(false);
        window.alert(error);
      });
  }

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  // const curPage = (pageNum) => {
  //   navigate(`/member/${pageNum}`);
  //   setPageNum(pageNum);
  //   GetList(pageNum);
  // };

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    // setIsLoader(false)
    setDataLoader(false);
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetList(selectedPage);
    setDataLoader(false);

    // setIsLoader(false)
  };

  const onFileChange = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 2096000) {
      setImagePreview(defaultImg);
      alert("File size more than 2 MB. File size must under 2MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      setGetImage(event.target.files[0]);

      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        setIsEdit(true);
        setImagePreview(result);
      };

      fileReader.readAsDataURL(file);
    }
  };

  const range = (start, end, step) => {
    return Array.from(
      Array.from(Array(Math.ceil((end - start) / step)).keys()),
      (x) => start + x * step
    );
  };

  const SelectHeight = (e, number) => {
    if (document.querySelector("#heightul li.active") !== null)
      document.querySelector("#heightul li.active").classList.remove("active");

    e.currentTarget.classList.add("active");

    userdetails.height = number;
  };

  const SelectWeight = (e, number) => {
    if (document.querySelector("#weightul li.active") !== null)
      document.querySelector("#weightul li.active").classList.remove("active");

    e.currentTarget.classList.add("active");

    userdetails.weight = number;
  };

  async function Getgoals() {
    await axios.get(`${apiUrl}/client/getallgoals`).then((res) => {
      setSelectAllGoal(res.data.result);
    });
  }

  const handleFitnessGoal = (e) => {
    if (e.currentTarget.checked) {
      fitnessgoal.push(e.target.value);
      setFitnessGoal(fitnessgoal.map((x) => x));
      userdetails.fitnessgoals = fitnessgoal.map((x) => x).join(",");
    } else {
      setFitnessGoal(fitnessgoal.filter((x) => x !== e.target.value));
      userdetails.fitnessgoals = fitnessgoal
        .filter((x) => x !== e.target.value)
        .join(",");
    }
  };

  const onChangehandle = async (e, obj) => {
    setUserDetails((prev) =>
      prev._id === obj._id ? { ...prev, isactive: !prev.isactive } : prev
    );
    const obje = {
      _id: obj._id,
      isactive: e.currentTarget.checked ? true : false,
    };

    setButtonLoader(true);

    await axios
      .post(`${apiUrl}/client/clientStatus`, obje, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setDataLoader(false)
          GetList(pageNum);
          setDataLoader(false)
          // setIsLoader(false);
          setButtonLoader(false);

          // const newState = List.map((x) => {
          //   if (x._id === obj._id) {
          //     return { ...x, statusid: response.data.result.statusid };
          //   }
          //   return x;
          // });
          // setList(newState);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          // setIsLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // setIsLoader(false);
        console.log(error);
      });
  };

  const validatePhoneNumber = (phoneNumber, countryCode) => {
    const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  
    if (countryCode === 'no') {
      return formattedPhoneNumber?.length === 8;
    } else {
      return formattedPhoneNumber?.length === 10;
    }
  };

  const saveClick = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};
    let reg_email =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let reg_numbers = /^[0-9]+$/;

    if (userdetails.firstname === "") {
      errormsg.firstname = "Please enter First Name.";
      isValid = false;
    } else if (
      userdetails.firstname?.length >= 3 === false ||
      userdetails.firstname?.length <= 50 === false
    ) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    } 

    if (userdetails.lastname === "") {
      errormsg.lastname = "Please enter Last Name.";
      isValid = false;
    } else if (
      userdetails.lastname?.length >= 3 === false ||
      userdetails.lastname?.length <= 50 === false
    ) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    } 

    if (userdetails.email === "") {
      errormsg.email = "Please enter Email.";
      isValid = false;
    } else if (reg_email.test(userdetails.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    } else if (userdetails.email?.length <= 50 === false) {
      errormsg.email = "Please enter maximum 256";
      isValid = false;
    }

    if (userdetails.phoneno === "") {
      errormsg.phoneno = "Please enter Phone number.";
      isValid = false;
    } else if (!validatePhoneNumber(userdetails.phoneno, userdetails.country)) {
      errormsg.phoneno = "Invalid phone number for the selected country.";
      isValid = false;
    }

    if (userdetails.age === "") {
      errormsg.age = "Please enter Age.";
      isValid = false;
    } else if (
      userdetails.age < 5 ||
      userdetails.age > 120 ||
      !reg_numbers.test(userdetails.age)
    ) {
      errormsg.age = "Please enter valid Age.";
      isValid = false;
    } 

    if (userdetails.gender === "") {
      errormsg.gender = "Please select gender.";
      isValid = false;
    }
    if (userdetails.height === "") {
      errormsg.height = "Please select height.";
      isValid = false;
    }
    if (userdetails.weight === "") {
      errormsg.Weight = "Please select Weight.";
      isValid = false;
    }
    if (userdetails.equipmentavailable === "") {
      errormsg.equipmentavailable = "Please enter equipmentavailable.";
      isValid = false;
    } else if (
      userdetails?.equipmentavailable?.length >= 3 === false ||
      userdetails?.equipmentavailable?.length <= 50 === false
    ) {
      errormsg.equipmentavailable = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    } 

    if (userdetails.otherfitnessgoals === "") {
      errormsg.otherfitness = "Please enter other fitness goals.";
      isValid = false;
    } else if (
      userdetails?.otherfitnessgoals?.length >= 3 === false ||
      userdetails?.otherfitnessgoals?.length <= 50 === false
    ) {
      errormsg.otherfitness = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    } 

    if (fitnessgoal === "") {
      errormsg.fitnessgoal = "Please select fitness goal!.";
      isValid = false;
    }

    if (userdetails.injuriesorhelthissues === "") {
      errormsg.injuriesorhelthissues ="Please enter Injuries Or Helth Issues!.";
      isValid = false;
    } else if (
      userdetails?.injuriesorhelthissues?.length >= 3 === false ||
      userdetails?.injuriesorhelthissues?.length <= 50 === false
    ) {
      errormsg.injuriesorhelthissues ="Please enter minimum 3 letters or maximum 50";
      isValid = false;
    } 

    setErrors(errormsg);
    if (isValid) {
      let arry = [];
      for (const [key, value] of Object.entries(fitnessgoal)) {
        arry.push(value);
      }
      userdetails.fitnessgoals = arry;

      let obj = {
        _id: userdetails._id,
        isactive: userdetails.isactive ? true : false,
        firstname: userdetails.firstname,
        lastname: userdetails.lastname,
        email: userdetails.email,
        phoneno: userdetails.phoneno,
        countryDialCode: userdetails.countryDialCode,
        country: userdetails.country,
        age: userdetails.age,
        gender: userdetails.gender,
        heightisfeet: userdetails.heightisfeet,
        height: userdetails.height,
        weightiskg: userdetails.weightiskg,
        weight: userdetails.weight,
        equipmentavailable: userdetails.equipmentavailable,
        fitnessgoals: JSON.stringify(userdetails.fitnessgoals),
        otherfitnessgoals: userdetails.otherfitnessgoals,
        injuriesorhelthissues: userdetails.injuriesorhelthissues,
        emailnotifications: userdetails.emailnotifications,
        maillinglist: userdetails.maillinglist,
        textnotifications: userdetails.textnotifications,
        webnotifications: userdetails.webnotifications,
        mobilenotifications: userdetails.mobilenotifications,
      };
      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      // PROFILE IMAGE
      let profile;
      if (typeof getImage == "string") {
        profile = getImage.split(apiUrl);
        profile = profile[1] || "";
        setGetImage(...getImage, profile);
      } else {
        profile = getImage;
      }
      formData.append("profile", profile);
      setIsButtonDisabled(true);
      setButtonLoader(true);
      setPopuploader(true);
      // setIsLoader(true)
      await axios
        .post(`${apiUrl}/client/saveclient`, formData)
        .then(function (response) {
          // setIsLoader(true)
          if (response.data.status === 1) {
            setTimeout(() => {
              searchClick();
              setDataLoader(false);
              setIsEdit(false);
              GetList(pageNum);
              setDataLoader(false);
              setIsButtonDisabled(false);
              setButtonLoader(false);
              setPopuploader(false);
              // setList(prev=>[...prev,response.data.result])
              navigate(`/member/${pageNum}`);
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              handleClose();
            }, 1000);
          } else {
            // setIsLoader(false)
            setShow(true);
            setIsButtonDisabled(false);
            setButtonLoader(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          // setIsLoader(false)
          setDataLoader(false);
          console.log(error);
        });
    }
  };

  const [oldImage, setOldImage] = useState(defaultImg)

  const editClick = async (e) => {
    console.log("e", e);
    setIsEdit(false);
    setIsButtonDisabled(false);
    setErrors({});
    userdetails.height = e.height;
    userdetails.weight = e.weight;
    let contectNUmber = e.countryDialCode + e.phoneno;
    setMobileNumber(contectNUmber);
    let profileImage =
      e.profile !== "null" && e.profile !== "undefined" && e.profile !== ""
        ? apiUrl + e.profile
        : defaultImg;
        setOldImage(profileImage)
    setImagePreview(profileImage);
    setGetImage(profileImage);
    setFitnessGoal(e.fitnessgoals);
    setMobilenotifications(e.mobilenotifications);
    setEmailnotifications(e.emailnotifications);
    setTextnotifications(e.textnotifications);
    setWebnotifications(e.webnotifications);
    setMaillisting(e.maillinglist);

    handleShow();
    setIsActive(e.statusid);
    setGender(e.gender);
    setUserDetails({
      ...userdetails,
      _id: e._id,
      firstname: e.firstname,
      profile: e.profile,
      lastname: e.lastname,
      email: e.email,
      phoneno: e.phoneno,
      countryDialCode: e.countryDialCode,
      country: e.country,
      age: e.age,
      equipmentavailable: e.equipmentavailable,
      otherfitnessgoals: e.otherfitnessgoals,
      injuriesorhelthissues: e.injuriesorhelthissues,
      gender: e.gender,
      isactive: e.statusid,
      heightisfeet: e.heightisfeet,
      height: e.height,
      weightiskg: e.weightiskg,
      weight: e.weight,
      mobilenotifications: e.mobilenotifications,
      emailnotifications: e.emailnotifications,
      textnotifications: e.textnotifications,
      webnotifications: e.webnotifications,
      maillinglist: e.maillinglist,
    });
    //SetEditList(e);
  };

  const deleteClick = async (e) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // setIsLoader(true);
          setDataLoader(true);
          axios
            .post(`${apiUrl}/client/deleteclient`, { _id: e }, {})
            .then(function (response) {
              // setIsLoader(false);
              if (response.data.status === 1) {
                // GetList(pageNum ?? 1);
                setTimeout(async () => {
                  const newList = List.filter((x) => x._id !== e);
                  if (newList?.length === 0) {
                    if (pageNum === 1) {
                      setPageNum(pageNum);
                      await GetList(pageNum);
                      setList(newList);
                      navigate(`/member/${pageNum}`);
                    } else {
                      setPageNum(pageNum - 1);
                      await GetList(pageNum - 1);
                      setList(newList);
                      navigate(`/member/${pageNum - 1}`);
                    }
                  } else {
                    setList(newList);
                  }
                  swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true,
                  });
                  setDataLoader(false);
                }, 1000);
              } else {
                setDataLoader(false);
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                  button: true,
                });
              }
            })
            .catch(function (error) {
              // setIsLoader(false);
              setDataLoader(false);
              window.alert(error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const vewdetail = (e) => {
    handleShow1();
    console.log("userdetails",userdetails);
    setUserDetails({
      ...userdetails,
      _id: e._id,
      firstname: e.firstname,
      lastname: e.lastname,
      email: e.email,
      phoneno: e.phoneno,
      countryDialCode: e.countryDialCode,
      age: e.age,
      gender: e.gender,
      height: e.height,
      weight: e.weight,
      equipmentavailable: e.equipmentavailable,
      fitnessgoals: e.fitnessgoals,
      progressphotos: e.progressphotos,
      injuriesorhelthissues: e.injuriesorhelthissues,
      profile: e.profile,
      socialmedia: e.socialmedia,
      // isactive: e.statusid ? "Active" : "Deactive",
      isactive: e.statusid,
    });
  };

  const [popuploader, setPopuploader] = useState(false);

  const OnFileDiacard = () => {
    setIsEdit(false);
    setImagePreview(oldImage);
    setGetImage(oldImage)
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value, pageNum);
    } else {
      setSearching(false);
      setPageNum(pageNum); // Reset page number when clearing the search
      GetList(pageNum);
    }
  };
  
  const searchClick = async (searchData, pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };
  
      const response = await axios.post(
        `${apiUrl}/client/searchclient`,
        { searchname: searchData, obj },
        {}
      );
  
      if (response.data.status === 1) {
        setList(response?.data?.result?.paginatedResults);
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0);
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }
    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
  };
  
  const curPage =(pageNum) => {
    navigate(`/member/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
        setDataLoader(true);
        setTimeout(async() => {
          await searchClick(searchname, pageNum);
          setDataLoader(false);
        }, 500);
      // searchClick(searchname, pageNum);
    } else {
      GetList(pageNum);
    }
  };

  return (
    <>
      {/* {isLoader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Member</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Member</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="search"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <>
                      <button className="search_btn mt-0" onClick={clearClick}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="search_btn mt-0"
                        onClick={() => searchClick("")}
                      >
                        {" "}
                        <i className="fa fa-search"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive client-trainer">
                    {dataLoader ? (
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {List?.length > 0 ? (
                      <>
                        <table className="table table-bordered table-hover mb-0 position-relative">
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>Sr. No</th>
                              {columnArr.map((col, i) => {
                                return (
                                  <th
                                    key={"i" + i}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      isSortedColumn(col.value, col.dir)
                                    }
                                  >
                                    <div className="d-flex justify-content-between">
                                      {col.name}
                                      <span className="align-self-center">
                                        <i className="ml-2 fa fa-sort sort-gray"></i>
                                        <i
                                          className={`${
                                            col.dir === 1
                                              ? "sort-i fa fa-sort-asc"
                                              : "sort-i fa fa-sort-desc"
                                          }`}
                                        ></i>
                                      </span>
                                    </div>
                                  </th>
                                );
                              })}
                              {/* <th className="text-center w130">Star</th> */}
                              <th className="text-center w130">Status</th>
                              <th className="text-center w130">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {List.map((listItems, index) => {
                              return (
                                <tr key={"key" + index}>
                                  <td style={{ width: "50px" }}>
                                    {index + 1 + (pageNum - 1) * limitValue}
                                  </td>
                                  <td>
                                    <img
                                      className="rounded"
                                      style={{ width: "30px", height: "30px" }}
                                      height={50}
                                      width={50}
                                      title={listItems.firstname}
                                      src={
                                        apiUrl + listItems.profile || defaultImg
                                      }
                                      alt="profile"
                                      onError={(e) => {
                                        e.target.src = "/img/Small-no-img.png";
                                      }}
                                    />
                                  </td>
                                  <td className="text-nowrap">
                                    {listItems.firstname} {listItems.lastname}
                                  </td>
                                  {/* <td>{listItems.lastname}</td> */}
                                  <td>{listItems.email}</td>
                                  <td className="text-nowrap">
                                    {listItems.countryDialCode}{" "}
                                    {listItems.phoneno}
                                  </td>
                                  {/* <td>{listItems.age}</td>
                                  <td className="text-nowrap"> {listItems.gender}</td> */}
                                  {/* <td>
                                    <Rating
                                      initialValue="3.5"
                                      size="17"
                                      readonly="true"
                                      allowHover="false"
                                      allowHalfIcon="true"
                                    />
                                  </td> */}
                                  <td>
                                    <div className="onoffswitch">
                                      <input
                                        type="checkbox"
                                        name="onoffswitch"
                                        className="onoffswitch-checkbox"
                                        id={`myonoffswitch ${index}`}
                                        checked={
                                          listItems.statusid === 1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          onChangehandle(e, listItems);
                                        }}
                                      />
                                      <label
                                        className="onoffswitch-label"
                                        htmlFor={`myonoffswitch ${index}`}
                                      >
                                        <span className="onoffswitch-inner active-deactive"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justify-content-center">
                                    <button
                                        title="View"
                                        className="btn btn-eye mr-2"
                                        onClick={() => vewdetail(listItems)}
                                        // to={"/clientdetails?Id=" + listItems._id}
                                      >
                                        <i className="ace-icon fa fa-eye"></i>
                                      </button>
                                      <button
                                        title="Edit"
                                        className="btn btn-edit mr-2"
                                        onClick={() => editClick(listItems)}
                                      >
                                        <i className="ace-icon fas fa-pen-square"></i>
                                      </button>
                                      <button
                                        title="Delete"
                                        className="btn btn-delete mr-2"
                                        onClick={() =>
                                          deleteClick(listItems._id)
                                        }
                                      >
                                        <i className="ace-icon fa fa-trash"></i>
                                      </button>
                                      
                                      <button
                                        className="btn btn-comment d-none"
                                        onClick={handleShow1}
                                      >
                                        <i className="ace-icon fa fa-comment"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                          <div className="text-center" colSpan="10">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                  <h4 className="font-weight-bold">
                                     Member Not Found
                                  </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* {searchname === "" ? (
                  <> */}
                    {List?.length > 0 && (
                      <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                        <Pagination
                          className="pagination-bar"
                          currentPage={pageNum}
                          totalCount={noOfRecords}
                          pageSize={limitValue}
                          onPageChange={(page) => curPage(page)}
                        />
                      </div>
                    )}
                  {/* </>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {popuploader ? (
          <div id="loadermodal" className="loader">
            <div className="popuploader loginloader"></div>
          </div>
        ) : (
          <></>
        )}
        <Modal.Header closeButton>
          <Modal.Title className="d-sm-flex align-items-center justify-content-between mb-0 w-100">
            <div className="d-flex align-items-center">
              <img src="/img/common-health.png" alt="logo" />
              <h4 className="mb-0 ml-3">Edit Member</h4>
            </div>
            <div className="onoffswitch m-0">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                value={isActive}
                id="myonoffswitchedit"
                onChange={handleOnChange}
                checked={isActive}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitchedit">
                <span className="onoffswitch-inner active-deactive"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="Details">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-6">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={onFileChange}
                      />
                      {isEdit ? (
                        <>
                          <button
                            className="img-cancel"
                            onClick={OnFileDiacard}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </>
                      ) : (
                        <>
                          <label htmlFor="imageUpload"></label>
                        </>
                      )}
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{ backgroundImage: `url(${imagepreview})` }}
                      ></div>
                    </div>
                    <div className="prof_name"></div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-12">
                  <div className="col-lg-12 col-md-12 col-12 mb-3">
                    <label>First Name</label>{" "}
                    <span className="text-red font-bold">*</span>
                    <div className="input-group">
                      <input
                        value={userdetails.firstname}
                        onChange={(e) => handleInputs(e)}
                        name="firstname"
                        placeholder="First name"
                        className="input-box m-0"
                        type="text"
                      />
                    </div>
                    <div className="text-danger">{errors.firstname}</div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 mb-3">
                    <label>Last Name</label>{" "}
                    <span className="text-red font-bold">*</span>
                    <div className="input-group">
                      <input
                        value={userdetails.lastname}
                        onChange={(e) => handleInputs(e)}
                        name="lastname"
                        placeholder="Last name"
                        className="input-box m-0"
                        type="text"
                      />
                    </div>
                    <div className="text-danger">{errors.lastname}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Email</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      value={userdetails.email}
                      onChange={(e) => handleInputs(e)}
                      name="email"
                      placeholder="E-mail"
                      className="input-box m-0"
                      type="email"
                    />
                  </div>
                  <div className="text-danger">{errors.email}</div>
                </div>

                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Mobile No</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <PhoneInput
                    country={"in"}
                    enableSearch={true}
                    name="phoneno"
                    placeholder="Phone number"
                    value={mobileNumber}
                    onChange={(value, country) => {
                      const phone = value.replace(country.dialCode, "");
                      setUserDetails((prev) => ({ ...prev, phoneno: phone }));
                      setUserDetails((prev) => ({
                        ...prev,
                        country: country.countryCode,
                      }));
                      setUserDetails((prev) => ({
                        ...prev,
                        countryDialCode: `+${country.dialCode}`,
                      }));
                    }}
                  />
                  <div className="text-danger">{errors.phoneno}</div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Age</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      value={userdetails.age}
                      onChange={(e) => handleInputs(e)}
                      name="age"
                      placeholder="Age"
                      className="input-box m-0"
                      type="text"
                    />
                  </div>
                  <div className="text-danger">{errors.age}</div>
                </div>
                <div className="col-md-6 col-sm-4 col-12 mb-3">
                  <label>Gender</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <div className="input-group m-0">
                    <i className="fa fa-angle-down darrow-i"></i>
                    <select
                      className="input-box"
                      value={gender}
                      onChange={(event) => handleChange(event.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Non-Binary">Non-Binary</option>
                    </select>
                    <div className="text-danger">{errors.gender}</div>
                  </div>
                </div>

                <div className="col-md-12 col-12 my-4">
                  <div className="d-flex justify-content-between mb-3">
                    <label className="pt-2 mb-0">Height</label>
                    <div className="feet-metter d-flex">
                      <input
                        onChange={(e) => handleInputs(e)}
                        checked={userdetails.heightisfeet === true}
                        value={true}
                        type="radio"
                        name="heightisfeet"
                        id="radio1"
                      />
                      <label className="input_lbl mr-2" htmlFor="radio1">
                        Feet
                      </label>
                      <input
                        onChange={(e) => handleInputs(e)}
                        checked={userdetails.heightisfeet === false}
                        value={false}
                        type="radio"
                        name="heightisfeet"
                        id="radio2"
                      />
                      <label className="input_lbl" htmlFor="radio2">
                        Meters
                      </label>
                    </div>
                  </div>
                  <div className="scale-hw">
                    <ul key="heightul" id="heightul" className="scroll">
                      {userdetails.heightisfeet === true
                        ? range(4, 8, 1).map((number, index) => (
                            <span key={Math.random().toString(36).substr(2, 9)}>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.1)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.1
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.2)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.2
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.3)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.3
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.4)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.4
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.5)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.5
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.6)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.6
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.7)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.7
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.8)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.8
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) =>
                                  SelectHeight(e, number - 1 + 0.9)
                                }
                                className={`list-item ${
                                  userdetails.height === number - 1 + 0.9
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number)}
                                className={`list-item ${
                                  userdetails.height === number ? "active" : ""
                                }`}
                              >
                                <span className="line-height"></span>
                                <label>{number}</label>
                              </li>
                            </span>
                          ))
                        : range(100, 240, 10).map((number, index) => (
                            <span key={Math.random().toString(36).substr(2, 9)}>
                              <li
                                onClick={(e) => SelectHeight(e, number - 9)}
                                className={`list-item ${
                                  userdetails.height === number - 9
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 8)}
                                className={`list-item ${
                                  userdetails.height === number - 8
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 7)}
                                className={`list-item ${
                                  userdetails.height === number - 7
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 6)}
                                className={`list-item ${
                                  userdetails.height === number - 6
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 5)}
                                className={`list-item ${
                                  userdetails.height === number - 5
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 4)}
                                className={`list-item ${
                                  userdetails.height === number - 4
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 3)}
                                className={`list-item ${
                                  userdetails.height === number - 3
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 2)}
                                className={`list-item ${
                                  userdetails.height === number - 2
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 1)}
                                className={`list-item ${
                                  userdetails.height === number - 1
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number)}
                                className={`list-item ${
                                  userdetails.height === number ? "active" : ""
                                }`}
                              >
                                <span className="line-height"></span>
                                <label>{number}</label>
                              </li>
                            </span>
                          ))}
                    </ul>
                  </div>
                  <div className="text-danger">{errors.height}</div>
                </div>

                <div className="col-md-12 col-12">
                  <div className="d-flex justify-content-between mb-3">
                    <label className="pt-2 mb-0">Weight</label>
                    <div className="feet-metter d-flex">
                      <input
                        onChange={(e) => handleInputs(e)}
                        checked={userdetails.weightiskg === true}
                        value={true}
                        type="radio"
                        name="weightiskg"
                        id="radio3"
                      />
                      <label className="input_lbl mr-2" htmlFor="radio3">
                        Kg
                      </label>
                      <input
                        onChange={(e) => handleInputs(e)}
                        checked={userdetails.weightiskg === false}
                        value={false}
                        type="radio"
                        name="weightiskg"
                        id="radio4"
                      />
                      <label className="input_lbl" htmlFor="radio4">
                        Pound
                      </label>
                    </div>
                  </div>
                  <div className="scale-hw">
                    <ul id="weightul" className="scroll">
                      {userdetails.weightiskg === true
                        ? range(30, 210, 10).map((number, index) => (
                            <span key={Math.random().toString(36).substr(2, 9)}>
                              <li
                                onClick={(e) => SelectWeight(e, number - 9)}
                                className={`list-item ${
                                  userdetails.weight === number - 9
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 8)}
                                className={`list-item ${
                                  userdetails.weight === number - 8
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 7)}
                                className={`list-item ${
                                  userdetails.weight === number - 7
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 6)}
                                className={`list-item ${
                                  userdetails.weight === number - 6
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 5)}
                                className={`list-item ${
                                  userdetails.weight === number - 5
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 4)}
                                className={`list-item ${
                                  userdetails.weight === number - 4
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 3)}
                                className={`list-item ${
                                  userdetails.weight === number - 3
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 2)}
                                className={`list-item ${
                                  userdetails.weight === number - 2
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 1)}
                                className={`list-item ${
                                  userdetails.weight === number - 1
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number)}
                                className={`list-item ${
                                  userdetails.weight === number ? "active" : ""
                                }`}
                              >
                                <span className="line-height"></span>
                                <label>{number}</label>
                              </li>
                            </span>
                          ))
                        : range(30, 300, 5).map((number, index) => (
                            <span key={Math.random().toString(36).substr(2, 9)}>
                              <li
                                onClick={(e) => SelectWeight(e, number - 4)}
                                className={`list-item ${
                                  userdetails.weight === number - 4
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 3)}
                                className={`list-item ${
                                  userdetails.weight === number - 3
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 2)}
                                className={`list-item ${
                                  userdetails.weight === number - 2
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 1)}
                                className={`list-item ${
                                  userdetails.weight === number - 1
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number)}
                                className={`list-item ${
                                  userdetails.weight === number ? "active" : ""
                                }`}
                              >
                                <span className="line-height"></span>
                                <label>{number}</label>
                              </li>
                            </span>
                          ))}
                    </ul>
                  </div>
                  <div className="text-danger">{errors.weight}</div>
                </div>

                <div className="col-12">
                  <label>Equipment Available</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <input
                    onChange={(e) => handleInputs(e)}
                    value={userdetails.equipmentavailable}
                    name="equipmentavailable"
                    type="text"
                    className="input-box w-100 mb-2"
                    placeholder="Equipment"
                  />
                  <div className="text-danger">{errors.equipmentavailable}</div>
                </div>

                <div className="col-md-12 mt-3 mb-4">
                  <label>Fitness Goals</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <div className="fitnessgoal">
                    <div className="cat action wrapper">
                      {selectAllGoal.map((ele, index) => {
                        const selected = fitnessgoal.filter((x) =>
                          ele.name === x ? true : false
                        );
                        let checked = selected?.length !== 0 ? true : false;

                        return (
                          <>
                            <label className="option option-1">
                              <input
                                type="checkbox"
                                value={ele?.name}
                                name={`fitnessgoals${index + 1}`}
                                onChange={(e) => handleFitnessGoal(e)}
                                id={`fitnessgoals${index + 1}`}
                                checked={checked}
                              />
                              <span>{ele?.name}</span>
                            </label>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="text-danger">{errors.fitnessgoal}</div>
                </div>

                <div className="col-12">
                  <label>Others</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <textarea
                    onChange={(e) => handleInputs(e)}
                    value={userdetails.otherfitnessgoals}
                    name="otherfitnessgoals"
                    className="w-100 mb-3 other-txtarea"
                    placeholder="Please Write Any Other Fitness Goals."
                  ></textarea>
                  <div className="text-danger">{errors.otherfitness}</div>
                </div>

                <div className="col-12">
                  <label>Injuries/Health Issues</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <textarea
                    onChange={(e) => handleInputs(e)}
                    value={userdetails.injuriesorhelthissues}
                    name="injuriesorhelthissues"
                    className="w-100 mb-4 txt-area"
                    placeholder="Let us know of any injuries or health issues you have you have or write 'none' if you have no concerns."
                  ></textarea>
                  <div className="text-danger">
                    {errors.injuriesorhelthissues}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <h4 className="noti-title">Notifications</h4>
                  <div className="row">
                    <div className="col-lg-2 col-md-3 col-12">
                      <label>In-App</label>
                      <div className="onoffswitch m-0">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          value={mobilenotifications}
                          id="apponoftoggle"
                          onChange={handleMoblieNotifcation}
                          checked={mobilenotifications}
                        />
                        <label
                          className="onoffswitch-label"
                          htmlFor="apponoftoggle"
                        >
                          <span className="onoffswitch-inner active-deactive"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-12">
                      <label>Email</label>
                      <div className="onoffswitch m-0">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          value={emailnotifications}
                          id="emailToggle"
                          onChange={handleEmailNotification}
                          checked={emailnotifications}
                        />
                        <label
                          className="onoffswitch-label"
                          htmlFor="emailToggle"
                        >
                          <span className="onoffswitch-inner active-deactive"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-12">
                      <label>Text</label>
                      <div className="onoffswitch m-0">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          value={textnotifications}
                          id="textToggle"
                          onChange={handleTextNotification}
                          checked={textnotifications}
                        />
                        <label
                          className="onoffswitch-label"
                          htmlFor="textToggle"
                        >
                          <span className="onoffswitch-inner active-deactive"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-12">
                      <label>Web</label>
                      <div className="onoffswitch m-0">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          value={webnotifications}
                          id="webToggle"
                          onChange={handleWebnotification}
                          checked={webnotifications}
                        />
                        <label
                          className="onoffswitch-label"
                          htmlFor="webToggle"
                        >
                          <span className="onoffswitch-inner active-deactive"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-12">
                      <label>Maillisting</label>
                      <div className="onoffswitch m-0">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          value={maillisting}
                          id="mailToggle"
                          onChange={handleMaillisting}
                          checked={maillisting}
                        />
                        <label
                          className="onoffswitch-label"
                          htmlFor="mailToggle"
                        >
                          <span className="onoffswitch-inner active-deactive"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn cancel_btn"
            disabled={isButtonDisabled}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="btn save_btn secondary"
            disabled={isButtonDisabled}
            onClick={saveClick}
          >
            {/* {buttonLoader ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            ) : (
              ""
            )}*/}
            {userdetails._id ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* client chat history modal popup */}
      <Modal
        show={show1}
        onHide={handleClose1}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="loadermodal" className="loader d-none">
          <div className="popuploader loginloader"></div>
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Member Detail</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="Details">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="detail-img">
                    {userdetails?.profile ? (
                      <img
                        src={apiUrl + userdetails?.profile}
                        alt=""
                        onError={(e) => {
                          e.target.src = "/img/Small-no-img.png";
                        }}
                      />
                    ) : (
                      <img src={"/img/Small-no-img.png"} alt="" />
                    )}

                    {/* <img src={`${apiUrl}${userdetails.profile}`} onError={(e) => { e.target.src = "/img/Small-no-img.png" }} alt="" /> */}
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="modal-box">
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Name </label>
                        <span>
                          {userdetails?.firstname} {userdetails?.lastname}
                        </span>
                      </div>
                      {/* <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Last Name</label>
                        <span>{userdetails.lastname}</span>
                      </div> */}
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Email</label>
                        <span>{userdetails?.email}</span>
                      </div>
                      {/* <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Password</label>
                        <span>test123</span>
                      </div> */}
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Mobile No</label>
                        <span>
                          {userdetails?.countryDialCode} {userdetails?.phoneno}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Age</label>
                        <span>{userdetails?.age}</span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Star</label>
                        <Rating
                          initialValue="3.5"
                          size="17"
                          readonly="true"
                          allowHover="false"
                          allowHalfIcon="true"
                        />
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Status</label>
                        {/* <span>{userdetails?.isactive}</span> */}
                        {userdetails?.isactive ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">Deactive</span>
                        )}
                      </div>
                      {userdetails?.socialmedia?.length > 0 ? (
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">Social media </label>
                          <span>
                            {userdetails?.socialmedia?.map((media, i) => (
                              <>
                                <li
                                  key={i}
                                  className="list-inline-item position-relative"
                                >
                                  <Link
                                    className="text-decoration-none"
                                    to={media?.url}
                                    target="_blank"
                                  >
                                    <span className="px-1">
                                      <i
                                        className={media?.icon}
                                        aria-hidden="true"
                                        title={media?.platform}
                                      ></i>
                                    </span>
                                  </Link>
                                </li>
                              </>
                            ))}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="modal-box">
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Gender</label>
                        <span>{userdetails?.gender}</span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Height</label>
                        <span>{userdetails?.height} Feet</span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Weight</label>
                        <span>{userdetails?.weight} Kg</span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Equipment</label>
                        <span>{userdetails?.equipmentavailable}</span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Fitness Goal</label>
                        <span>{userdetails?.fitnessgoals}</span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">
                          Injuries / Health Issues
                        </label>
                        <span>{userdetails?.injuriesorhelthissues}</span>
                      </div>
                    </div>
                        {userdetails?.progressphotos?.length > 0 &&
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Progress Photos</label>
                          {userdetails?.progressphotos?.map((ele) => {
                            return (
                              ele?.list?.length > 0 &&
                              ele?.list?.map((singleProgressPhoto) => {
                                let ProgressPhoto =
                                  singleProgressPhoto !== "null" &&
                                  singleProgressPhoto !== ""
                                    ? apiUrl + singleProgressPhoto
                                    : defaultImg;
                                    console.log("ProgressPhoto",ProgressPhoto);
                                return (
                                  <>
                                    <Link
                                      target="_blank"
                                      to={apiUrl + singleProgressPhoto}
                                      className="rounded"
                                    >
                                      <img
                                        src={ProgressPhoto}
                                        alt=""
                                        height={100}
                                        width={100}
                                        onError={(e) => {
                                          e.target.src =
                                            "/img/Small-no-img.png";
                                        }}
                                      />
                                    </Link>
                                  </>
                                );
                              })
                            );
                          })}
                      </div>
                    </div>
                          }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* client chat history modal popup End*/}
    </>
  );
}

export default Client;
