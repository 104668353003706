/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { apiUrl } from "../environment/environment";
import Pagination from "./Pagination/Pagination";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";


function Trainer() {
  const fileRightIcon = "/img/file.png";
  const defaultImg = "/img/Small-no-img.png";

  const { pages } = useParams();

 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(+pages);
  const [List, setList] = useState([]);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [gender, setGender] = useState("");
  const [type, setType] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [userdetails, setUserDetails] = useState({
    _id: "",
    firstname: "",
    lastname: "",
    email: "",
    phoneno: "",
    type: true,
    countryDialCode: "",
    gender: "",
    aboutus: "",
    trainingstyle: "",
    quote: "",
    experience: "",
    qualifications: "",
    specialitys: "",
    introduction: "",
    certifications: "",
    emailnotifications: false,
    maillinglist: false,
    textnotifications: false,
    notification: false,
    isactive: true,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [errors, setErrors] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [imagepreview, setImagePreview] = useState();
  const [getImage, setGetImage] = useState();

  // const [isLoader, setIsLoader] = useState(false);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const limitValue = 10;

  const [workoutList, setWorkOutList] = useState([]);
  const [tags, settags] = useState([]);

  const [qualificationslist, setQualifications] = useState([]);
  const [qualification, setQualification] = useState("");
  const [imagesQuaPathList, setImagesQuaPathList] = useState([]);
  const [qualificationshtmllist, setHtmlQualifications] = useState([]);
  
  const [filterWorkout, setFilterWorkout] = useState([]);
  const [expVal, setExp] = useState(1);
  const trainingstyle = useRef("");

  // console.log("imagesQuaPathList",imagesQuaPathList);

  const [certificationslist, setCertifications] = useState([]);
  const [certificationshtmllist, setHtmlCertifications] = useState([]);
  const [certification, setCertification] = useState("");
  const [cerimagesPathList, setCerImagesPathList] = useState([]);
  // const [certificateView, setCertificateView] = useState([])
  const [popuploader, setPopuploader] = useState(false);

  const [columnArr, setColumnArr] = useState([
    { name: "Profile", value: "profile", dir: 1 },
    { name: "Name", value: "firstname", dir: 1 },
    // { name: "Last Name", value: "lastname", dir: 1 },
    { name: "Email", value: "email", dir: 1 },
    { name: "Phone No", value: "phoneno", dir: 1 },
    // { name: "Gender", value: "gender", dir: 1 },
  ]);
  const [emailnotifications, setEmailnotifications] = useState(false);
  const [textnotifications, setTextnotifications] = useState(false);
  const [maillisting, setMaillisting] = useState(false);
  const [notifications, setNotifications] = useState(false);

  //Onload event set here.
  useEffect(() => {
    var isFirstLoader = false;
    if (isFirstLoader === false) {
      navigate(`/trainer/${pageNum}`);
      isFirstLoader = true;
    }
    GetList(pages);
    getTypeOfWorkout();
  }, []);

  async function GetList(val) {
    setSelectedPage(val);
    // setIsLoader(true);
    setDataLoader(true);
    var obj = {
      availablestatus: 0,
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };

    axios
      .post(`${apiUrl}/admin/trainerlist`, obj)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            setNoOfRecords(response?.data?.result?.noOfRecords || 0);
            setList(response?.data?.result?.trainerlist);
            // setIsLoader(false);
            // console.log("list trainer >", response?.data?.result?.trainerlist);
            setDataLoader(false);
          }, 1000);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        // console.log("trainer list", error);
        // setIsLoader(false);
        setDataLoader(true);
        window.alert(error);
      });
  }

  const handleEmailNotification = () => {
    setEmailnotifications(!emailnotifications);
    setUserDetails({ ...userdetails, emailnotifications: !emailnotifications });
  };

  const handleTextNotification = () => {
    setTextnotifications(!textnotifications);
    setUserDetails({ ...userdetails, textnotifications: !textnotifications });
  };

  const handleMaillisting = () => {
    setMaillisting(!maillisting);
    setUserDetails({ ...userdetails, maillinglist: !maillisting });
  };

  const handleNotification = () => {
    setNotifications(!notifications);
    setUserDetails({ ...userdetails, notification: !notifications });
  };

  const handleChange = (e) => {
    setGender(e);
    setUserDetails({ ...userdetails, gender: e });
  };

  const handleTypeChange = (e) => {
    setType(e);
    setUserDetails({ ...userdetails, type: e });
  };

  const handleInputs = (e) => {
    setUserDetails({ ...userdetails, [e.target.name]: e.target.value });
  };

  const handleOnChange = () => {
    setIsActive(!isActive);
    setUserDetails({ ...userdetails, isactive: !isActive });
  };

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    // setIsLoader(false)
    setDataLoader(false);
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetList(selectedPage);
    setDataLoader(false);
    // setIsLoader(false)
  };

  const onFileChange = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 2096000) {
      setImagePreview(defaultImg);
      alert("File size more than 2 MB. File size must under 2MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      setGetImage(event.target.files[0]);

      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        setIsEdit(true);
        setImagePreview(result);
      };

      fileReader.readAsDataURL(file);
    }
  };

  // console.log("listStyle",listStyle);
  async function getTypeOfWorkout() {
    await axios
      .get(`${apiUrl}/trainer/trainer/getworkoutcategory`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setWorkOutList(response.data.result);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setDataLoader(false);
        // document.querySelector(".loading").classList.add("d-none");
      });
  }

  const tagChange = (e) => {
    setUserDetails({ ...userdetails, trainingstyle: e.target.value });
    handleSuggestion();
  };

  const handleSuggestion = () => {
    const suggestFilterInput = workoutList.filter((suggest) =>
      suggest.name
        .toLowerCase()
        .includes(userdetails.trainingstyle?.toLowerCase())
    );

    const suggestFilterTags = suggestFilterInput.filter(
      (suggest) => !tags.includes(suggest.name)
    );
    setFilterWorkout(suggestFilterTags);
  };

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, j) => i !== j);
    settags(newTags);
    setFilterWorkout([]);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
    }
    handleSuggestion();
  };

  const AddTags = (text) => {
    settags([...tags, text]);
    userdetails.trainingstyle = "";
    trainingstyle.current = "";
    setUserDetails(userdetails);
    setFilterWorkout([]);
  };

  const plusminus = (e) => {
    var count;
    if (e === "-") {
      count = expVal;
      count = parseInt(count) - 1;
      count = count < 1 ? 1 : count;
    } else {
      count = expVal;
      if (count > 49) {
        count = 50;
      } else {
        count++;
      }
    }
    setExp(count);
    return false;
  };

  const handleExp = (e) => {
    const newValue = e.target.value.replace(/\D/g, "");
    if (newValue?.length <= 2 && newValue < 51) {
      setExp(newValue);
    }
  };

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const handleQualifications = (e) => {
    e.preventDefault();
    // debugger
    var errormsg = {};
    var isValid = true;
    if (qualification === "") {
      errormsg.note = "Please enter note.";
      isValid = false;
    }
    if (imagesQuaPathList?.length === 0) {
      errormsg.image = "Please upload image here..";
      isValid = false;
    }
    if (
      qualificationslist?.length > 0 &&
      qualificationslist.filter((x) => x === qualification)?.length > 0
    ) {
      errormsg.already = "This qualification already exist!";
      isValid = false;
    }
    if (
      imagesQuaPathList.filter((x) => x.qualification === qualification)?.length === 0
    ) {
      errormsg.image = "Please upload image here..";
      isValid = false;
    }
    if (
      imagesQuaPathList.filter((x) => x.qualification === qualification)?.length > 0
    ) {
      var objFile = imagesQuaPathList.filter(
        (x) => x.qualification === qualification
      );
      if (objFile[0].uri === undefined || objFile[0].uri === null) {
        errormsg.image = "Please upload image here..";
        isValid = false;
      }
    }

    setErrors(errormsg);
    if (isValid) {
      qualificationslist.push(qualification);
      setQualifications(qualificationslist);
      setQualification("");

      const updatedList =  qualificationslist.map((listItems, index) => {
        return (
          <div
            id={`qualification${index}`}
            className="control-group input-group"
            style={{ marginTop: "10px" }}
          >
            <div className="d-flex col-md-6 pl-0">
              <div name="qualifications" className="removeinput">
                {listItems}
              </div>
              <div className="input-group-btn position-relative">
                <button
                  onClick={() => {
                    removeQualifications(index);
                  }}
                  className="remove position-absolute"
                  type="button"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6 position-relative">
              <img
                src="/img/file.png"
                className="Fileicon"
                alt="File"
                title="View"
              />
            </div>
          </div>
        );
      });
      setHtmlQualifications(updatedList);
    }
  };

  const removeQualifications = (index) => {
    qualificationslist.splice(index, 1);
    var qualificationslst = qualificationslist;
    setQualifications(qualificationslst);

    imagesQuaPathList.splice(index, 1);
    let imageQuapathlst = imagesQuaPathList;
    setImagesQuaPathList(imageQuapathlst);

    const updatedList = qualificationslist.map((listItems, index) => {
      return (
        <div
          key={"qualification" + index}
          className="control-group input-group"
          style={{ marginTop: "10px" }}
        >
          <div className="d-flex">
            <div name="qualifications" className="removeinput">
              {listItems}
            </div>
            <div className="input-group-btn position-relative">
              <button
                onClick={() => {
                  removeQualifications(index);
                }}
                className="remove position-absolute"
                type="button"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      );
    });
    setHtmlQualifications(updatedList);
  };

  const OnQualificationFileChange = (event, value) => {
    if (event.target.files[0] !== undefined) {
      const file_size = event.target.files[0]?.size;
      if (file_size > 1048000) {
        setImagesQuaPathList([]);
        alert("File size more than 1 MB. File size must under 1MB !");
        event.preventDefault();
      } else {
        const fileReader = new window.FileReader();
        const file = event.target.files[0];

        fileReader.onload = (fileLoad) => {
          var maxId =
            imagesQuaPathList?.length > 0 ? imagesQuaPathList?.length + 1 : 1;
            imagesQuaPathList.push({
            uri: file,
            path: "/public/trainerqualifications/" + file.name,
            name: file.name,
            type: file.type,
            id: maxId,
            qualification: value,
          });
          setImagesQuaPathList(imagesQuaPathList);
        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  const handleCertification = (e) => {
    setCertification(e.target.value);
  };

  const handleCertifications = (e) => {
    e.preventDefault();
    var errormsg = {};
    var isValid = true;

    if (certification === "") {
      errormsg.certification = "Please enter certification.";
      isValid = false;
    }
    if (cerimagesPathList?.length === 0) {
      errormsg.certificationImg = "Please upload image here..";
      isValid = false;
    }
    if (
      certificationslist?.length > 0 &&
      certificationslist.filter((x) => x === certification)?.length > 0
    ) {
      errormsg.certificationAlready = "This certification already exist!";
      isValid = false;
    }
    if (
      cerimagesPathList.filter((x) => x.certification === certification)
        ?.length === 0
    ) {
      errormsg.certificationImg = "Please upload image here..";
      isValid = false;
    }
    if (
      cerimagesPathList.filter((x) => x.certification === certification)
        ?.length > 0
    ) {
      var objFile = cerimagesPathList.filter(
        (x) => x.certification === certification
      );
      if (objFile[0].uri === undefined || objFile[0].uri === null) {
        errormsg.certificationImg = "Please upload image here..";
        isValid = false;
      }
    }
    setErrors(errormsg);
    if (isValid) {
      certificationslist.push(certification);
      setCertifications(certificationslist);
      setCertification("");
    }

    const updatedCList = certificationslist.map((ClistItems, index) => {
      return (
        <div
          key={"certification" + index}
          className="control-group input-group"
          style={{ marginTop: "10px" }}
        >
          <div className="d-flex">
            <div name="certifications" className="removeinput">
              {ClistItems}
            </div>
            <div className="input-group-btn position-relative">
              <button
                onClick={() => {
                  removeCertifications(index);
                }}
                className="remove position-absolute"
                type="button"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      );
    });
    setHtmlCertifications(updatedCList);
  };

  const removeCertifications = (index) => {
    certificationslist.splice(index, 1);
    var certificationslst = certificationslist;
    setCertifications(certificationslst);

    cerimagesPathList.splice(index,1)
    var certiPathList=cerimagesPathList;
    setCerImagesPathList(certiPathList)

    const updatedCList =  certificationslist.map((ClistItems, index) => {
      return (
        <div
          key={"certification" + index}
          className="control-group input-group"
          style={{ marginTop: "10px" }}
        >
          <div className="d-flex">
            <div name="certifications" className="removeinput">
              {ClistItems}
            </div>
            <div className="input-group-btn position-relative">
              <button
                onClick={() => {
                  removeCertifications(index);
                }}
                className="remove position-absolute"
                type="button"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      );
    });
    setHtmlCertifications(updatedCList);
  };

  const onCertificationFileChange = (event, value) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setCerImagesPathList([]);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];

      fileReader.onload = (fileLoad) => {
        //const { result } = fileLoad.target;
        var maxId =
          cerimagesPathList?.length > 0 ? cerimagesPathList?.length + 1 : 1;
        cerimagesPathList.push({
          uri: file,
          name: file.name,
          type: file.type,
          id: maxId,
          certification: value,
        });
        setCerImagesPathList(cerimagesPathList);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const onChangeHandle = async (e, obj) => {
    setList((prev) =>
      prev.id === obj._id ? { ...prev, isActive: !prev.isActive } : prev
    );
    const obje = {
      _id: obj._id,
      isactive: e.currentTarget.checked ? true : false,
    };
    // setIsLoader(true)
    setButtonLoader(true);
    await axios
      .post(`${apiUrl}/trainer/trainer/tarinerStatus`, obje, {})
      .then(function (response) {
        // setIsLoader(false)
        if (response.data.status === 1) {
          setButtonLoader(false);
          // const newState = List.map((x) => {
          //   if (x._id === obj._id) {
          //     return { ...x, statusid: response.data.result.statusid };
          //   }
          //   return x;
          // });
          // setList(newState);
          setDataLoader(false);
          GetList(pageNum);
          setDataLoader(false);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          // GetList(pageNum);
        } else {
          // setIsLoader(false)
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // setIsLoader(false);
        setDataLoader(false);
        window.alert(error);
      });
  };

  const validatePhoneNumber = (phoneNumber, countryCode) => {
    const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  
    if (countryCode === 'no') {
      return formattedPhoneNumber?.length === 8;
    } else {
      return formattedPhoneNumber?.length === 10;
    }
  };

  const saveClick = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (userdetails.firstname === "") {
      errormsg.firstname = "Please enter First Name.";
      isValid = false;
    } else if (
      userdetails.firstname?.length >= 3 === false ||
      userdetails.firstname?.length <= 50 === false
    ) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    } 

    if (userdetails.lastname === "") {
      errormsg.lastname = "Please enter Last Name.";
      isValid = false;
    } else if (
      userdetails.lastname?.length >= 3 === false ||
      userdetails.lastname?.length <= 50 === false
    ) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (userdetails.email === "") {
      errormsg.email = "Please enter Email.";
      isValid = false;
    } else if (reg_email.test(userdetails.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    } else if (userdetails.email?.length <= 50 === false) {
      errormsg.email = "Please enter maximum 256";
      isValid = false;
    }

    if (userdetails.phoneno === "") {
      errormsg.phoneno = "Please enter Phone number.";
      isValid = false;
    } else if (!validatePhoneNumber(userdetails.phoneno, userdetails.country)) {
      errormsg.phoneno = "Invalid phone number for the selected country.";
      isValid = false;
    }

    if (userdetails.gender === "") {
      errormsg.gender = "Please choose gender!";
      isValid = false;
    }

    if (userdetails.type === "") {
      errormsg.type = "Please choose type.";
      isValid = false;
    }
    if (userdetails.aboutus === "") {
      errormsg.aboutus = "Please enter about us description.";
      isValid = false;
    } 

    if (tags?.length === 0) {
      errormsg.trainingstyle = "Please enter training style.";
      isValid = false;
    }

    if (userdetails.quote === "") {
      errormsg.quote = "Please enter quote description.";
      isValid = false;
    } 

    setErrors(errormsg);
    if (isValid) {
      let qualificationsObj = {
        name: qualificationslist.join(","),
        path: imagesQuaPathList,
      };

      let certificationsObj = {
        name: certificationslist.join(","),
        path: cerimagesPathList,
      };

      // console.log("qualificationsObj", qualificationsObj);
      // console.log("certificationsObj", certificationsObj);

      let obj = {
        id: userdetails._id,
        firstname: userdetails.firstname,
        lastname: userdetails.lastname,
        email: userdetails.email,
        phoneno: userdetails.phoneno,
        countryDialCode: userdetails.countryDialCode,
        gender: userdetails.gender,
        isactive: userdetails.isactive ? true : false,
        type: userdetails.type,
        aboutus: userdetails.aboutus,
        specialitys: userdetails.specialitys,
        quote: userdetails.quote,
        trainingstyle: tags.join(","),
        experience: parseInt(expVal),
        emailnotifications: userdetails.emailnotifications,
        textnotifications: userdetails.textnotifications,
        maillinglist: userdetails.maillinglist,
        notification: userdetails.notification,
      };

      console.log("obje >", obj);

      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      // PROFILE IMAGE
      let profile;
      if (typeof getImage == "string") {
        profile = getImage.split(apiUrl);
        profile = profile[1] || "";
        setGetImage(...getImage, profile);
      } else {
        profile = getImage;
      }
      formData.append("profile", profile);
      setIsButtonDisabled(true);
      setButtonLoader(true);
      setPopuploader(true);
      setIsEdit(false);

      // setIsLoader(true);
      // setDataLoader(true)
      await axios
        .post(`${apiUrl}/trainer/trainer/savetrainer`, formData)
        .then(function (response) {
          if (response.data.status === 1) {
            if (qualificationsObj) {
              updateTrainerPara(
                qualificationsObj,
                certificationsObj,
                response.data.result._id
              );
            }
            setTimeout(() => {
              setDataLoader(false)
              GetList(pageNum);
              setDataLoader(false)
              setImagePreview("");
              setGetImage("");
              setIsButtonDisabled(false);
              setButtonLoader(false);
              setPopuploader(false);
              setIsEdit(false);
              navigate(`/trainer/${pageNum}`);
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setShow(false);
            }, 1000);
          } else {
            setShow(true);
            setButtonLoader(false);
            setIsButtonDisabled(false);
            setDataLoader(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          // setIsLoader(false);
          setDataLoader(false);
          window.alert(error);
        });
    }
  };

  const updateTrainerPara = (qualificationsObj, certificationsObj, tid) => {
    var form_data = new FormData();
    for (var key in qualificationsObj?.path) {
      form_data.append(
        qualificationsObj?.path[key].name,
        qualificationsObj?.path[key].uri
      );
    }
    for (var key1 in certificationsObj?.path) {
      form_data.append(
        certificationsObj?.path[key1].name,
        certificationsObj?.path[key1].uri
      );
    }
    form_data.append("id", tid);
    form_data.append("qualifications", JSON.stringify(qualificationsObj));
    form_data.append("certifications", JSON.stringify(certificationsObj));
    // document.querySelector(".loading").classList.remove("d-none");
    // console.log("sdfsfds >", JSON.stringify(qualificationsObj));
    // console.log("ddfdgdf >", JSON.stringify(certificationsObj));
    axios
      .post(`${apiUrl}/trainer/account/updateTrainerPara`, form_data)
      .then((response) => {
        if (response.data.status === 1) {
          console.log("rew", response.data);
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {});
  };

  const [oldImage, setOldImage] = useState(defaultImg)

  const editClick = async (e) => {
    setIsEdit(false);
    if (e.qualifications?.name) {
      setQualifications(e?.qualifications?.name.split(","));
      setImagesQuaPathList(e?.qualifications?.path);
    }

    // console.log("setCertificateView",e?.certifications?.path);
    if (e.certifications?.name) {
      setCertifications(e?.certifications?.name.split(","));
      setCerImagesPathList(e?.certifications?.path);
    }

    setIsButtonDisabled(false);
    setErrors({});
    let contectNUmber = e.countryDialCode + e.phoneno;
    let profileImage =
      e.profile !== null &&  e.profile !== undefined && e.profile !== ""
        ? apiUrl + e.profile
        : defaultImg;
        const newTags = e.trainingstyle.split(",");
        
    setOldImage(profileImage)
    setEmailnotifications(e.emailnotifications);
    setTextnotifications(e.textnotifications);
    setMaillisting(e.maillinglist);
    setNotifications(e.notification);

    settags(newTags);
    setImagePreview(profileImage);
    setGetImage(profileImage);
    setMobileNumber(contectNUmber);
    handleShow();
    setIsActive(e.statusid);
    setGender(e.gender);
    setType(e.type);
    setUserDetails({
      ...userdetails,
      _id: e._id,
      firstname: e.firstname,
      lastname: e.lastname,
      email: e.email,
      phoneno: e.phoneno,
      countryDialCode: e.countryDialCode,
      gender: e.gender,
      aboutus: e.aboutus,
      trainingstyle: e.trainingstyle,
      quote: e.quote,
      type: e.type,
      experience: e.experience,
      qualifications: e.qualifications,
      specialitys: e.specialitys,
      introduction: e.introduction,
      certifications: e.certifications,
      emailnotifications: e.emailnotifications,
      maillinglist: e.maillinglist,
      textnotifications: e.textnotifications,
      notification: e.notification,
    });
    //SetEditList(e);
  };

  const viewDetail = (e) => {
    // console.log("e", e);
    handleShow1();
    setUserDetails({
      ...userdetails,
      _id: e._id,
      firstname: e.firstname,
      lastname: e.lastname,
      email: e.email,
      phoneno: e.phoneno,
      countryDialCode: e.countryDialCode,
      gender: e.gender,
      aboutus: e.aboutus,
      experience: e.experience,
      trainingstyle: e.trainingstyle,
      Speciality: e.Speciality,
      introduction: e.introduction,
      certifications: e.certifications,
      qualifications: e.qualifications,
      profile: e.profile,
      socialmedia: e.socialmedia,
      // isactive: e.statusid ? "Active" : "Deactive",
      isactive: e.statusid,
      type: e.type,
    });
  };

  const deleteClick = async (e) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // setIsLoader(true);
          setDataLoader(true);
          axios
            .post(`${apiUrl}/trainer/trainer/deletetrainer`, { _id: e }, {})
            .then(function (response) {
              if (response.data.status === 1) {
                setTimeout(async () => {
                  const newList = List.filter((x) => x._id !== e);
                  if (newList?.length === 0) {
                    setDataLoader(false);
                    if (pageNum === 1) {
                      setPageNum(pageNum);
                      await GetList(pageNum);
                      setList(newList);
                      navigate(`/trainer/${pageNum}`);
                    } else {
                      setPageNum(pageNum - 1);
                      await GetList(pageNum - 1);
                      setList(newList);
                      navigate(`/trainer/${pageNum - 1}`);
                    }
                  } else {
                    setList(newList);
                  }
                  swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true,
                  });
                  setDataLoader(false);
                }, 1000);
                // GetList(pageNum ?? 1);
              } else {
                setDataLoader(false);
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                  button: true,
                });
              }
            })
            .catch(function (error) {
              // setIsLoader(false);
              setDataLoader(false);
              window.alert(error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const OnFileDiacard = () => {
    setIsEdit(false);
    setImagePreview(oldImage);
    setGetImage(oldImage);
    
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      GetList(pageNum);
      setPageNum(pageNum)
    }
  };

  const searchClick = async (searchData,pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };

      const response=await axios
          .post(
            `${apiUrl}/trainer/trainer/searchtrainer`,
            { searchname: searchData ,obj},
            {}
          )
      if (response.data.status === 1) {
         setList(response?.data?.result?.paginatedResults)
         setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
         setSearching(true);
      }else{
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }
  
    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // if (!searchData) {
    //   // setIsLoader(false);
    //   setDataLoader(false);
    //   GetList(1);
    //   setDataLoader(true);
    //   // setIsLoader(false);
    // } else {
    //   // setIsLoader(true);
    //   await axios
    //     .post(
    //       `${apiUrl}/trainer/trainer/searchtrainer`,
    //       { searchname: searchData },
    //       {}
    //     )
    //     .then(function (response) {
    //       // setIsLoader(false);
    //       if (response.data.status === 1) {
    //         setList(response.data.result);
    //         setSearching(true);
    //       } else {
    //         swal({
    //           title: "Error!",
    //           text: response.data.message,
    //           icon: "error",
    //           button: true,
    //         });
    //       }
    //     })
    //     .catch(function (error) {
    //       // console.log("trainer", error);
    //       // setIsLoader(false);
    //       setDataLoader(false);
    //       window.alert(error);
    //     });
    // }
  };

  const curPage = (pageNum) => {
    navigate(`/trainer/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async() => {
        await searchClick(searchname,pageNum)
        setDataLoader(false);
        
      }, 500);
    }else{
      GetList(pageNum);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Trainer</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Trainer</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <>
                      <button className="search_btn mt-0" onClick={clearClick}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="search_btn mt-0"
                        onClick={() => searchClick("")}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive client-trainer">
                    {dataLoader ? (
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {List?.length > 0 ? (
                      <>
                        <table className="table table-bordered table-hover mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>Sr. No</th>
                              {columnArr?.map((col, i) => {
                                return (
                                  <th
                                    key={"i" + i}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      isSortedColumn(col.value, col.dir)
                                    }
                                  >
                                    <div className="d-flex justify-content-between">
                                      {col.name}
                                      <span className="align-self-center">
                                        <i className="ml-2 fa fa-sort sort-gray"></i>
                                        <i
                                          className={`${
                                            col?.dir === 1
                                              ? "sort-i fa fa-sort-asc"
                                              : "sort-i fa fa-sort-desc"
                                          }`}
                                        ></i>
                                      </span>
                                    </div>
                                  </th>
                                );
                              })}
                              {/* <th className="text-center w130">Star</th> */}
                              <th className="text-center w130">Status</th>
                              {/* <th className="text-center w130">Type</th> */}
                              <th className="text-center w240">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {List.map((listItems, index) => {
                              return (
                                <tr
                                  key={"key" + index}
                                  style={{ width: "30px" }}
                                >
                                  <td>
                                    {index + 1 + (pageNum - 1) * limitValue}
                                  </td>
                                  <td>
                                    <img
                                      className="rounded"
                                      height={50}
                                      width={50}
                                      title={listItems.firstname}
                                      style={{ width: "30px", height: "30px" }}
                                      src={
                                        apiUrl + listItems.profile || defaultImg
                                      }
                                      alt="profile"
                                      onError={(e) => {
                                        e.target.src = "/img/Small-no-img.png";
                                      }}
                                    />
                                  </td>
                                  <td className="text-nowrap">
                                    {listItems.firstname || "N/A"}{" "}
                                    {listItems.lastname || "N/A"}
                                  </td>
                                  {/* <td>{listItems.lastname || "N/A"}</td> */}
                                  <td>{listItems.email || "N/A"}</td>
                                  <td className="text-nowrap">
                                    {listItems.countryDialCode} {listItems.phoneno || "N/A"}
                                  </td>
                                  
                                  <td>
                                    <div className="onoffswitch">
                                      <input
                                        type="checkbox"
                                        name="onoffswitch"
                                        className="onoffswitch-checkbox"
                                        id={`myonoffswitch ${index}`}
                                        checked={
                                          listItems.statusid === 1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          onChangeHandle(e, listItems);
                                        }}
                                      />
                                      <label
                                        className="onoffswitch-label"
                                        htmlFor={`myonoffswitch ${index}`}
                                      >
                                        <span className="onoffswitch-inner active-deactive"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>
                                  </td>
                                  {/* <td>{listItems.type || "N/A"}</td> */}
                                  <td className="text-center">
                                    <div className="d-flex justify-content-center">
                                    <button
                                        title="View"
                                        className="btn btn-eye mr-2"
                                        onClick={() => viewDetail(listItems)}
                                        // to={"/trainerdetails?Id=" + listItems._id}
                                      >
                                        <i className="ace-icon fa fa-eye"></i>
                                      </button>
                                      <button
                                      title="Edit"
                                        className="btn btn-edit mr-2"
                                        onClick={() => editClick(listItems)}
                                      >
                                        <i className="fas fa-pen-square"></i>
                                      </button>
                                      <button
                                       title="Delete"
                                        className="btn btn-delete mr-2"
                                        onClick={() =>
                                          deleteClick(listItems._id)
                                        }
                                      >
                                        <i className="ace-icon fa fa-trash"></i>
                                      </button>
                                      
                                      <button
                                        className="btn btn-comment d-none"
                                        onClick={handleShow1}
                                      >
                                        <i className="ace-icon fa fa-comment"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                            <div className="text-center" colSpan="10">
                              <div className="mt-3 text-center">
                                <div className="no-record-box">
                                  <img src="/img/no-record.png" alt="no-img" />
                                  <h4 className="font-weight-bold">
                                    Trainer Not Found
                                  </h4>
                                </div>
                              </div>
                            </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* {searchname === "" ? (
                  <> */}
                    {List?.length > 0 && (
                      <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                        <Pagination
                          className="pagination-bar"
                          currentPage={pageNum}
                          totalCount={noOfRecords}
                          pageSize={limitValue}
                          onPageChange={(page) => curPage(page)}
                        />
                      </div>
                    )}
                  {/* </>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {popuploader ? (
          <div id="loadermodal" className="loader">
            <div className="popuploader loginloader"></div>
          </div>
        ) : (
          <></>
        )}
        <Modal.Header closeButton>
          <Modal.Title className="d-sm-flex align-items-center justify-content-between mb-0 w-100">
            <div className="d-flex align-items-center">
              <img src="/img/common-health.png" alt="logo" />
              <h4 className="mb-0 ml-3">Edit Trainer</h4>
            </div>
            <div className="onoffswitch m-0">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitchedit"
                onChange={handleOnChange}
                checked={isActive}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitchedit">
                <span className="onoffswitch-inner active-deactive"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="Details">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12 mb-3">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={onFileChange}
                      />
                      {/* <label htmlFor="imageUpload"></label> */}
                      {isEdit ? (
                        <>
                          <button
                            className="img-cancel"
                            onClick={OnFileDiacard}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </>
                      ) : (
                        <>
                          <label htmlFor="imageUpload"></label>
                        </>
                      )}
                    </div>
                    <div className="avatar-preview">
                        <div
                        id="imagePreview"
                        style={{
                          backgroundImage: `url(${imagepreview || defaultImg})`,
                        }}
                      ></div>
                      
                    </div>
                    <div className="prof_name"></div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-12">
                  <div className="col-lg-12 col-md-6 col-12 mb-3 pr-0">
                    <label>First name</label>
                    <span className="text-red font-bold">*</span>
                    <div className="input-group">
                      <input
                        value={userdetails.firstname}
                        onChange={(e) => handleInputs(e)}
                        name="firstname"
                        placeholder="First name"
                        className="input-box m-0"
                        type="text"
                      />
                    </div>
                    <div className="text-danger">{errors.firstname}</div>
                  </div>
                  <div className="col-lg-12 col-md-6 col-12 mb-3 pr-0">
                    <label>Last name</label>
                    <span className="text-red font-bold">*</span>
                    <div className="input-group">
                      <input
                        value={userdetails.lastname}
                        onChange={(e) => handleInputs(e)}
                        name="lastname"
                        placeholder="Last name"
                        className="input-box m-0"
                        type="text"
                      />
                    </div>
                    <div className="text-danger">{errors.lastname}</div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Email</label>
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      value={userdetails.email}
                      disabled
                      onChange={(e) => handleInputs(e)}
                      name="email"
                      placeholder="E-mail"
                      className="input-box m-0"
                      type="email"
                    />
                  </div>
                  <div className="text-danger">{errors.email}</div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Phone number</label>
                  <span className="text-red font-bold">*</span>
                  <PhoneInput
                    country={"in"}
                    // disabled
                    placeholder="Phone number"
                    enableSearch={true}
                    name="phoneno"
                    value={mobileNumber}
                    onChange={(value, country) => {
                      
                      const phone = value.replace(country.dialCode, "");
                      setUserDetails((prev) => ({ ...prev, phoneno: phone }));
                      setUserDetails((prev) => ({
                        ...prev,
                        country: country.countryCode,
                      }));
                      setUserDetails((prev) => ({
                        ...prev,
                        countryDialCode: `+${country.dialCode}`,
                      }));
                    }}
                    readonly={true}
                  />
                  <div className="text-danger">{errors.phoneno}</div>
                </div>
                <div className="col-md-6 col-sm-4 col-12 mb-3">
                  <label>Gender</label>
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <i className="fa fa-angle-down darrow-i"></i>
                    <select
                      className="input-box"
                      value={gender}
                      name="gender"
                      onChange={(event) => handleChange(event.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Non-Binary">Non-Binary</option>
                    </select>
                  </div>
                  <div className="text-danger">{errors.gender}</div>
                </div>
                <div className="col-md-6 col-sm-4 col-12 mb-3">
                  <label>Type</label>
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <i className="fa fa-angle-down darrow-i"></i>
                    <select
                      className="input-box"
                      value={type}
                      onChange={(event) => handleTypeChange(event.target.value)}
                    >
                      <option value="">Select type</option>
                      <option value="Standard">Standard</option>
                      <option value="ELite">ELite</option>
                    </select>
                  </div>
                  <div className="text-danger">{errors.type}</div>
                </div>

                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Trainer favorite quote</label>
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      value={userdetails.quote}
                      onChange={(e) => handleInputs(e)}
                      name="quote"
                      placeholder="Quote"
                      className="input-box m-0"
                      type="text"
                    />
                  </div>
                  <div className="text-danger">{errors.quote}</div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Trainer specialitys</label>
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      value={userdetails.specialitys}
                      onChange={(e) => handleInputs(e)}
                      name="specialitys"
                      placeholder="Quote"
                      className="input-box m-0"
                      type="text"
                    />
                  </div>
                  <div className="text-danger">{errors.quote}</div>
                </div>
                <div className="col-md-9 col-sm-4 col-12 mb-3">
                  <label>Training style</label>
                  <span className="text-red font-bold">*</span>
                  <div className="tags-content">
                    <div className="tags-input">
                      <input
                        type="text"
                        className="w-100 mb-4 input-box"
                        // value={trainingstyle.current}
                        ref={trainingstyle}
                        onClick={(e) => {
                          tagChange(e);
                        }}
                        onKeyDown={(e) => {
                          handleKeyDown(e);
                        }}
                        placeholder="Describe your training style"
                      />
                      {Boolean(filterWorkout?.length) && (
                        <div className="tags-suggestions">
                          <span
                            className="float-right"
                            onClick={(e) => {
                              setFilterWorkout([]);
                            }}
                          >
                            <button className="btn btn-sm btn-danger">X</button>
                          </span>
                          {filterWorkout.map((suggest) => {
                            return (
                              <div
                                className="suggestion-item"
                                onClick={() => AddTags(suggest.name)}
                              >
                                {suggest.name}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {tags.map((tag, i) => (
                      <div key={i} className="tag">
                        {tag}
                        <div
                          className="remove-tag"
                          onClick={() => handleDelete(i)}
                        >
                          ×
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-danger">{errors.trainingstyle}</div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 mb-3">
                  <label>Experience (in years)</label>
                  <span className="text-red font-bold">*</span>
                  <div className="number ml-3">
                    <div className="row">
                      <div className="pmclass">
                        <span className="minus" onClick={() => plusminus("-")}>
                          {" "}
                          -
                        </span>
                        <input
                          type="text"
                          value={expVal}
                          onChange={handleExp}
                          className="qutinput"
                        />
                        <span className="plus" onClick={() => plusminus("+")}>
                          {" "}
                          +{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-11 col-10">
                  <label>Qualification Details</label>
                  <div className="input-group control-group after-add-more position-relative">
                    <input
                      onChange={(e) => handleQualification(e)}
                      type="text"
                      name="qualification"
                      value={qualification}
                      className="input-box w-100"
                      placeholder="Qualification"
                      autoComplete="off"
                    />
                    <div className="input-group-btn">
                      <button
                        onClick={(e) => {
                          handleQualifications(e);
                        }}
                        className="add-more position-absolute"
                        type="button"
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-2 mt-42">
                  <div className="uploadimg">
                    <input
                      type="file"
                      id="uploadQualification"
                      onChange={(e) => {
                        OnQualificationFileChange(e, qualification);
                      }}
                      accept=".png, .jpg, .jpeg, .pdf, .doc"
                    />
                    <label htmlFor="uploadQualification">
                      <i className="fas fa-upload upl-icon"></i>
                    </label>
                  </div>
                </div>
              </div>
              <div className="text-danger">{errors.qualification}</div>
              <div className="text-danger">{errors.note}</div>
              <div className="text-danger">{errors.image}</div>
              <div className="text-danger">{errors.already}</div>

              {qualificationslist?.length > 0 &&
                qualificationslist.map((listItems, index) => {
                  return (
                    <>
                      <div
                        key={`qualification${index}`}
                        className={`col-md-12 control-group${index}`}
                      >
                        <div className="row">
                          <div className="col-md-11 p-0">
                            <div className="copy">
                              <div
                                className="input-group"
                                style={{ marginTop: "10px" }}
                                id={`qualification${index}`}
                              >
                                <div className="d-flex">
                                  <div
                                    name={"qualification" + index}
                                    className="removeinput"
                                  >
                                    {listItems}
                                  </div>
                                  <div className="input-group-btn position-relative">
                                    <button
                                      onClick={() => {
                                        removeQualifications(index);
                                      }}
                                      className="remove position-absolute"
                                      type="button"
                                    >
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 mt-2">
                            <div className="uploadimg">
                              {imagesQuaPathList?.length > 0 &&
                                imagesQuaPathList?.map((path)=>{
                                  return(<>
                                  <Link target="_blank" to={apiUrl + path?.path}>
                                      <label
                                        htmlFor="uploadQualification2"
                                        title="View"
                                      >
                                        <img
                                          src={fileRightIcon}
                                          className="Fileicon"
                                          style={{ position: "unset" }}
                                          alt="File"
                                        />
                                      </label>
                                    </Link>

                                  </>)
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              <div className="row">
                <div className="col-md-11 col-10 mt-3">
                  <label>Certifications Details</label>
                  <div className="input-group control-group after-add-more position-relative">
                    <input
                      onChange={(e2) => handleCertification(e2)}
                      type="text"
                      autoComplete={"off"}
                      name="certification"
                      value={certification}
                      className="input-box w-100 mb-3"
                      placeholder="Certifications"
                    />
                    <div className="input-group-btn">
                      <button
                        onClick={(e2) => {
                          handleCertifications(e2);
                        }}
                        className="add-more position-absolute"
                        type="button"
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-2 mt-60">
                  <div className="uploadimg">
                    <input
                      type="file"
                      id="uploadCertifications"
                      onChange={(e) => {
                        onCertificationFileChange(e, certification);
                      }}
                      accept=".png, .jpg, .jpeg, .pdf, .doc"
                    />
                    <label htmlFor="uploadCertifications">
                      {/* <img src="/img/upload.png" alt='Upload' /> */}
                      <i className="fas fa-upload upl-icon"></i>
                    </label>
                  </div>
                </div>
                <div className="text-danger">{errors.certificationslist}</div>
                <div className="text-danger">{errors.certificationImg}</div>
                <div className="text-danger">{errors.certificationAlready}</div>
              </div>
              {certificationslist?.length > 0 &&
                certificationslist.map((ClistItems, index) => {
                  return (
                    <div
                      key={`certification${index}`}
                      className={`col-md-12 control-group${index}`}
                    >
                      <div className="row">
                        <div className="col-md-11 col-11 p-0">
                          <div className="copy">
                            <div
                              key={"certification" + index}
                              className="input-group"
                            >
                              <div className="d-flex">
                                <div
                                  name="certifications"
                                  className="removeinput"
                                >
                                  {ClistItems}
                                </div>
                                <div className="input-group-btn position-relative">
                                  <button
                                    onClick={() => {
                                      removeCertifications(index);
                                    }}
                                    className="remove position-absolute"
                                    type="button"
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                </div>
                                {/* {certificateView[index]?.path ?
                                  <Link target="_blank"
                                    to={apiUrl + certificateView[index]?.path}
                                  >View {ClistItems}</Link>
                                  : ""} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1 col-1  text-center">
                          <div className="uploadimg">
                            {cerimagesPathList?.length > 0 && 
                              cerimagesPathList?.map((path)=>{
                                return(<>
                                <Link target="_blank" to={apiUrl + path?.path}>
                                    <label
                                      htmlFor="uploadQualification1"
                                      title="View"
                                    >
                                      <img
                                        src={fileRightIcon}
                                        className="Fileicon"
                                        style={{ position: "unset" }}
                                        alt="File"
                                      />
                                    </label>
                                  </Link>
                                </>)
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="col-lg-12 col-md-12 col-12 my-3">
                <div className="row">
                  <label>About</label>
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <textarea
                      onChange={(e) => handleInputs(e)}
                      value={userdetails.aboutus}
                      name="aboutus"
                      className="w-100 Sessionrej mb-3 input-box txtarea"
                      placeholder="About"
                    ></textarea>
                    {/* <input
                      value={userdetails.aboutus}
                      onChange={(e) => handleInputs(e)}
                      name="aboutus"
                      placeholder="Aboutus"
                      className="input-box m-0"
                      type="text"
                    /> */}
                  </div>
                  <div className="text-danger">{errors.aboutus}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <h4 className="noti-title">Notifications</h4>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <label>Email</label>
                  <div className="onoffswitch m-0">
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      value={emailnotifications}
                      id="emailToggle"
                      onChange={handleEmailNotification}
                      checked={emailnotifications}
                    />
                    <label className="onoffswitch-label" htmlFor="emailToggle">
                      <span className="onoffswitch-inner active-deactive"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <label>Text</label>
                  <div className="onoffswitch m-0">
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      value={textnotifications}
                      id="textToggle"
                      onChange={handleTextNotification}
                      checked={textnotifications}
                    />
                    <label className="onoffswitch-label" htmlFor="textToggle">
                      <span className="onoffswitch-inner active-deactive"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <label>Mailling List</label>
                  <div className="onoffswitch m-0">
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      value={maillisting}
                      id="mailToggle"
                      onChange={handleMaillisting}
                      checked={maillisting}
                    />
                    <label className="onoffswitch-label" htmlFor="mailToggle">
                      <span className="onoffswitch-inner active-deactive"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <label>Web</label>
                  <div className="onoffswitch m-0">
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      value={notifications}
                      id="notifications"
                      onChange={handleNotification}
                      checked={notifications}
                    />
                    <label
                      className="onoffswitch-label"
                      htmlFor="notifications"
                    >
                      <span className="onoffswitch-inner active-deactive"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn cancel_btn"
            disabled={isButtonDisabled}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="secondary btn save_btn"
            disabled={isButtonDisabled}
            onClick={saveClick}
          >
            {/* {buttonLoader ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            ) : (
              ""
            )} */}
            {userdetails._id ? "Update" : "Save"}{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Trainer chat history modal popup */}
      <Modal
        show={show1}
        onHide={handleClose1}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="loadermodal" className="loader d-none">
          <div className="popuploader loginloader"></div>
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="d-sm-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Trainer Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="Details">
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-12">
                  {/* {userdetails} */}
                  <div className="detail-img">
                    {userdetails?.profile ? (
                      <img
                        src={apiUrl + userdetails?.profile}
                        alt=""
                        onError={(e) => {
                          e.target.src = "/img/Small-no-img.png";
                        }}
                      />
                    ) : (
                      <img src={"/img/Small-no-img.png"} alt="" />
                    )}
                    {/* <div className="text-center">
                      <div className="">
                          <label className="modal-lbl">{userdetails?.firstname} {userdetails?.lastname}</label>
                        </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="modal-box">
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl"> Name </label>
                        <span>
                          {userdetails?.firstname
                            ? userdetails.firstname
                            : "N/A"}{" "}
                          {userdetails.lastname}
                        </span>
                      </div>
                      {/* <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Last Name </label>
                        <span>{userdetails.lastname}</span>
                      </div> */}
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Email </label>
                        <span>
                          {userdetails.email ? userdetails.email : "N/A"}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Mobile No </label>
                        <span>
                          {userdetails.phoneno
                            ? userdetails.countryDialCode
                            : "N/A"}{" "}
                          {userdetails.phoneno}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Gender </label>
                        <span>
                          {userdetails.gender ? userdetails.gender : "N/A"}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Star </label>
                        <Rating
                          initialValue="3.5"
                          size="17"
                          readonly="true"
                          allowHover="false"
                          allowHalfIcon="true"
                        />
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Type </label>
                        <span>
                          {userdetails.type ? userdetails.type : "N/A"}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Status </label>
                        {/* <span>{(userdetails.isactive) ? userdetails.isactive : "N/A"}</span> */}
                        {userdetails?.isactive ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">Deactive</span>
                        )}
                      </div>
                      {userdetails?.socialmedia?.length > 0 ? (
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">Social Media </label>
                          <span>
                            {userdetails?.socialmedia?.map((media, i) => (
                              <>
                                <li
                                  key={i}
                                  className="list-inline-item position-relative"
                                >
                                  <Link
                                    className="text-decoration-none"
                                    to={media.url}
                                    target="_blank"
                                  >
                                    <span className="px-1">
                                      <i
                                        className={media.icon}
                                        aria-hidden="true"
                                        title={media.platform}
                                      ></i>
                                    </span>
                                  </Link>
                                </li>
                              </>
                            ))}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="modal-box">
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Training Style </label>
                        <span>
                          {userdetails.trainingstyle
                            ? userdetails.trainingstyle
                            : "N/A"}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Fitness Speciality </label>
                        <span>
                          {userdetails?.specialitys
                            ? userdetails?.specialitys
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="modal-box">
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Comment </label>
                        <span className="content-disc">
                          {userdetails.aboutus ? userdetails.aboutus : "N/A"}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Experience </label>
                        <span className="content-disc">
                          {userdetails.experience
                            ? userdetails.experience
                            : "N/A"}
                        </span>
                      </div>
                      <div className="col-lg-6 col-12 mb-3">
                        <label className="modal-lbl">About </label>
                        <span className="content-disc">
                          {userdetails?.introduction
                            ? userdetails?.introduction
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              
                <div className="col-12">
                  <div className="modal-box">
                    <div className="row">
                      <div className="col-lg-6 col-12 mb-3">
                        <label className="modal-lbl">Qualifications </label>
                        <span>
                          {userdetails?.qualifications?.name
                            ? userdetails?.qualifications?.name
                            : "N/A"}
                        </span>
                        <div className="row">
                          {userdetails?.qualifications?.path?.length > 0 &&
                            userdetails?.qualifications?.path.map(
                              (ele, qindex) => {
                                return (
                                  <Link
                                    target="_blank"
                                    to={apiUrl + ele?.path}
                                    key={"qindex" + qindex}
                                    className="col-lg-4 col-md-3 col-sm-6 col-12 proimg"
                                  >
                                    <img
                                      src={apiUrl + ele.path}
                                      title={ele.name}
                                      alt=""
                                      onError={(e) => {
                                        e.target.src = "/img/Small-no-img.png";
                                      }}
                                    />
                                  </Link>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="modal-box">
                    <div className="row">
                      <div className="col-md-12 col-12 mb-3">
                        <label className="modal-lbl">Certifications </label>
                        <span>
                          {userdetails?.certifications?.name
                            ? userdetails?.certifications?.name
                            : "N/A"}
                        </span>
                        <div className="row">
                          {userdetails?.certifications?.path?.length > 0 &&
                            userdetails?.certifications?.path.map(
                              (ele, cindex) => {
                                return (
                                  <Link
                                    target="_blank"
                                    to={apiUrl + ele?.path}
                                    key={"cindex" + cindex}
                                    className="col-lg-2 col-md-3 col-sm-6 col-12 proimg"
                                  >
                                    <img
                                      src={apiUrl + ele.path}
                                      title={ele.name}
                                      onError={(e) => {
                                        e.target.src = "/img/Small-no-img.png";
                                      }}
                                      alt=""
                                    />
                                  </Link>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Trainer chat history modal popup End*/}
    </>
  );
}

export default Trainer;
