import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiUrl } from "../environment/environment";
import Pagination from "./Pagination/Pagination";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function UserManagement() {
  const defaultImg = "/img/Small-no-img.png";
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [staff, setStaff] = useState({
    id: "",
    profile: defaultImg,
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phoneno: "",
    countryDialCode: "",
    // age: null,
    gender: "",
    height: "",
    weight: "",
    status: true,
  });
  const [show, setShow] = useState(false);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  // const [isLoader, setIsLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [dataLoader, setDataLoader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [getImage, setGetImage] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [imagepreview, setImagePreview] = useState(defaultImg);

  const { pages } = useParams();

  const handleClose = () => setShow(false);
  const [errors, setErrors] = useState({});
  const [pageNum, setPageNum] = useState(+pages);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const limitValue = 10;

  const [oldImage, setOldImage] = useState(defaultImg)
  const [popuploader, setPopuploader] = useState(false);


  const [columnArr, setColumnArr] = useState([
    { name: "Profile", value: "profile" },
    { name: "Name", value: `firstname`, dir: 1 },
    // { name: "Last Name", value: "lastname", dir: 1 },
    { name: "Email", value: "email", dir: 1 },
    { name: "Phone No", value: "phoneno", dir: 1 },
    // { name: "Age", value: "age", dir: 1 },
    { name: "Gender", value: "gender", dir: 1 },
  ]);

  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => setShow3(false);
  const [show3, setShow3] = useState(false);
  const [showDeatil, setShowDeatil] = useState([]);

  useEffect(() => {
    var isFirstLoader = false;
    if (isFirstLoader === false) {
      navigate(`/usermanagement/${pageNum}`);
      isFirstLoader = true;
    }

    GetList(pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function GetList(val) {
    setSelectedPage(val);
    // setIsLoader(true);
    setDataLoader(true);
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };
    // return false
    await axios
      .post(`${apiUrl}/admin/getstaff`, obj)
      .then(function (response) {
        // setIsLoader(false);
        // return false
        if (response.data.status === 1) {
          setTimeout(() => {
            response.data.result?.stafflist.forEach((element) => {
              element.status = element.statusid === 1 ? true : false;
            });
            setNoOfRecords(response.data?.result?.noOfRecords || 0);
            setList(response?.data?.result?.stafflist);
            setDataLoader(false);
          }, 1000);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // setIsLoader(false);
        setDataLoader(false);
      });
  }

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    // setIsLoader(false)

    setDataLoader(false);
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetList(selectedPage);
    setDataLoader(false);
    // setIsLoader(false)
  };

  const isEditStaff = (obj) => {
    setIsButtonDisabled(false);
    setErrors({});
    let contectNUmber = obj.countryDialCode + obj.phoneno;
    setMobileNumber(contectNUmber);
    let profileImage = obj.profile !== null && obj.profile !== undefined && obj.profile !== ""
        ? apiUrl + obj.profile
        : defaultImg;
    setOldImage(profileImage)
    setImagePreview(profileImage || defaultImg);
    setGetImage(profileImage);

    setStaff({
      ...staff,
      id: obj._id,
      firstname: obj.firstname,
      lastname: obj.lastname,
      email: obj.email,
      password: obj.password,
      phoneno: obj.phoneno,
      countryDialCode: obj.countryDialCode,
      gender: obj.gender,
      status: obj.status,
      profile: obj.profile,
    });
    setShow(true);
  };


  const validatePhoneNumber = (phoneNumber, countryCode) => {
    const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (countryCode === 'no') {
      return formattedPhoneNumber?.length === 8;
    } else {
      return formattedPhoneNumber?.length === 10;
    }
  };

  const saveStaff = (e) => {
    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    // let reg_email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (staff.firstname === "") {
      errormsg.firstname = "Please enter first name!";
      isValid = false;
    } else if (
      (staff.firstname?.length >= 3) === false ||
      (staff.firstname?.length <= 50) === false
    ) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (staff.lastname === "") {
      errormsg.lastname = "Please enter last name!";
      isValid = false;
    } else if (
      (staff.lastname?.length >= 3) === false ||
      (staff.lastname?.length <= 50) === false
    ) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (staff.email === "") {
      errormsg.email = "Please enter email id!";
      isValid = false;
    } else if (reg_email.test(staff.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    }

    if (staff.password === "") {
      errormsg.password = "Please enter password!";
      isValid = false;
    }

    if (staff.phoneno === "") {
      errormsg.phoneno = "Please enter phone no!";
      isValid = false;
    } else if (!validatePhoneNumber(staff.phoneno, staff.country)) {
      errormsg.phoneno = "Invalid phone number for the selected country.";
      isValid = false;
    }
    // if (staff.age === null) {
    //   errormsg.age = "Please enter age!";
    //   isValid = false;
    // }
    if (staff.gender === "") {
      errormsg.gender = "Please choose gender!";
      isValid = false;
    }
    //if(staff.id == null) { staff.id = ""; }
    setErrors(errormsg);
    if (isValid) {
      let obj = {
        id: "",
        firstname: staff.firstname,
        lastname: staff.lastname,
        email: staff.email,
        password: staff.password,
        phoneno: staff.phoneno,
        countryDialCode: staff.countryDialCode,
        gender: staff.gender,
        status: staff.status,
      };
      // setImagePreview(staff.profile || defaultImg);
      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      // PROFILE IMAGE
      let profile;
      if (typeof getImage == "string") {
        profile = getImage.split(apiUrl);
        profile = profile[1] || "";
        setGetImage(...getImage, profile);
      } else {
        profile = getImage;
      }
      formData.append("profile", profile);

      setIsButtonDisabled(true);
      setButtonLoader(true);
      setPopuploader(true);
      // setDataLoader(true)
      axios
        .post(`${apiUrl}/admin/addStaff`, formData)
        .then(function (response) {
          if (response.data.status === 1) {
            setTimeout(() => {
              setButtonLoader(false);
              setImagePreview(defaultImg);
              setGetImage("");
              // setList(prev => [...prev, response.data.result])
              GetList(pageNum);
              // setDataLoader(false)
              setIsEdit(false);
              setPopuploader(false);
              setIsButtonDisabled(false);
              setShow(false);
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
            }, 1000);
          } else {
            setShow(true);
            setButtonLoader(false);
            setIsButtonDisabled(false);
            setPopuploader(false);
            // setDataLoader(false)
            // setIsLoader(false)

            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          // setIsLoader(false);
          setDataLoader(false);
          window.alert(error);
        });
    }
  };

  const deleteStaff = (val) => {
    let obj = {
      id: val._id,
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // setIsLoader(true);
          setDataLoader(true);
          await axios
            .post(`${apiUrl}/admin/deletestaff`, obj)
            .then((response) => {
              // setIsLoader(false);
              if (response.data.status === 1) {
                setTimeout(async () => {
                  const newList = list.filter((x) => x._id !== val._id);
                  if (newList?.length === 0) {
                    if (pageNum === 1) {
                      setPageNum(pageNum);
                      await GetList(pageNum);
                      setList(newList);
                      navigate(`/usermanagement/${pageNum}`);
                    } else {
                      setPageNum(pageNum - 1);
                      await GetList(pageNum - 1);
                      setList(newList);
                      navigate(`/usermanagement/${pageNum - 1}`);
                    }
                  } else {
                    setList(newList);
                  }
                  swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true,
                  });
                  setDataLoader(false);
                }, 1000);
              } else {
                setDataLoader(false);
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                  button: true,
                });
                //window.alert(response.data.message);
              }
            })
            .catch(function (error) {
              setDataLoader(false);
              // setIsLoader(false);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const isShowModel = () => {
    setImagePreview(getImage);
    setIsButtonDisabled(false);
    setIsEdit(false);
    setStaff({
      ...staff,
      id: null,
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      phoneno: "",
      countryDialCode: "",
      gender: "",
      status: true,
      profile: "",
    });
    setErrors({});
    setShow(true);
  };


  const onChangeHandle = (e, obj) => {
    // setButtonLoader(true)
    setStaff((prev) =>
      prev.id === obj._id ? { ...prev, status: !prev.status } : prev
    );

    let obje = {
      id: obj._id,
      status: e.currentTarget.checked ? true : false,
    };

    setButtonLoader(true);
    axios
      .post(`${apiUrl}/admin/adminstatuschange`, obje)
      .then(function (response) {
        if (response.data.status === 1) {
          setDataLoader(false);
          GetList(pageNum);
          setDataLoader(false);

          setButtonLoader(false);
          // const newState = list.map((x) => {
          //   if (x._id === obj._id) {
          //     return { ...x, statusid: response.data.result.statusid }
          //   }
          //   return x
          // })
          // setList(newState)
          navigate(`/usermanagement/${pageNum}`);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          // setIsLoader(false)
          setButtonLoader(false);

          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // setIsLoader(false);
        setDataLoader(false);
      });
  };

  const update = (e, updateId) => {
    setIsEdit(false);
    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (staff.firstname === "") {
      errormsg.firstname = "Please enter first name!";
      isValid = false;
    } else if (
      (staff.firstname?.length >= 3) === false ||
      (staff.firstname?.length <= 50) === false
    ) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }
    if (staff.lastname === "") {
      errormsg.lastname = "Please enter last name!";
      isValid = false;
    } else if (
      (staff.lastname?.length >= 3) === false ||
      (staff.lastname?.length <= 50) === false
    ) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }
    if (staff.email === "") {
      errormsg.email = "Please enter email id!";
      isValid = false;
    } else if (reg_email.test(staff.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    } else if ((staff.email?.length <= 50) === false) {
      errormsg.email = "Please enter maximum 256";
      isValid = false;
    }
    if (staff.phoneno === "") {
      errormsg.phoneno = "Please enter phone nnumber!";
      isValid = false;
    } else if (
      (staff.phoneno?.length > 9) === false ||
      (staff.phoneno?.length <= 15) === false
    ) {
      errormsg.phoneno = "Phone No length must be 10";
      isValid = false;
    }

    if (staff.gender === "") {
      errormsg.gender = "Please choose gender!";
      isValid = false;
    }

    setErrors(errormsg);
    if (isValid) {
      let obj = {
        id: staff.id,
        firstname: staff.firstname,
        lastname: staff.lastname,
        email: staff.email,
        password: staff.password,
        phoneno: staff.phoneno,
        countryDialCode: staff.countryDialCode,
        gender: staff.gender,
        status: staff.status,
      };

      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      // PROFILE IMAGE
      let profile;
      if (typeof getImage == "string") {
        profile = getImage.split(apiUrl);
        profile = profile[1] || "";
        setGetImage(...getImage, profile);
      } else {
        profile = getImage;
      }

      formData.append("profile", profile);
      setIsButtonDisabled(true);
      setButtonLoader(true);
      setPopuploader(true);
      // setIsLoader(true);
      axios
        .post(`${apiUrl}/admin/updateadminusers`, formData)
        .then(function (response) {
          if (response.data.status === 1) {
            // setIsLoader(false);
            setTimeout(() => {
              // setDataLoader(false)
              setDataLoader(false);
              GetList(pageNum);
              setDataLoader(false);
              setImagePreview("");
              setGetImage("");
              setDataLoader(false);
              setButtonLoader(false);
              setPopuploader(false);
              setIsEdit(false);
              setIsButtonDisabled(false);
              // navigate(`/usermanagement/${pageNum}`);
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setShow(false);
            }, 1000);
          } else {
            setShow(true);
            // setIsLoader(false);
            setPopuploader(false);
            setButtonLoader(false);
            setIsButtonDisabled(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          // setIsLoader(false);
          setDataLoader(false);
        });
    }
  };

  const onFileChange = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 2096000) {
      setImagePreview(defaultImg);
      alert("File size more than 2 MB. File size must under 2MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      setGetImage(event.target.files[0]);

      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        setIsEdit(true);
        setImagePreview(result);
      };

      fileReader.readAsDataURL(file);
    }
  };

  const viewDetail = (e) => {
    handleShow3();
    setShowDeatil({
      ...showDeatil,
      countryDialCode: e.countryDialCode,
      email: e.email,
      firstname: e.firstname,
      gender: e.gender,
      lastname: e.lastname,
      phoneno: e.phoneno,
      profile: e.profile,
      socialmedia: e.socialmedia,
      status: e.status,
      // statusid: e.statusid ? "Active" : "Deactive",
      statusid: e.statusid,
      usertype: e.usertype,
    });
  };

  const OnFileDiacard = () => {
    setIsEdit(false);
    setImagePreview(oldImage && defaultImg);
    setGetImage(oldImage);
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      GetList(pageNum);
      setPageNum(pageNum)
    }
  };

  const searchClick = async (searchData,pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };

      const response =await axios.post(`${apiUrl}/admin/searchstaff`, { searchname: searchData, obj}, {})
      if (response.data.status === 1) {
        setList(response?.data?.result?.paginatedResults)
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }


    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
  };

  const curPage = (pageNum) => {
    navigate(`/usermanagement/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async () => {
        await searchClick(searchname, pageNum)
        setDataLoader(false);

      }, 500);
    } else {
      GetList(pageNum);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Staff / Sub-Admin</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Staff / Sub-Admin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-6 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <>
                      <button
                        className="search_btn mt-0"
                        onClick={() => clearClick("")}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="search_btn mt-0"
                        onClick={() => searchClick("")}
                      >
                        {" "}
                        <i className="fa fa-search"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="col-xl-2 col-md-3 col-sm-6 col-12 offset-md-3 offset-xl-6 text-right mb-2">
                <button
                  className="add-btn"
                  onClick={(e) => {
                    isShowModel(e);
                  }}
                >
                  <i className="fa fa-plus pr-2"></i>Add{" "}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive">
                    {dataLoader ? (
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {list?.length > 0 ? (
                      <>
                        <table className="table table-bordered table-hover mb-0">
                          <thead>
                            <tr className="position-relative">
                              <th>Sr. No</th>
                              {columnArr.map((col, i) => {
                                return (
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      isSortedColumn(col.value, col.dir)
                                    }
                                  >
                                    <div className="d-flex justify-content-between">
                                      {col.name}
                                      <span className="align-self-center">
                                        <i className="ml-2 fa fa-sort sort-gray"></i>
                                        <i
                                          className={`${col.dir === 1
                                              ? "sort-i fa fa-sort-asc"
                                              : "sort-i fa fa-sort-desc"
                                            }`}
                                        ></i>
                                      </span>
                                    </div>
                                  </th>
                                );
                              })}
                              <th className="text-center w130">Status</th>
                              <th className="text-center w130">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.map((ele, index) => {
                              return (
                                <tr key={"key" + index}>
                                  <td className="text-center">
                                    {index + 1 + (pageNum - 1) * limitValue}
                                  </td>
                                  <td>
                                    <img
                                      className="rounded"
                                      height={50}
                                      width={50}
                                      title={ele.firstname}
                                      src={apiUrl + ele.profile || defaultImg}
                                      alt="profile"
                                      onError={(e) => {
                                        e.target.src = "/img/Small-no-img.png";
                                      }}
                                    />
                                  </td>
                                  <td className="text-nowrap">
                                    {ele.firstname} {ele.lastname}
                                  </td>
                                  {/* <td>{ele.lastname}</td> */}
                                  <td>{ele.email}</td>
                                  <td className="text-nowrap">
                                    {ele.countryDialCode} {ele.phoneno}
                                  </td>
                                  {/* <td>{ele.age}</td> */}
                                  <td className="text-nowrap">{ele.gender}</td>
                                  <td>
                                    <div className="onoffswitch">
                                      <input
                                        type="checkbox"
                                        name="onoffswitch"
                                        disabled={
                                          ele.usertype === "admin" ? true : false
                                        }
                                        className="onoffswitch-checkbox"
                                        id={`myonoffswitch ${index}`}
                                        checked={
                                          ele.statusid === 1 ? true : false
                                        }
                                        onChange={(e) => {
                                          onChangeHandle(e, ele);
                                        }}
                                      />
                                      <label
                                        className="onoffswitch-label"
                                        htmlFor={`myonoffswitch ${index}`}
                                      >
                                        {ele.usertype === "admin" ? (
                                          <>
                                            <span className="onoffswitch-inner active-deactive"></span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="onoffswitch-inner active-deactive"></span>
                                            <span className="onoffswitch-switch"></span>
                                          </>
                                        )}
                                      </label>
                                    </div>
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justify-content-center">
                                      <button
                                        title="View"
                                        className="btn btn-eye mr-2"
                                        onClick={() => viewDetail(ele)}
                                      // to={"/trainerdetails?Id=" + listItems._id}
                                      >
                                        <i className="ace-icon fa fa-eye"></i>
                                      </button>
                                      <button
                                        title="Edit"
                                        className="btn btn-edit mr-2 "
                                        disabled={
                                          ele.usertype === "admin" ? true : false
                                        }
                                        onClick={(e) => {
                                          isEditStaff(ele);
                                        }}
                                      >
                                        <i className="fas fa-pen-square"></i>
                                      </button>
                                      <button
                                        title="Delete"
                                        className="btn btn-delete mr-2"
                                        disabled={
                                          ele.usertype === "admin" ? true : false
                                        }
                                        onClick={(e) => {
                                          deleteStaff(ele);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody></table>
                      </>
                    ) : (
                      <>
                        {dataLoader === false && popuploader === false && (

                          <div className="mt-3 text-center">
                            <div className="no-record-box">
                              <img src="/img/no-record.png" alt="no-img" />
                              <h4 className="font-weight-bold">
                                User Not Found
                              </h4>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* {searchname === "" ? (
                  <> */}
                {list?.length > 0 && (
                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={pageNum}
                      totalCount={noOfRecords}
                      pageSize={limitValue}
                      onPageChange={(page) => curPage(page)}
                    />
                  </div>
                )}
                {/* </>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {popuploader ? (
          <div id="loadermodal" className="loader">
            <div className="popuploader loginloader"></div>
          </div>
        ) : (
          <></>
        )}
        <Modal.Header closeButton>
          <Modal.Title className="d-sm-flex align-items-center justify-content-between mb-0 w-100">
            <div className="d-flex align-items-center">
              <img src="/img/common-health.png" alt="logo" />
              <h4 className="mb-0 ml-3">Staff / Sub-Admin</h4>
            </div>
            <div className="onoffswitch m-0">
              <input
                type="checkbox"
                name="onoffswitch"
                className="onoffswitch-checkbox"
                id="myonoffswitchedit"
                checked={staff.status}
                value={staff.status}
                onChange={(e) => {
                  setStaff({ ...staff, status: e.currentTarget.checked });
                }}
              />
              <label className="onoffswitch-label" htmlFor="myonoffswitchedit">
                <span className="onoffswitch-inner active-deactive"></span>
                <span className="onoffswitch-switch"></span>
              </label>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={onFileChange}
                      onError={(e) => {
                        e.target.src = "/img/Small-no-img.png";
                      }}
                    />
                    {isEdit ? (
                      <>
                        <button className="img-cancel" onClick={OnFileDiacard}>
                          <i className="fa fa-times"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <label htmlFor="imageUpload"></label>
                      </>
                    )}
                  </div>
                  <div className="avatar-preview">

                    <div
                      id="imagePreview"
                      style={{
                        backgroundImage: `url(${imagepreview || defaultImg})`,
                      }}
                    ></div>

                  </div>
                  <div className="prof_name"></div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-6 col-12">
                <div className="col-lg-12 col-md-12 col-12 mb-3 pr-0">
                  <label>First Name</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      className="input-box m-0"
                      type="text"
                      placeholder="First name"
                      value={staff.firstname}
                      onChange={(e) => {
                        setStaff({ ...staff, firstname: e.target.value });
                      }}
                    />
                    <div className="text-danger">{errors.firstname}</div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12 mb-3 pr-0">
                  <label>Last Name</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      className="input-box m-0"
                      type="text"
                      placeholder="Last name"
                      value={staff.lastname}
                      onChange={(e) => {
                        setStaff({ ...staff, lastname: e.target.value });
                      }}
                    />
                    <div className="text-danger">{errors.lastname}</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <label>Email</label>{" "}
                <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <input
                    className="input-box m-0"
                    type="email"
                    // disabled={staff.id ? true : false}
                    value={staff.email}
                    placeholder="E-mail"
                    onChange={(e) => {
                      setStaff({ ...staff, email: e.target.value });
                    }}
                  />
                  <div className="text-danger">{errors.email}</div>
                </div>
              </div>
              {!staff.id && (
                <div className="col-lg-6 col-md-6 col-12 mb-3">
                  <label>Password</label>{" "}
                  <span className="text-red font-bold">*</span>
                  <div className="input-group">
                    <input
                      className="input-box m-0"
                      disabled={staff.id}
                      maxLength={6}
                      placeholder="******"
                      type={isHidden === true ? "password" : "text"}
                      value={staff.password}
                      onChange={(e) => {
                        setStaff({ ...staff, password: e.target.value });
                      }}
                    />
                    <i
                      className={`fa fa-eye${isHidden === false ? "" : "-slash"
                        } log_i eyeicon`}
                      onClick={() => setIsHidden(!isHidden)}
                    ></i>
                    <div className="text-danger">{errors.password}</div>
                  </div>
                </div>
              )}
              {staff.id ? (
                <>
                  <div className="col-lg-6 col-md-6 col-12 mb-3">
                    <label>Phone No</label>{" "}
                    <span className="text-red font-bold">*</span>
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      inputclassName="w-100 mb-4"
                      name="phoneno"
                      placeholder="Phone number"
                      value={mobileNumber}
                      onChange={(value, country) => {
                        // setUser((prev)=>({...prev,[e.target.name]:e.target.value}))
                        const phone = value.replace(country.dialCode, "");
                        setStaff((prev) => ({ ...prev, phoneno: phone }));
                        setStaff((prev) => ({
                          ...prev,
                          country: country.countryCode,
                        }));
                        setStaff((prev) => ({
                          ...prev,
                          countryDialCode: `+${country.dialCode}`,
                        }));
                      }}
                    />
                    <div className="text-danger">{errors.phoneno}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-6 col-12 mb-3">
                    <label>Phone No</label>{" "}
                    <span className="text-red font-bold">*</span>
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      name="phoneno"
                      placeholder="Phone number"
                      onChange={(value, country) => {
                        const phone = value.replace(country.dialCode, "");
                        setStaff((prev) => ({ ...prev, phoneno: phone }));
                        setStaff((prev) => ({
                          ...prev,
                          country: country.countryCode,
                        }));
                        setStaff((prev) => ({
                          ...prev,
                          countryDialCode: `+${country.dialCode}`,
                        }));
                      }}
                    />
                    <div className="text-danger">{errors.phoneno}</div>
                  </div>
                </>
              )}
              <div className="col-md-6 col-sm-4 col-12 mb-3">
                <label>Gender</label>{" "}
                <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <i className="fa fa-angle-down darrow-i"></i>
                  <select
                    convert-number
                    className="input-box m-0"
                    value={staff.gender}
                    onChange={(e) => {
                      setStaff({ ...staff, gender: e.target.value });
                    }}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non-Binary">Non-Binary</option>
                  </select>
                  <div className="text-danger">{errors.gender}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn cancel_btn"
            disabled={isButtonDisabled}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {staff.id ? (
            <>
              <Button
                className="btn save_btn"
                variant="secondary"
                disabled={isButtonDisabled}
                onClick={(e) => {
                  update(e, staff.id);
                }}
              >
                {/* {buttonLoader ? (<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) : ""} */}
                Update
              </Button>
            </>
          ) : (
            <>
              <Button
                className="btn save_btn"
                variant="secondary"
                disabled={isButtonDisabled}
                onClick={(e) => {
                  saveStaff(e);
                }}
              >
                {/* {buttonLoader ? (<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></>) : ""} */}
                Save
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      {/* subadmin profile view */}
      <Modal
        show={show3}
        onHide={handleClose3}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-sm-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Staff / Sub-Admin Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataLoader ? (
            <div id="loadermodal" className="loader">
              <div className="popuploader loginloader"></div>
            </div>
          ) : (
            <div id="Details">
              <div className="col-md-12 col-12">
                <div className="row">
                  <div className="col-lg-4 col-12">
                    <div className="detail-img">
                      {showDeatil?.profile ? (
                        <img
                          src={apiUrl + showDeatil?.profile}
                          alt=""
                          onError={(e) => {
                            e.target.src = "/img/Small-no-img.png";
                          }}
                        />
                      ) : (
                        <img src={"/img/Small-no-img.png"} alt="" />
                      )}
                    </div>
                    {/* <div className="text-center">
                     <div className="">
                        <label className="modal-lbl">{showDeatil?.firstname} {showDeatil?.lastname}</label>
                      </div>
                  </div> */}
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="modal-box">
                      <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">Name </label>
                          <span>
                            {showDeatil?.firstname || ""}{" "}
                            {showDeatil?.lastname || ""}
                          </span>
                        </div>
                        {/* <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Last Name </label>
                        <span>{showDeatil?.lastname}</span>
                      </div> */}
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">Email </label>
                          <span>{showDeatil?.email || ""}</span>
                        </div>

                        {/* <div className="col-md-6 col-12 mb-3">
                        <label className="modal-lbl">Password </label>
                        <span>******</span>
                      </div> */}
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">Mobile No </label>
                          <span>
                            {showDeatil?.countryDialCode || ""}{" "}
                            {showDeatil?.phoneno || ""}
                          </span>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">Gender </label>
                          <span>{showDeatil?.gender || ""}</span>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">User Type </label>
                          <span>{showDeatil?.usertype || ""}</span>
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                          <label className="modal-lbl">Status </label>
                          {/* <span className="text-success">{showDeatil?.statusid}</span> */}
                          {showDeatil?.statusid ? (
                            <span className="text-success">Activated</span>
                          ) : (
                            <span className="text-danger">DeActivated</span>
                          )}
                        </div>
                        {showDeatil?.socialmedia?.length > 0 ? (
                          <>
                            <div className="col-md-6 col-12 mb-3 ">
                              <label className="modal-lbl">Social Media </label>
                              <ul className="list-inlne modal-media mb-0 pb-0 pl-0">
                                {showDeatil?.socialmedia?.map((media, i) => (
                                  <li
                                    key={i}
                                    className="list-inline-item position-relative"
                                  >
                                    <Link
                                      className="text-decoration-none"
                                      to={media.url}
                                      target="_blank"
                                    >
                                      <span className="px-1">
                                        <i
                                          className={media.icon}
                                          aria-hidden="true"
                                          title={media.platform}
                                        ></i>
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserManagement;
