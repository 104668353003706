import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import "./App.css";
import BasicMovements from "./components/BasicMovements";
import Client from "./components/Client";
import ClientBookingHistory from "./components/ClientBookingHistory";
import ClientRatings from "./components/ClientRatings";
import EquipmentListing from "./components/EquipmentListing";
import ForgotPassword from "./components/ForgotPassword";
import Login from "./components/Login";
import ManageGoalTypes from "./components/ManageGoalType";
import Dashboard from "./components/Masters/Dashboard";
import Notifications from "./components/Others/Notifications";
import PaymentSetup from "./components/Others/PaymentSetup";
import ProfileManagment from "./components/Others/ProfileManagement";
import Settings from "./components/Others/Settings";
import PaymentReport from "./components/PaymentReport";
import ResetPassword from "./components/ResetPassword";
import Subscribers from "./components/Subscribers";
import Trainer from "./components/Trainer";
import TrainerBookingHistory from "./components/TrainerBookingHistory";
import Transactions from "./components/Transactions";
import UserManagement from "./components/UserManagement";
import ClientDetails from "./components/UserManagement/ClientDetails";
import TrainerDetails from "./components/UserManagement/TrainerDetails";
import WorkOutCategory from "./components/WorkOutCategory";
// import CountryListing from './components/CountryListing';
// import WorkOutForm from './components/WorkOutForm';
import WorkoutForm from "./components/WorkOutForm";
// import Dashboard from './components/Masters/Dashboard';
import AdminLayout from "./layouts/AdminLayout";
import ProtectedRoute from "./components/ProtectedRoute";
import Swal from "sweetalert2";
import Contectus from "./components/Others/Contectus";
import Newsletter from "./components/Others/Newsletter";
import { ToastContainer } from "react-toastify";

function AppRoutes() {
  return (<>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<LogOut />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route
        path="/:usertype?/account/resetpassword/:credentials?"
        element={<ResetPassword />}
      />

      {/* require authentication */}
      <Route element={<ProtectedRoute />}>
        <Route element={<AdminLayout />}>
          <Route path="/trainer/:pages" element={<Trainer />} />
          <Route path="/member/:pages" element={<Client />} />
          <Route path="/usermanagement/:pages" element={<UserManagement />} />
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/trainer" component={Trainer} />
                        <Route path="/client" component={Client} />
                      <Route path="/usermanagement" component={UserManagement} /> */}
          <Route
            path="/trainerbookinghistory"
            element={<TrainerBookingHistory />}
          />
          <Route
            path="/memberbookinghistory/:pages"
            element={<ClientBookingHistory />}
          />
          <Route path="/workoutforms/:pages" element={<WorkoutForm />} />
          <Route path="/workoutcategory/:pages" element={<WorkOutCategory />} />
          <Route path="/basicMovements/:pages" element={<BasicMovements />} />
          <Route path="/clientratings/:pages" element={<ClientRatings />} />
          <Route path="/managegoalstype/:pages" element={<ManageGoalTypes />} />
          <Route path="/equipmentlisting/:pages" element={<EquipmentListing />} />
          <Route path="/subscribers/:pages" element={<Subscribers />} />
          <Route path="/transactions/:pages" element={<Transactions />} />
          <Route path="/notifications/:pages" element={<Notifications />} />
          <Route path="/settings/:pages" element={<Settings />} />
          <Route path="/contactus/:pages" element={<Contectus />} />
          <Route path="/newsletter/:pages" element={<Newsletter />} />
          <Route path="/paymentreport" element={<PaymentReport />} />
          <Route path="/paymentsetup" element={<PaymentSetup />} />
          <Route path="/profilemanagement" element={<ProfileManagment />} />
          <Route path="/clientdetails" element={<ClientDetails />} />
          <Route path="/trainerdetails" element={<TrainerDetails />} />
        </Route>
      </Route>
    </Routes>
      <ToastContainer/>
  </>);
}

export default AppRoutes;

export const LogOut = async (e) => {
  e.preventDefault();

  e.preventDefault();
    Swal.fire({
      icon: 'warning',
      title: "Are you sure you want to logout ?",
      showCancelButton: true,
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        window.location.href = "/";
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
};
