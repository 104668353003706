import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./deshboard.css";
import axios from "axios";
import { apiUrl } from "../../environment/environment";
import swal from "sweetalert";
import { useNavigate,Link } from "react-router-dom";
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Import 'auto' to ensure that the necessary files are included

function Dashboard() {

  const navigate = useNavigate();

  const usertypelocalStorage = localStorage.getItem("usertype")

  if (!usertypelocalStorage === "admin") {
    navigate("/");
  }
  const [chartDate] = useState({
    options: {
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        //  categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
        axisBorder: { show: true },
        axisTicks: { show: false },
        labels: { show: true },
      },
      yaxis: { show: false },
      grid: { show: false },
      chart: {
        sparkline: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
    },
    // series: [
    //   {
    //     name: "Member",
    //     data: [0,1,3,5,7,9,11,13,15,17],
    //   },
    //   {
    //     name: "Trainer",
    //     data: [0,2,4,6,8,10,12,14,16,18],
    //   },
    // ],
  });

  const [memberCount, setMemberCount] = useState("");
  const [trainerCount, setTrainerCount] = useState("");
  const [videoSessionCount, setVideoSessionCount] = useState("");
  const [Trainer, setTrainer] = useState([]);
  const [Member, setMember] = useState([]);
  const [dataLoader, setDataLoader] = useState(false)
 
  const currentYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(currentYear);


  useEffect(() => {
    getCountMembers();
    getCountTrainer();
    getCountVideoSession(currentYear);
    monthChart(currentYear);
  }, []);

  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value);
    setSelectedYear(selectedYear);
    monthChart(e.target.value)
    getCountVideoSession(e.target.value)
  };


  const monthChart = (year) => {
    // var currentTime = new Date();
    // var year = currentTime.getFullYear();
    var obj = {
      year: parseInt(year),
    };
    // setIsLoader(true)
    //return false
    setDataLoader(true)
    axios.post(`${apiUrl}/admin/monthchart`, obj).then((response) => {
      if (response.data.status === 1) {

        let trainerobj = [];
        let memberobj = [];
        let memberdata = response.data.result.memberCount;
        memberdata?.forEach((trainer) => {
          memberobj.push(trainer.count);
        });

        let trainerdata = response.data.result.trainerCount;
        trainerdata?.forEach((trainer) => {
          trainerobj.push(trainer.count);
        });

        setTimeout(() => {
          setMember(memberobj);
          setTrainer(trainerobj);
          setDataLoader(false)
        }, 1000);
        // setIsLoader(false)
      } else {
        setDataLoader(false)
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }
    });
  };

  const getCountMembers = () => {
    // setIsLoader(true)
    setDataLoader(true)
    // return false
    axios
      .post(`${apiUrl}/admin/clientlist`)
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            setMemberCount(response.data.result.noOfRecords);
            setDataLoader(false)
          }, 500);
          // setIsLoader(false)
        } else {
          // setIsLoader(false)
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch((error) => {
        console.log("dashboard", error);
      });
  };

  const getCountTrainer = () => {
    // setIsLoader(true)
    setDataLoader(true)
    axios
      .post(`${apiUrl}/admin/trainerlist`)
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            setTrainerCount(response.data.result.noOfRecords);
            setDataLoader(false)
          }, 500);
        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // setIsLoader(false)

      });
  };

  const getCountVideoSession = (year) => {
    setDataLoader(true)
    let obje={
      year:parseInt(year)
    }
    axios.post(`${apiUrl}/admin/clientBookingListYear`,obje)
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            setVideoSessionCount(response?.data?.result[0]?.totalCount[0]?.count || 0);
            setDataLoader(false)
            console.log("response.data.result[0]",response.data.result[0]);
          }, 500);
        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const data = {
    labels: ['All Members', 'All Trainers',"All Booking History"],
    datasets: [
      {
        data: [memberCount, trainerCount,videoSessionCount],
        backgroundColor: ['#d81f1f', '#22c55e', '#4c75cf'],
        hoverBackgroundColor: ['#d81f1f', '#22c55e', '#4c75cf'],
      },
    ],
  };
 
  
  
  return (
    <>
     
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4 className="mb-2">Dashboard</h4>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          {dataLoader && 
           <div id="loadermodal" className="loader"><div className="popuploader loginloader"></div></div>
          }
          <div className="row">
            <div className="col-xl-4 col-md-6 col-12 mb-4">
              <Link to="/member/1" className="dashboard-box d-flex justify-content-between">
                <div>
                  <h4>All Members</h4>
                  <span className="d-text">{memberCount}</span>
                </div>
                <div className="user-i red-bg">
                  <i className="fa fa-users"></i>
                </div>
              </Link>
            </div>
            <div className="col-xl-4 col-md-6 col-12 mb-4">
              <Link to="/trainer/1" className="dashboard-box d-flex justify-content-between">
                <div>

                  <h4>All Trainers</h4>
                  <span className="d-text">{trainerCount}</span>
                </div>
                <div className="user-i green-bg">
                  <i className="fa fa-user"></i>
                </div>
              </Link>
            </div>
            <div className="col-xl-4 col-md-6 col-12 mb-4">
              <Link to="/memberbookinghistory/1" className="dashboard-box d-flex justify-content-between">
                <div>

                  <h4>Trainer and Member Booking History</h4>
                  <span className="d-text">{videoSessionCount}</span>
                </div>
                <div className="user-i blue-bg">
                  <i className="fas fa-video"></i>
                </div>
              </Link>
            </div>
          </div>

          <div className="row">
                <div className="col-xl-3 col-md-3 col-sm-6 col-12 mb-3">
                  <label htmlFor="yearSelect" className="position-relative">Select Year<span class="tooltip info"><i class="fa fa-info-circle"></i>
                  <span class="tooltiptext">Choose year to display graph by year.</span>
                </span></label>
                  <select id="yearSelect"  className="input-box" value={selectedYear} onChange={handleYearChange}>
                    {Array.from({ length: 101 }, (_, i) => 2023 + i).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {/* <small>Choose year to display graph by year.</small> */}
                </div>
                
            <div className="col-md-12 col-12">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                  <div className="chart-box position-relative">
                 
                        {Member.length > 0 && Trainer.length > 0 ? (<>

                          <Chart
                            series={[
                              { name: "Member", data: Member },
                              { name: "Trainer", data: Trainer },
                            ]}
                            options={chartDate?.options}
                            type="line"
                            className="apex-chart"
                          />
                        </>) : (<>
                          {dataLoader===false && Member.length < 0 && Trainer.length < 0  && (
                            <div className="mt-3 text-center">
                              <div className="no-record-box text-center">
                                <h4 className="font-weight-bold">
                                  Data not found
                                </h4>
                              </div>
                            </div>
                          )}
                        </>)}

                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="chart-box position-relative">
                      <Pie data={data}  />
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
}
export default Dashboard;