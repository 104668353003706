import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { apiUrl } from "../../environment/environment";
import Swal from "sweetalert2";
import swal from "sweetalert";
import Pagination from "../Pagination/Pagination";


function Newsletter() {
  const navigate = useNavigate();

  const { pages } = useParams();
  const [dataLoader, setDataLoader] = useState(false);

  const [pageNum, setPageNum] = useState(+pages);
  // const [isLoader, setIsLoader] = useState(false);
  const [newsletterData, setNewsletterData] = useState([]);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const limitValue = 10;



  function getNewsletter(val) {
    setDataLoader(true);
    // setIsLoader(true);

    const obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
    };
    axios
      .post(`${apiUrl}/admin/getnewsletter`, obj)
      .then((res) => {
        setTimeout(() => {
          setNewsletterData(res?.data?.result?.findNewsletter);
          setNoOfRecords(res?.data?.result?.noOfRecords || 0);
          setDataLoader(false);
          // setIsLoader(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setDataLoader(false);
        // setIsLoader(false);
      });
  }

  useEffect(() => {
    getNewsletter(pageNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteClick = (e) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        cancelButton: "btn btn-danger",
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setDataLoader(true);
          // setIsLoader(true);
          axios
            .post(`${apiUrl}/admin/deletenewslette/${e._id}`)
            .then((response) => {
              // newsletterData.filter((list) => list._id !== e._id)
              setTimeout(async () => {
                const newList = newsletterData.filter((x) => x._id !== e._id);

                if (newList.length === 0) {
                  if (pageNum === 1) {
                    setPageNum(pageNum);
                    await getNewsletter(pageNum);
                    setNewsletterData(newList);
                    navigate(`/newsletter/${pageNum}`);
                  } else {
                    setPageNum(pageNum - 1);
                    await getNewsletter(pageNum - 1);
                    setNewsletterData(newList);
                    navigate(`/newsletter/${pageNum - 1}`);
                  }
                } else {
                  setNewsletterData(newList);
                }
                swal({
                  title: "Success!",
                  text: response.data.message,
                  icon: "success",
                  button: true,
                });
                setDataLoader(false);
              }, 1000);
            })
            .catch((err) => {
              window.alert(err);
              setDataLoader(false);
              // setIsLoader(false);
            });
        }
      });
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    getNewsletter(pageNum);
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      setPageNum(pageNum); // Reset page number when clearing the search
      getNewsletter(pageNum);
    }
  };

  const searchClick = async (searchData, pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };

      const response = await axios.post(`${apiUrl}/admin/searchnewsletter`,
        { searchname: searchData, obj }, {})

      if (response.data.status === 1) {
        console.log("search ddata", response?.data?.result);
        setNewsletterData(response?.data?.result?.paginatedResults);
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0);
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }

    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // if (!searchData) {
    //   setDataLoader(false);
    //   // setIsLoader(false);
    //   getNewsletter();
    //   setDataLoader(false);
    //   // setIsLoader(false);
    // } else {
    //   await axios
    //     .post(
    //       `${apiUrl}/admin/searchnewsletter`,
    //       { searchname: searchData },
    //       {}
    //     )
    //     .then(function (response) {
    //       if (response.data.status === 1) {
    //         setNewsletterData(response.data.result);
    //         setSearching(true);
    //       } else {
    //         swal({
    //           title: "Error!",
    //           text: response.data.message,
    //           icon: "error",
    //           button: true,
    //         });
    //       }
    //     })
    //     .catch(function (error) {
    //       // setIsLoader(false);
    //       setDataLoader(false);
    //       window.alert(error);
    //     });
    // }
  };

  const curPage = (pageNum) => {
    navigate(`/newsletter/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async() => {
        await searchClick(searchname, pageNum);
        setDataLoader(false);
      }, 500);
    // searchClick(searchname, pageNum);
  } else {
    setNewsletterData(pageNum);
  }
  };



  return (
    <>
      {/* {isLoader && (
        <div className="loader">
          <div className="popuploader loginloader"></div>
        </div>
      )} */}
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4 className="mb-0">News Letter</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">News Letter</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card otherpage-box">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <>
                      <button className="search_btn mt-0" onClick={clearClick}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="search_btn mt-0"
                        onClick={() => searchClick("")}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive">

                    {dataLoader ? (
                      // <span>Loadnig..</span>
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}


                    {newsletterData.length > 0 ? (<>
                      <table className="table table-bordered table-hover mb-0">
                        <thead>
                          <tr>
                            <th style={{ "width": "50px" }}>Sr. No</th>
                            <th>Email</th>
                            <th className="text-center w130">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {newsletterData.map((ele, index) => {
                            return (
                              <>
                                <tr key={"index" + index}>
                                  <td style={{ "width": "50px" }}>
                                    {index + 1 + (pageNum - 1) * limitValue}
                                  </td>
                                  <td>{ele.email}</td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-delete mr-2"
                                      onClick={() => {
                                        deleteClick(ele);
                                      }}
                                    >
                                      <i className="ace-icon fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </>) : (
                      <>
                        {dataLoader === false && (
                          <div className="text-center" colSpan="10">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  News Letter Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                  </div>
                </div>

                {newsletterData.length > 0 && (
                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={pageNum}
                      totalCount={noOfRecords}
                      pageSize={limitValue}
                      onPageChange={(page) => curPage(page)}
                    />
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Newsletter;
