import axios from "axios";
import React, { useState } from "react";
import { apiUrl } from "../environment/environment";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

function ForgotPassword() {
  const usertype = sessionStorage.getItem("usertype");
  const navigate=useNavigate()
  const [email, setUser] = useState("");
  const [error, setError] = useState({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleInputs = (e) => {
    setUser(e.target.value);
  };

  const PostForgotPassword = async (e) => {
    e.preventDefault();

    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (email === "") {
      errormsg.email = "Please enter email id!";
      isValid = false;
    }else if (reg_email.test(email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    }
    setError(errormsg)
    console.log(errormsg);
    if(isValid){
      setIsButtonDisabled(true)
      setButtonLoader(true)
      // document.querySelector(".loader").classList.remove("d-none");
      await axios
        .post(
          `${apiUrl}/${usertype}/account/passwordlink`,
          { email: email },
          {}
        )
        .then(function (response) {
          // document.querySelector(".loader").classList.add("d-none");
          if (response.data.status === 1) {
            setIsButtonDisabled(false)
            setButtonLoader(false)
            swal({
              title: "Sucess!",
              text: response.data.message,
              icon: "success",
              button: true,
            });
            // window.location.href = "/login";
            navigate("/login");
          } else {
            setIsButtonDisabled(false)
            setButtonLoader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          setIsButtonDisabled(false)
          setButtonLoader(false)
          // document.querySelector(".loader").classList.add("d-none");
          console.log(error);
        });
    }
    

    
  };

  return (
    <>
      <div className="bg-img">
        <div className="container">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="row">
              <div className="col-xl-5 col-md-8 col-12 mx-auto log-m">
                <div id="loader" className="loader d-none">
                  <div className="popuploader loginloader"></div>
                </div>
                <div className="reset_pass">
                  <div className="log_logo">
                    <img src="/img/common-health.png" alt="Logo" />
                  </div>
                  <div className="modal-body">
                    <h4 className="modal-title">Forgot Password</h4>
                    <div className="col-md-12 col-sm-12 col-12 mb-4">
                      <div className="position-relative">
                        <input
                          onChange={(e) => handleInputs(e)}
                          name="email"
                          className="input-box"
                          type="text"
                          placeholder="Email"
                        />
                        <i className="fa fa-envelope log_i"></i>
                      </div>
                      <div className="text-red">{error.email}</div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 text-right">
                      <button
                       disabled={isButtonDisabled}
                        onClick={(e) => PostForgotPassword(e)}
                        type="button"
                        className="log_btn"
                      >
                        {buttonLoader ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          ""
                        )}
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 col-sm-12 col-12 mr-2">
                      <Link to="/login" className="back_btn">
                        Back To Login
                        <i className="fa fa-arrow-circle-left pl-2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
