import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import Pagination from "../Pagination/Pagination";

const MyEnum = {
  SESDLY: "SESDLY",
  STNCHR: "STNCHR",
  ELTCHR: "ELTCHR",
};


function Settings() {

  const { pages } = useParams()
  const [pageNum, setPageNum] = useState(+pages);
  const [dataLoader, setDataLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [show, setShow] = useState(false);
  // const [isLoader, setIsLoader] = useState(false);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [List, setList] = useState([]);
  const handleClose = () => setShow(false);
  const [setting, setSetting] = useState({
    id: null,
    key: "",
    code: "",
    val: null,
  });
  const [popuploader, setPopuploader] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const limitValue = 10;
  const [columnArr, setColumnArr] = useState([
    { name: "Key", value: "key", dir: 1 },
    { name: "Code", value: "code", dir: 1 },
    { name: "Value (Minute)", value: "val", dir: 1 },
  ]);

  useEffect(() => {
    GetList(pageNum);
  }, []);

  const GetList = (val) => {
    setSelectedPage(val);
    setDataLoader(true)
    // setIsLoader(true);
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };
    axios
      .post(`${apiUrl}/admin/settinglist`, obj)
      .then(function (response) {
        // setIsLoader(false);
        if (response.data.status === 1) {
          setTimeout(() => {
            setNoOfRecords(response.data?.result?.noOfRecords || 0);
            setList(response.data?.result?.settinglist);
            setDataLoader(false)
          }, 1000);
          // setIsLoader(false);
        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setDataLoader(false)
        // setIsLoader(false);
        window.alert(error);
      });
  };

  const setCodeValue = (value) => {
    if (MyEnum.SESDLY) return value + " (Minute)";
    else if (MyEnum.STNCHR || MyEnum.ELTCHR) return value + " $";
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value, pageNum);
    } else {
      setSearching(false);
      setPageNum(pageNum); // Reset page number when clearing the search
      GetList(pageNum);
    }
  };

  const searchClick = async (searchData, pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };
      const response = await axios.post(`${apiUrl}/admin/searchsettings`, { searchname: searchData, obj }, {})
      if (response.data.status === 1) {
        setList(response?.data?.result?.paginatedResults);
        setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0);
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }
    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // if (!searchname) {
    //   setDataLoader(false)
    //   // setIsLoader(false)
    //   GetList(pageNum);
    //   setDataLoader(false)
    //   // setIsLoader(false)
    // } else {
    //   // setIsLoader(true);
    //   await axios
    //     .post(`${apiUrl}/admin/searchsettings`, { searchname: searchname }, {})
    //     .then(function (response) {
    //       // setIsLoader(false);
    //       if (response.data.status === 1) {
    //         setList(response.data.result);
    //         setSearching(true);
    //       } else {
    //         swal({
    //           title: "Error!",
    //           text: response.data.message,
    //           icon: "error",
    //           button: true,
    //         });
    //       }
    //     })
    //     .catch(function (error) {
    //       setDataLoader(false)
    //       // setIsLoader(false);
    //       window.alert(error);
    //     });
    // }
  };

  const curPage = (pageNum) => {
    setPageNum(pageNum);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async () => {
        await searchClick(searchname, pageNum);
        setDataLoader(false);
      }, 500);
    } else {
      GetList(pageNum);
    }
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  const isEdit = (obj) => {
    setDataLoader(false)
    setSetting({
      ...setting,
      id: obj._id,
      key: obj.key,
      code: obj.code,
      val: obj.val,
    });
    setShow(true);
  };

  const isSave = (e) => {
    e.stopPropagation();
    let isValid = true;
    var errormsg = {};

    if (setting.val === "") {
      errormsg.value = "Please enter value!";
      isValid = false;
    } else if (!/^\d+$/.test(setting.val)) {
      // Check if value contains only digits
      errormsg.value = "Please enter only digits!";
      isValid = false;
    }


    setErrors(errormsg);
    if (isValid) {
      // setDataLoader(true)
      setIsButtonDisabled(true);
      setButtonLoader(true)
      setPopuploader(true)
      // setIsLoader(true);
      axios
        .post(`${apiUrl}/admin/savesetting`, setting)
        .then(function (response) {
          // setIsLoader(false);
          if (response.data.status === 1) {
            setTimeout(() => {
              setErrors({});
              GetList(pageNum);
              setIsButtonDisabled(false);
              setPopuploader(false)
              setButtonLoader(false)
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setDataLoader(false)
              setShow(false);
            }, 1000);
          } else {
            setDataLoader(false)
            setIsButtonDisabled(false);
            setPopuploader(false)
            setButtonLoader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          setDataLoader(false)
          // setIsLoader(false);
          window.alert(error);
        });
    }
  };

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    setDataLoader(false)
    // setIsLoader(false)
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetList(selectedPage);
    setDataLoader(false)
    // setIsLoader(false)
  };

  return (
    <>
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Settings</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Settings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card otherpage-box">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                  />
                  {isSearching ? (
                    <button className="search_btn" onClick={clearClick}>
                      <i className="fa fa-times"></i>
                    </button>
                  ) : (
                    <button
                      className="search_btn"
                      onClick={searchClick}
                      type="button"
                    >
                      {" "}
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive">

                    {dataLoader ? (
                      // <span>Loadnig..</span>
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}


                    {List?.length > 0 ? (<>
                      <table className="table table-bordered table-hover mb-0">
                        <thead>
                          <tr>
                            <th style={{ "width": "50px" }}>Sr. No</th>
                            {columnArr.map((col, i) => {
                              return (
                                <th
                                  style={{ cursor: "pointer" }}
                                  onClick={() => isSortedColumn(col.value, col.dir)}
                                >
                                  <div className="d-flex justify-content-between">
                                    {col.name}
                                    <span className="align-self-center">
                                      <i className="ml-2 fa fa-sort sort-gray"></i>
                                      <i
                                        className={`${col.dir === 1
                                          ? "sort-i fa fa-sort-asc"
                                          : "sort-i fa fa-sort-desc"
                                          }`}
                                      ></i>
                                    </span>
                                  </div>
                                </th>
                              );
                            })}
                            <th className="text-center w130">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {List.map((ele, i) => {
                            return (
                              <tr key={"index" + i}>
                                <td style={{ "width": "50px" }}>{i + 1}</td>
                                <td>{ele.key}</td>
                                <td>{ele.code}</td>
                                <td>{setCodeValue(ele.val)}</td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-edit mx-auto"
                                    onClick={(e) => {
                                      isEdit(ele);
                                    }}
                                  >
                                    <i className="ace-icon fas fa-pen-square"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                          <div className="text-center" colSpan="10">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Data Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                  </div>
                </div>
                {List?.length > 0 && (
                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={pageNum}
                      totalCount={noOfRecords}
                      pageSize={limitValue}
                      onPageChange={(page) => curPage(page)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {popuploader &&
          <div id="loadermodal" className="loader">
            <div className="popuploader loginloader"></div>
          </div>
        }
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Settings</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 pb-3">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <label>Key</label> <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <input
                    className="input-box"
                    type="text"
                    value={setting.key}
                    placeholder="Key"
                    disabled={setting.id ? true : false}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <label>Code</label> <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Code"
                    value={setting.code}
                    onChange={(e) => {
                      setSetting({ ...setting, code: e.target.value });
                    }}
                    disabled={setting.id ? true : false}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mb-3">
                <label>Value (Minute)</label> <span className="text-red font-bold">*</span>
                <div className="input-group">
                  <input
                    className="input-box"
                    type="text"
                    value={setting.val}
                    placeholder="Value"
                    onChange={(e) => {
                      setSetting({ ...setting, val: e.target.value });
                    }}
                  />
                  <div className="text-danger">{errors.value}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn cancel_btn"
            disabled={isButtonDisabled}
            onClick={(e) => {
              setErrors({});
              setShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isButtonDisabled}
            className="btn save_btn"
            variant="secondary"
            onClick={(e) => {
              isSave(e);
            }}
          >
            {/* {buttonLoader ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            ) : (
              ""
            )} */}
            {setting.id ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Settings;
