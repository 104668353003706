//For local setup
const local = {
   apiUrl: "https://api.chealth.in",
    //  apiUrl: "http://localhost:3155",
   
};

//For staging server
const staging = {
   apiUrl: "https://api.chealth.in",
    // apiUrl: "http://localhost:3155",
   
};

//For production server
const production = {
    apiUrl: "https://api.chealth.in",
    // apiUrl: "http://localhost:3155",
   
};

if (process.env.REACT_APP_ENV === "local") module.exports = local;
else if (process.env.REACT_APP_ENV === "staging") module.exports = staging;
else if (process.env.REACT_APP_ENV === "production") module.exports = production;
else module.exports = staging;
