import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../environment/environment";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "./Pagination/Pagination";
import swal from "sweetalert";
import Swal from "sweetalert2";

function WorkoutForm() {
  let navigate = useNavigate();
  const { pages } = useParams();

  // const [isLoader, setIsLoader] = useState(false);
  const [show1, setShow1] = useState(false);
  const [list, setList] = useState([]);
  const [getlist, setGetlist] = useState();
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [pageNum, setPageNum] = useState(+pages);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [isSortedCol, setIsSortedCol] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const limitValue = 10;
  const [popuploader, setPopuploader] = useState(false);

  const [columnArr, setColumnArr] = useState([
    { name: "Member Name", value: "client_data.firstname", dir: 1 },
    { name: "Fitness Goals", value: "sessionworkout.fitnessGoals", dir: 1 },
    { name: "Date", value: "date", dir: 1 },
  ]);

  const [dataLoader, setDataLoader] = useState(false);

  useEffect(() => {
    GetList(pageNum || 1);
  }, []);

  async function GetList(val) {
    setSelectedPage(val);
    // setIsLoader(true)
    setDataLoader(true);
    // document.querySelector(".loader").classList.remove("d-none");
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };
    await axios
      .post(`${apiUrl}/admin/workoutlist`, obj)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            setNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
            setList(response.data?.result[0]?.paginatedResults);
            setDataLoader(false);
          }, 1000);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        // setIsLoader(false)
        setDataLoader(false);
        // document.querySelector(".loader").classList.add("d-none");
        swal({
          title: "Error!",
          text: error,
          icon: "error",
          button: true,
        });
      });
  }

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    // setIsLoader(false)
    setDataLoader(false);
    setColumnArr(columnArr);
    setIsSortedCol(col);
    setDataLoader(false);
    GetList(selectedPage);
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
    // setIsLoader(false)
  };

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value) {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      setPageNum(pageNum); // Reset page number when clearing the search
      GetList(pageNum);
    }
  };

  const searchClick = async (searchData) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };
      const response=await axios.post(`${apiUrl}/admin/searchworkoutsession`,{ searchname: searchData, obj},{})
      if (response.data.status===1) {
        setList(response.data.result[0]?.paginatedResults)
        setNoOfRecords(response.data.result[0]?.totalCount[0]?.count)
        // console.log("response.data.resul",response.data.result[0]?.totalCount[0]?.count || 0);
      }else{
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }
    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // if (!searchData) {
    //   // setIsLoader(false)
    //   setDataLoader(false);
    //   setDataLoader(false);
    //   GetList(pageNum);
    //   // setIsLoader(false)
    // } else {
    //   // document.querySelector('.loading').classList.remove('d-none');
    //   await axios
    //     .post(
    //       `${apiUrl}/admin/searchworkoutsession`,
    //       { searchname: searchData },
    //       {}
    //     )
    //     .then(function (response) {
    //       // document.querySelector(".loader").classList.add("d-none");
    //       if (response.data.status === 1) {
    //         setList(response.data.result);
    //         setSearching(true);
    //       } else {
    //         swal({
    //           title: "Error!",
    //           text: response.data.message,
    //           icon: "error",
    //           button: true,
    //         });
    //       }
    //     })
    //     .catch(function (error) {
    //       window.alert(error);
    //     });
    // }
  };

  const isEdit = (obj) => {
    console.log("obj",obj);
    setGetlist(obj);
    setShow1(true);
  };

  const curPage = (pageNum) => {
    navigate(`/workoutforms/${pageNum}`);
    setPageNum(pageNum);
    GetList(pageNum);
  };

  const deleteClick = (val) => {
    let obj = {
      id: val._id,
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // setIsLoader(true)
          setDataLoader(true);
          setPopuploader(true);
          // document.querySelector(".loader").classList.remove("d-none");
          axios
            .post(`${apiUrl}/admin/deleteworkoutform`, obj)
            .then(function (response) {
              // setIsLoader(false)
              // document.querySelector(".loader").classList.add("d-none");
              if (response.data.status === 1) {
                setTimeout(async () => {
                  const newList = list.filter((x) => x._id !== val._id);
                  if (newList?.length === 0) {
                    setShow1(false);
                    if (pageNum === 1) {
                      setPageNum(pageNum);
                      await GetList(pageNum);
                      setList(newList);
                      navigate(`/workoutforms/${pageNum}`);
                    } else {
                      setPageNum(pageNum - 1);
                      await GetList(pageNum - 1);
                      setList(newList);
                      navigate(`/workoutforms/${pageNum - 1}`);
                    }
                  } else {
                    setList(newList);
                  }
                  swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true,
                  });
                  setPopuploader(false);
                  setDataLoader(false);
                }, 1000);
              } else {
                setPopuploader(false);
                setDataLoader(false);
                swal({
                  title: "Error!",
                  text: response.data.message,
                  icon: "error",
                  button: true,
                });
                //window.alert(response.data.message);
              }
            })
            .catch(function (error) {
              // setIsLoader(false)
              setDataLoader(false);
              // document.querySelector(".loader").classList.add("d-none");
              window.alert(error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Workout Form</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Workout Form</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <button className="search_btn" onClick={clearClick}>
                      <i className="fa fa-times"></i>
                    </button>
                  ) : (
                    <button
                      className="search_btn"
                      onClick={() => searchClick("")}
                    >
                      {" "}
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-12 record_table workform mt-2">
                <div className="position-relative">
                  <div className="table-responsive">
                    {dataLoader ? (
                      // <span>Loadnig..</span>
                      <div id="loadermodal" className="loader">
                        <div className="popuploader loginloader"></div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {list?.length > 0 ? (
                      <>
                        <table className="table table-bordered table-hover mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>Sr. No</th>
                              {columnArr.map((col, i) => {
                                return (
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      isSortedColumn(col.value, col.dir)
                                    }
                                  >
                                    <div className="d-flex justify-content-between">
                                      {col.name}
                                      <span className="align-self-center">
                                        <i className="ml-2 fa fa-sort sort-gray"></i>
                                        <i
                                          className={`${
                                            col.dir === 1
                                              ? "sort-i fa fa-sort-asc"
                                              : "sort-i fa fa-sort-desc"
                                          }`}
                                        ></i>
                                      </span>
                                    </div>
                                  </th>
                                );
                              })}
                              {/* <th>Basic Movements</th> */}
                              <th className="text-center w130">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.map((ele, index) => {
                              return (
                                <tr key={"index" + index}>
                                  <td style={{ width: "50px" }}>
                                    {index + 1 + (pageNum - 1) * limitValue}
                                  </td>
                                  <td>{ele.client_data?.firstname}</td>
                                  <td>{ele.sessionworkout.fitnessGoals}</td>
                                  <td className="text-nowrap">
                                    {new Date(ele.date).toDateString()}
                                  </td>
                                  {/* <td>
                                <div className="disc">
                                  {ele.sessionworkout.basicMovements !==
                                    undefined &&
                                    ele.sessionworkout.basicMovements.map(
                                      (rs) => {
                                        return <p>{rs.movementName}</p>;
                                      }
                                    )}
                                </div>
                              </td> */}
                                  <td className="text-center">
                                    <div className="d-flex justify-content-center">
                                      <button
                                       title="View"
                                        className="btn btn-eye mr-2"
                                        onClick={(e) => {
                                          isEdit(ele);
                                        }}
                                      >
                                        <i className="ace-icon fa fa-eye"></i>
                                      </button>
                                      <button
                                        title="Delete"
                                        className="btn btn-delete mr-2"
                                        onClick={() => {
                                          deleteClick(ele);
                                        }}
                                      >
                                        <i className="ace-icon fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {dataLoader === false && popuploader === false && (
                          <div className="text-center" colSpan="9">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Workout Form Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* {searchname === "" ? (
                  <> */}
                    {list?.length > 0 && (
                      <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                        <Pagination
                          className="pagination-bar"
                          currentPage={pageNum}
                          totalCount={noOfRecords}
                          pageSize={limitValue}
                          onPageChange={(page) => curPage(page)}
                        />
                      </div>
                    )}
                  {/* </>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*---------- workform view Detail Modal--------------*/}

      <Modal
        className="workmodal"
        show={show1}
        onHide={handleClose1}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="loadermodal" className="loader d-none">
          <div className="popuploader loginloader"></div>
        </div>
        <Modal.Header className="d-block text-center" closeButton>
          <Modal.Title className="d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Workout Form Detail</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 pb-3 pt-3 Workform">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 form-detail">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home"
                    >
                      Trainer Information
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#menu1">
                      Trainer WorkOut
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#menu2">
                      Basic Movement
                    </a>
                  </li>
                </ul>
                <div className="tab-content form-content">
                  <div id="home" className="trainer-info tab-pane active">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="row">
                          <label className="col-lg-6 col-md-6 col-12">
                            Name <span className="colon">:</span>
                          </label>
                          <div className="col-lg-6 col-md-6 col-12 mb-1">
                            <span>{getlist?.client_data?.firstname}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row">
                          <label className="col-lg-4 col-md-6 col-12">
                            Age <span className="colon">:</span>
                          </label>
                          <div className="col-lg-8 col-md-6 col-12 mb-1">
                            <span>{getlist?.client_data?.age}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row">
                          <label className="col-lg-6 col-md-6 col-12">
                            Fitness Goals <span className="colon">:</span>
                          </label>
                          <div className="col-lg-6 col-md-6 col-12 mb-1">
                            <span>{getlist?.sessionworkout?.fitnessGoals}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="row">
                          <label className="col-lg-4 col-md-6 col-12">
                            Format <span className="colon">:</span>
                          </label>
                          <div className="col-lg-8 col-md-6 col-12 mb-1">
                            <span>{getlist?.sessionworkout?.format}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="row">
                          <label className="col-lg-3 col-md-3 col-12">
                            Injuries <span className="colon">:</span>
                          </label>
                          <div className="col-lg-9 col-md-9 col-12 mb-1">
                            <span>
                              {getlist?.client_data?.injuriesorhelthissues}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="row">
                          <label className="col-lg-3 col-md-3 col-12">
                            Additional Notes <span className="colon">:</span>
                          </label>
                          <div className="col-lg-9 col-md-9 col-12 mb-1">
                            <span>
                              {getlist?.sessionworkout?.additionalNotes}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="menu1" className="tab-pane fade">
                    <div className="row">
                      <div className="col-12">
                        <h4 className="work-name pt-0">WorkOut Question</h4>
                      </div>
                      <div className="col-md-12 col-12">
                        <label className="w-100">
                          Q. What did you focus on today to achieve. Your
                          client's desired fitness goals?
                        </label>
                        <p className="font-weight-bold">
                          Ans.{" "}
                          {getlist?.sessionworkout?.desiredOne
                            ? getlist?.sessionworkout?.desiredOne?.join(",")
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <label className="w-100">
                          Q. What did you focus on today to achieve your
                          client's desired fitness goals?
                        </label>
                        <p className="font-weight-bold">
                          Ans.{" "}
                          {getlist?.sessionworkout?.desiredTwo
                            ? getlist?.sessionworkout?.desiredTwo?.join(",")
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <label className="w-100">
                          Q. Which of the 7 basic movements did you work on
                          today? (Check all that apply){" "}
                        </label>
                        <p className="font-weight-bold">
                          Ans.{" "}
                          {getlist?.sessionworkout?.basicMovements?.length > 0 &&
                            getlist?.sessionworkout?.basicMovements
                              .map((x) => x.movementName)
                              .join(",")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="menu2" className="tab-pane fade basic-movement">
                    {getlist?.sessionworkout?.basicMovements?.length > 0 &&
                      getlist?.sessionworkout?.basicMovements.map((ele) => {
                        return (
                          <div className="row bor-b">
                            <div className="col-md-12 col-12">
                              <h4 className="work-name pt-0">
                                {ele.movementName}
                              </h4>
                              <div className="row basic-movement">
                                <div className="col-12">
                                  <label>
                                    Specify Movement{" "}
                                    <span className="colon">:</span>
                                  </label>
                                  <span>
                                    {ele.specifyMovement
                                      ? ele.specifyMovement
                                      : "N/A"}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label>
                                    Weight <span className="colon">:</span>
                                  </label>
                                  <span>{ele.weight ? ele.weight : "N/A"}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label>
                                    Rest Duration{" "}
                                    <span className="colon">:</span>
                                  </label>
                                  <span>
                                    {ele.restDuration
                                      ? ele.restDuration
                                      : "N/A"}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label>
                                    Sets <span className="colon">:</span>
                                  </label>
                                  <span>{ele.sets ? ele.sets : "N/A"}</span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label>
                                    Reps <span className="colon">:</span>
                                  </label>
                                  <span>{ele.reps ? ele.reps : "N/A"}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                {/* <div className="row">
                        <label className="col-md-5 col-12">Name <span>:</span></label>
                        <div className="col-md-7 col-12">
                            <span>Ray Doyle</span>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-5 col-12">Age <span>:</span></label>
                         <div className="col-md-7 col-12">
                             <span>30</span>
                         </div>
                    </div>
                    <div className="row">
                        <label className="col-md-5 col-12">Injuries<span>:</span></label>
                        <div className="col-md-7 col-12">
                            <span>Left Knee pain</span>
                        </div>                                                                                
                    </div>
                    <div className="row">
                        <label className="col-md-5 col-12">Fitness Goals <span>:</span></label>
                        <div className="col-md-7 col-12">
                            <span>Lower Body Fat</span>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-5 col-12">Additional Notes <span>:</span></label>
                        <div className="col-md-7 col-12">
                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-5 col-12">Format <span>:</span></label>
                        <div className="col-md-7 col-12">
                            <span>Circuits</span>
                        </div>
                    </div> */}
                {/* <div className="row">
                                    <div className="col-md-12 col-12">
                                        <h4 className="work-name">Hip Dominant</h4>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-hover">
                                                <tr>
                                                    <th>Specify Movement</th>
                                                    <td>Pushup</td>
                                                </tr>
                                                <tr>
                                                    <th>Weight</th>
                                                    <td>1 Kg</td>
                                                </tr>
                                                <tr>
                                                    <th>Rest Duration</th>
                                                    <td>10 mins</td>
                                                </tr>
                                                <tr>
                                                    <th>Sets</th>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <th>Reps</th>
                                                    <td>1</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <h4 className="work-name">Knee Dominant</h4>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-hover">
                                                <tr>
                                                    <th>Specify Movement</th>
                                                    <td>Pushup</td>
                                                </tr>
                                                <tr>
                                                    <th>Weight</th>
                                                    <td>1 Kg</td>
                                                </tr>
                                                <tr>
                                                    <th>Rest Duration</th>
                                                    <td>10 mins</td>
                                                </tr>
                                                <tr>
                                                    <th>Sets</th>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <th>Reps</th>
                                                    <td>1</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*---------- workform view Detail Modal End--------------*/}
    </>
  );
}
export default WorkoutForm;
