import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import Pagination from "../Pagination/Pagination";


function Notifications() {
  const navigate = useNavigate();
  
  const { pages } = useParams();
  const [dataLoader, setDataLoader] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow1 = () => setShow(true);

  // const [isLoader, setIsLoader] = useState(false);
  const [searchname, setSearchName] = useState("");
  const [isSearching, setSearching] = useState(false);
  const [pageNum, setPageNum] = useState(+pages);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [List, setList] = useState([]);
  const [isSortedCol, setIsSortedCol] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const limitValue = 10;
  const [viewData, setViewData] = useState([])

  const [columnArr, setColumnArr] = useState([
    { name: "Name", value: "firstname", dir: 1 },
    { name: "Date", value: "date", dir: 1 },
    { name: "Title", value: "title", dir: 1 },
  ]);

  useEffect(() => {
    GetList(pageNum);
  }, []);

  const GetList = (val) => {
    setSelectedPage(val);
    // setIsLoader(true);
    setDataLoader(true);
    var obj = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
      sortedCol: isSortedCol,
      sortedOrder: columnArr.filter((x) => x.value === isSortedCol)[0]?.dir,
    };
    axios
      .post(`${apiUrl}/admin/notificationlist`, obj)
      .then(function (response) {
        // setIsLoader(false);
        if (response.data.status === 1) {
          setTimeout(() => {
            setNoOfRecords(response.data?.result?.count || 0);
            setList(response.data?.result?.list);
            setDataLoader(false);
          }, 1000);
        } else {
          setDataLoader(true);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setDataLoader(true);
        // setIsLoader(false);
        window.alert(error);
      });
  };

  const isSortedColumn = (col, order) => {
    columnArr.forEach((ele) => {
      if (ele.value === col) {
        ele.dir = ele.dir === 1 ? -1 : 1;
      }
    });
    setDataLoader(true);
    // setIsLoader(false)
    setColumnArr(columnArr);
    setIsSortedCol(col);
    GetList(selectedPage);
    setDataLoader(true);
    // setIsLoader(false)
  };

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
    // setIsLoader(false)
  };

  const viewDetail=async(data)=>{
    setViewData(data)
    handleShow1();
  }

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value) {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      GetList(pageNum);
      setPageNum(pageNum); // Reset page number when clearing the search
      // setIsLoader(false)
    }
  };

  const searchClick = async (searchData,pageNum) => {
    try {
      const obj = {
        limitValue: limitValue,
        pageNumber: pageNum,
      };

     const response= await axios.post(`${apiUrl}/admin/searchnotification`,{ searchname: searchData,obj },{})
      if (response.data.status === 1) {
        console.log("search data",response.data.result.total);
        setList(response?.data?.result?.notifications);
        setNoOfRecords(response?.data?.result?.total || 0);
        setSearching(true);
      } else {
        setDataLoader(false);
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: true,
        });
      }
    } catch (error) {
      setDataLoader(false);
      window.alert(error);
    }
    // if (!searchData) {
    //   setDataLoader(true);
    //   // setIsLoader(false)
    //   GetList(pageNum);
    //   setDataLoader(true);
    //   // setIsLoader(false)
    // } else {
    //   // setIsLoader(true);
    //   await axios
    //     .post(
    //       `${apiUrl}/admin/searchnotification`,
    //       { searchname: searchData },
    //       {}
    //     )
    //     .then(function (response) {
    //       // setIsLoader(false);
    //       if (response.data.status === 1) {
    //         setList(response.data.result);
    //         setSearching(true);
    //       } else {
    //         swal({
    //           title: "Error!",
    //           text: response.data.message,
    //           icon: "error",
    //           button: true,
    //         });
    //       }
    //     })
    //     .catch(function (error) {
    //       setDataLoader(true);
    //       // setIsLoader(false);
    //       window.alert(error);
    //     });
    // }
  };

  const curPage = (pageNum) => {
    navigate(`/notifications/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setDataLoader(true);
      setTimeout(async() => {
        await searchClick(searchname, pageNum);
        setDataLoader(false);
      }, 500);
    // searchClick(searchname, pageNum);
  } else {
    GetList(pageNum);
  }
  };


  return (
    <>
      <div className="container-fluid">
        <div className="title-box">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <h4>Notification</h4>
            </div>
            <div className="col-md-6 col-sm-6 col-12 text-right">
              <div className="page-title float-right">
                <ul className="breadcrumb m-0 pb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Notification</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="box-card notification-box">
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <button className="search_btn" onClick={clearClick}>
                      <i className="fa fa-times"></i>
                    </button>
                  ) : (
                    <button
                      className="search_btn"
                      onClick={() => searchClick()}
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12 record_table mt-2">
                <div className="position-relative">
                  <div className="table-responsive">
                    {dataLoader && (
                      // <span>Loadnig..</span>
                      <div id="loadermodal" class="loader height-full">
                        <div class="popuploader loginloader"></div>
                      </div>
                    )}

                    {List.length > 0 ? (
                      <>
                        <table className="table table-bordered table-hover mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "50px" }}>Sr. No</th>
                              {columnArr.map((col, i) => {
                                return (
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      isSortedColumn(col.value, col.dir)
                                    } className="w-200"
                                  >
                                    <div className="d-flex justify-content-between">
                                      {col.name}
                                      <span className="align-self-center">
                                        <i className="ml-2 fa fa-sort sort-gray"></i>
                                        <i
                                          className={`${
                                            col.dir === 1
                                              ? "sort-i fa fa-sort-asc"
                                              : "sort-i fa fa-sort-desc"
                                          }`}
                                        ></i>
                                      </span>
                                    </div>
                                  </th>
                                );
                              })}
                              <th className="wd450">Description</th>
                              <th className="text-center w130">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {List.map((ele, index) => {
                              return (
                                <tr key={"index" + index}>
                                  <td style={{ width: "50px" }}>
                                    {index + 1 + (pageNum - 1) * limitValue}
                                  </td>
                                  <td>{`${ele?.user_data?.firstname} ${ele?.user_data?.lastname}`}</td>
                                  <td className="text-nowrap">{new Date(ele?.date).toDateString()}</td>
                                  <td><p className="wd250 mb-0">{ele?.title}</p></td>
                                  <td>
                                    <p className="noti-disc wd300 mb-0">
                                      {ele?.message}
                                    </p>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      title="View"
                                      className="btn btn-eye mr-2"
                                      onClick={()=>viewDetail(ele)}
                                   >
                                      <i className="ace-icon fa fa-eye"></i>
                                    </button></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        {dataLoader === false && (
                          <div className="text-center no-record" colSpan="10">
                            <div className="mt-3 text-center">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Notification Not Found
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* {searchname === "" ? (
                  <> */}
                    {List.length > 0 && (
                      <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                        <Pagination
                          className="pagination-bar"
                          currentPage={pageNum}
                          totalCount={noOfRecords}
                          pageSize={limitValue}
                          onPageChange={(page) => curPage(page)}
                        />
                      </div>
                    )}
                  {/* </>
                ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div id="loadermodal" className="loader d-none">
          <div className="popuploader loginloader"></div>
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="logo" />
            <h4 className="mb-0 ml-3">Notification</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 pb-3 booking-history notification-modal">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="row">
                  <label className="col-lg-3 col-md-5 col-12">
                    Date<span>:</span>
                  </label>
                  <div className="col-lg-9 col-md-7 col-12">
                    <span className="noti-content">{new Date(viewData?.date).toDateString()}</span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-3 col-md-5 col-12">
                    Title <span>:</span>
                  </label>
                  <div className="col-lg-9 col-md-7 col-12">
                    <span className="noti-content">{viewData?.title}</span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-3 col-md-5 col-12">
                    Description <span>:</span>
                  </label>
                  <div className="col-lg-9 col-md-7 col-12">
                    <span className="noti-content">{viewData?.message}</span>
                  </div>
                </div>
                {/* <div className="row">
                  <label className="col-md-5 col-12">
                    Sent by <span>:</span>
                  </label>
                  <div className="col-md-7 col-12">
                    <span>Ross</span>
                  </div>
                </div> */}
                <div className="row">
                  <label className="col-lg-3 col-md-5 col-12">
                    Sent To<span>:</span>
                  </label>
                  <div className="col-lg-9 col-md-7 col-12">
                    <span className="noti-content">{viewData?.user_data?.firstname}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Notifications;
